// import OrderDetail from './components/order-detail/OrderDetail'
import {
  IonBadge,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow
} from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import Breadcrumbs from 'app/components/header/breadcrumbs/Breadcrumbs'
import SummaryItem from 'app/components/summary-item/SummaryItem'
import { useConfig } from 'app/context/config/ConfigContext'
import { useSession } from 'app/context/session/useSession'
import { arrowBackOutline } from 'ionicons/icons'
import { User, UserAddress, UserLocation } from 'models'
import { CartItem, CartItemVariation, ElasticOrder } from 'models/CartTypes'
import { useEffect, useState } from 'react'
import {
  getBadgeText,
  getBadgeType,
  getOrder,
  getProductImages
} from 'services/order'
import { getTranslation } from 'translations'

const OrderDetailPage: React.FC = ({
  mockOrderId
}: {
  mockOrderId?: string
}) => {
  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo()
  })
  const pathnames = window.location.pathname.split('/')
  const id = mockOrderId || pathnames[pathnames.length - 1]
  const { data: order, isLoading } = useQuery({
    queryKey: ['order', id],
    queryFn: () => getOrder(id, true),
    enabled: !!id
  })
  const { data: brandConfig } = useConfig()
  const [hideItemNumber, setHideItemNumber] = useState<boolean>(false)
  const [orderDetails, setOrderDetails] = useState<ElasticOrder | null>(null)
  const [discountAmount, setDiscountAmount] = useState<number>(0.0)
  const [totalPriceString, setTotalPriceString] = useState<string>('')
  const [displayAddress, setDisplayAddress] = useState<UserAddress | null>(null)

  const { getUserInfo, userLanguageCode } = useSession()

  useEffect(() => {
    if (order) {
      const elasticOrder: ElasticOrder = order
      if (elasticOrder.discounts) {
        let totalDiscount = 0
        const discountsJson = JSON.parse(elasticOrder.discounts)
        for (const key in discountsJson) {
          totalDiscount += parseFloat(discountsJson[key])
        }
        setDiscountAmount(totalDiscount)
      }
      if (elasticOrder.pages && elasticOrder.pages.length > 0) {
        elasticOrder.pages[0].page_products.forEach((product: CartItem) => {
          product.isCustomized = product.embellishments ? true : false
          if (elasticOrder.products) {
            const images = getProductImages(product, elasticOrder.products)
            if (images) {
              product.images = images
            }
          }

          // populate these items price per unit (we only get back total pricings)
          product.page_items.forEach((stock: CartItemVariation) => {
            if (stock.total_prices && stock.quantity > 0) {
              stock.prices = {
                elastic_retail: (
                  parseFloat(stock.total_prices?.elastic_retail) /
                  stock.quantity
                ).toFixed(2),
                elastic_wholesale: (
                  parseFloat(stock.total_prices?.elastic_wholesale) /
                  stock.quantity
                ).toFixed(2),
                rBASEUSD: (
                  parseFloat(stock.total_prices?.rBASEUSD) / stock.quantity
                ).toFixed(2),
                wBASEUSD: (
                  parseFloat(stock.total_prices?.wBASEUSD) / stock.quantity
                ).toFixed(2)
              }
            }
          })
        })

        if (
          elasticOrder.pages[0].drop_ship_address &&
          elasticOrder.pages[0].drop_ship_address.address1 != null
        ) {
          const dropshipaddress = elasticOrder.pages[0].drop_ship_address
          const address: UserAddress = {
            name: dropshipaddress.name,
            street1: dropshipaddress.address1,
            street2: dropshipaddress.address2,
            city: dropshipaddress.city,
            state: dropshipaddress.state,
            country: dropshipaddress.country,
            zip: dropshipaddress.zip
          }
          setDisplayAddress(address)
        } else if (elasticOrder.pages[0].location_number && user) {
          setDisplayAddress(
            findUserLocation(elasticOrder.pages[0].location_number, user)
          )
        }
      }

      setOrderDetails(elasticOrder)

      if (elasticOrder.total_prices) {
        setTotalPriceString(
          '$' +
            parseFloat(elasticOrder.total_prices.elastic_wholesale)
              .toFixed(2)
              .toString()
        )
      }
    }

    if (brandConfig) {
      if (brandConfig.brandDynamic.config.flags.hideProductDataInUI) {
        setHideItemNumber(
          brandConfig.brandDynamic.config.flags.hideProductDataInUI
            .product_number
        )
      }
    }
  }, [brandConfig, order, user])

  const findUserLocation = (
    locationNumber: string,
    user: User
  ): UserAddress | null => {
    let theAddress = null
    user.customer.locations.forEach((location: UserLocation) => {
      if (location.number === locationNumber) {
        if (location.address) {
          theAddress = location.address
        }
      }
    })

    return theAddress
  }

  const calculateTotalCartItems = (order: ElasticOrder | null) => {
    let total = 0
    if (order) {
      order.pages[0].page_products.forEach((cartItem: CartItem) => {
        cartItem.page_items.forEach((stock: CartItemVariation) => {
          total += stock.quantity
        })
      })
    }

    return total
  }
  return isLoading ? (
    <IonLoading message="Loading order..." isOpen />
  ) : (
    <IonPage className="page-with-menu cart-page">
      <IonContent>
        <>
          {(orderDetails?.pages || []).length > 0 && (
            <>
              <div className="wrapper">
                <Breadcrumbs skipLast={true} />
                <h1>{getTranslation('orderDetails', userLanguageCode)}</h1>
                <p className="order-num">
                  {getTranslation('order', userLanguageCode)} #
                  {orderDetails?.number}
                </p>
                <div className="address-seperator">
                  <IonGrid>
                    <IonRow>
                      <IonCol size="6">
                        {displayAddress && (
                          <div className="selected-address">
                            <h3>
                              {getTranslation(
                                'shipmentAddress',
                                userLanguageCode
                              )}
                            </h3>
                            <p>
                              {displayAddress.name && (
                                <>
                                  {displayAddress.name} <br />
                                </>
                              )}
                              {displayAddress.street1 && (
                                <>
                                  {displayAddress.street1} <br />
                                </>
                              )}
                              {displayAddress.street2 && (
                                <>
                                  {displayAddress.street2} <br />
                                </>
                              )}
                              {displayAddress.city}, {displayAddress.state}{' '}
                              {displayAddress.zip}
                            </p>
                          </div>
                        )}
                      </IonCol>
                      <IonCol size="6">
                        <div className="selected-address">
                          <h3>
                            {getTranslation('shipmentStatus', userLanguageCode)}
                          </h3>
                          <p>
                            <IonBadge
                              color={getBadgeType(orderDetails?.state || '')}
                            >
                              {getBadgeText(orderDetails?.state || '')}
                            </IonBadge>
                          </p>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <h2>{getTranslation('orderSummary', userLanguageCode)}</h2>
                <p className="total-items">
                  {calculateTotalCartItems(orderDetails)}{' '}
                  {getTranslation('items', userLanguageCode)}
                </p>

                {orderDetails?.pages[0].page_products.map(
                  (product: CartItem, index: number) => {
                    let totalQuantity = 0
                    product.page_items.forEach(
                      (variation: CartItemVariation) => {
                        totalQuantity += variation.quantity
                      }
                    )

                    return (
                      <SummaryItem
                        key={index}
                        product={product}
                        totalQuantity={totalQuantity}
                        isEditable={false}
                        setProductToDelete={() => {}}
                        showItemNumber={!hideItemNumber}
                      />
                    )
                  }
                )}
              </div>
              <div className="cart-cost-details">
                <h2>
                  {getTranslation('priceDetails', userLanguageCode)} (
                  {calculateTotalCartItems(orderDetails)} Items)
                </h2>
                <IonGrid>
                  {orderDetails?.total_prices && (
                    <IonRow
                      className={discountAmount == 0.0 ? 'with-border' : ''}
                    >
                      <IonCol>
                        <p>{getTranslation('totalPrice', userLanguageCode)}</p>
                      </IonCol>
                      <IonCol>
                        <p>{totalPriceString}</p>
                      </IonCol>
                    </IonRow>
                  )}
                  {discountAmount != 0.0 && (
                    <IonRow class="with-border">
                      <IonCol>
                        <p>{getTranslation('discount', userLanguageCode)}</p>
                      </IonCol>
                      <IonCol>
                        <p>-${discountAmount.toFixed()}</p>
                      </IonCol>
                    </IonRow>
                  )}
                  {orderDetails?.net_price && (
                    <IonRow class="amount-due">
                      <IonCol>
                        <p>{getTranslation('totalAmount', userLanguageCode)}</p>
                      </IonCol>
                      <IonCol>
                        <p>${orderDetails.net_price?.toFixed(2)}</p>
                      </IonCol>
                    </IonRow>
                  )}
                </IonGrid>
              </div>
              <div className="place-order">
                <IonButton routerLink="/manage/orders" color={'secondary'}>
                  <IonIcon
                    slot="start"
                    color="primary"
                    icon={arrowBackOutline}
                  ></IonIcon>{' '}
                  Back to Orders
                </IonButton>
              </div>
            </>
          )}
        </>
      </IonContent>
    </IonPage>
  )
}

export default OrderDetailPage
