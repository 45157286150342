import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCol,
  IonRouterLink,
  IonRow
} from '@ionic/react'
import Image from 'app/components/image/image'
import { useCart } from 'app/context/cart/useCart'
import { useSession } from 'app/context/session/useSession'
import useProductImage from 'app/hooks/use-product-image'
import ProductCustomTable from 'app/pages/product-detail/components/product-custom-table/ProductCustomTable'
import { CartItem, CartItemVariation } from 'models'
import { getTranslation } from 'translations'
import './SummaryItem.scss'

interface SummaryItemProps {
  product: CartItem
  totalQuantity: number
  isEditable: boolean
  setProductToDelete: Function
  showItemNumber: boolean
}

const SummaryItem: React.FC<SummaryItemProps> = (props) => {
  const { userLanguageCode } = useSession()
  const { appPriceType } = useCart()
  const productImage = useProductImage(props.product)

  function totalItemQuantity() {
    let total = 0
    props.product.page_items.forEach((item) => {
      total += item.quantity
    })

    return total
  }

  function totalItemCost(): string {
    let wholesaleTotal = 0.0
    let retailTotal = 0.0
    props.product.page_items.forEach((stock: CartItemVariation) => {
      if (stock.quantity > 0) {
        if (stock.prices) {
          wholesaleTotal +=
            parseFloat(stock.prices.elastic_wholesale) * stock.quantity
          retailTotal +=
            parseFloat(stock.prices.elastic_retail) * stock.quantity
        }
      }
    })

    if (appPriceType == 'elastic_wholesale') {
      return wholesaleTotal.toFixed(2)
    } else if (appPriceType == 'elastic_retail') {
      return retailTotal.toFixed(2)
    } else if (appPriceType == 'both') {
      return wholesaleTotal.toFixed(2) + ' / $' + retailTotal.toFixed(2)
    } else {
      return ''
    }
  }

  return (
    <IonCard className="summary-item" key={props.product._id}>
      <IonCardHeader>
        <IonRow>
          <IonCol size="4" className="image-col">
            <Image src={productImage} />
          </IonCol>
          <IonCol size="8" className="info-col">
            <h3>{props.product.name}</h3>
            {props.showItemNumber && (
              <p className="product-number">{props.product.product_number}</p>
            )}
            {!props.product.isCustomized && (
              <p className="product-number">
                {props.product.color_name + ' - ' + props.product.color_code}
              </p>
            )}
            {props.product.isCustomized && (
              <IonBadge color={'medium'}>
                {getTranslation('customized', userLanguageCode)}
              </IonBadge>
            )}
          </IonCol>
        </IonRow>
        {props.product.isCustomized && props.product.embellishments && (
          <div className="embellishment-display" data-testid="customized">
            <ProductCustomTable
              embellishments={props.product.embellishments.data}
            />
          </div>
        )}
        <IonRow class="summary-row">
          <IonCol
            size={props.isEditable ? '6' : '2'}
            className="action-buttons"
          >
            {props.isEditable && (
              <>
                <IonButton
                  color={'secondary'}
                  routerLink={
                    '/product-detail/' +
                    props.product.product_number +
                    '?variationCode=' +
                    props.product.color_code
                  }
                  data-testid="editable"
                >
                  {getTranslation('edit', userLanguageCode)}
                </IonButton>
                <IonRouterLink
                  onClick={() => {
                    props.setProductToDelete({
                      number: props.product.product_number,
                      color: props.product.color_code
                    })
                    return false
                  }}
                >
                  {getTranslation('remove', userLanguageCode)}
                </IonRouterLink>
              </>
            )}
          </IonCol>
          <IonCol size={props.isEditable ? '6' : '10'}>
            <div className="info-item">
              <p className="name">
                {getTranslation('totalQty', userLanguageCode)}
              </p>
              <p className="amount">{totalItemQuantity()}</p>
            </div>
            <div className="info-item total-price">
              <p className="name">
                {getTranslation('totalPrice', userLanguageCode)}
              </p>
              <p className="amount">${totalItemCost()}</p>
            </div>
          </IonCol>
        </IonRow>
      </IonCardHeader>
    </IonCard>
  )
}

export default SummaryItem
