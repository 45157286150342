import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonSegment,
  IonSegmentButton,
  IonToolbar
} from '@ionic/react'
import { useCart } from 'app/context/cart/useCart'
import { useSession } from 'app/context/session/useSession'
import useBreakpoint from 'app/hooks/useBreakpoint'
import { closeOutline, search } from 'ionicons/icons'
import { User } from 'models/User'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { getUserDashboard } from 'services/dashboard'
import { getTranslation } from 'translations'
import './SideMenu.scss'
import LinkSection from './link-section/LinkSection'

const SideMenu: React.FC = () => {
  const [toolbar, setToolbar] = React.useState<string | null>('')
  const [primaryLinks, setPrimaryLinks] = React.useState<any[]>([])
  const [secondaryLinks, setSecondaryLinks] = React.useState<any[]>([])
  const [exploreLinks, setExploreLinks] = React.useState<any[]>([])
  const [manageLinks, setManageLinks] = React.useState<any[]>([])
  const { desktop, mobile } = useBreakpoint()
  const {
    logout,
    userCatalogId,
    userLanguageCode,
    getUserInfo,
    isAuthenticated
  } = useSession()
  const { clearOrder } = useCart()
  const history = useHistory()

  const myMenu: any = useRef(null)

  useEffect(() => {
    async function getData() {
      const userData: User | undefined = await getUserInfo()

      try {
        if (userData && userData.customer) {
          const dashboardData = await getUserDashboard(
            userData.customer.number!,
            true
          )

          if (dashboardData.data.exploreDynamic.dashboard.navigation_settings) {
            if (
              dashboardData.data.exploreDynamic.dashboard.navigation_settings
                .primary_items
            ) {
              setPrimaryLinks(
                dashboardData.data.exploreDynamic.dashboard.navigation_settings
                  .primary_items
              )
            }

            if (
              dashboardData.data.exploreDynamic.dashboard.navigation_settings
                .secondary_items
            ) {
              setSecondaryLinks(
                dashboardData.data.exploreDynamic.dashboard.navigation_settings
                  .secondary_items
              )
            }

            if (
              dashboardData.data.exploreDynamic.dashboard.navigation_settings
                .explore_links
            ) {
              setExploreLinks(
                dashboardData.data.exploreDynamic.dashboard.navigation_settings
                  .explore_links
              )
            }

            if (
              dashboardData.data.exploreDynamic.dashboard.navigation_settings
                .manage_links
            ) {
              setManageLinks(
                dashboardData.data.exploreDynamic.dashboard.navigation_settings
                  .manage_links
              )
            }
          }
        }
      } catch {}
    }

    getData()
  }, [history, isAuthenticated])

  const closeMenu = () => {
    myMenu.current.toggle()
  }

  const handleToolbar = (e: any) => {
    if (e.detail.value == 'explore' && exploreLinks.length == 0) {
      history.push('/explore/')
      myMenu.current.toggle()
    } else {
      setToolbar(e.detail.value)
    }
  }

  return (
    <IonMenu
      ref={myMenu}
      id="sidebar"
      contentId="main"
      type="overlay"
      style={{ '--width': desktop ? '40%' : mobile ? '100%' : '70%' }}
      swipeGesture={false}
    >
      <IonContent className="side-menu">
        {mobile && (
          <div className="close-icon ion-padding">
            <IonIcon onClick={closeMenu} icon={closeOutline} color="primary" />
          </div>
        )}

        <div className="page-header">
          {userCatalogId && (
            <IonMenuToggle key={'orders'} autoHide={false}>
              <IonButton
                className="menu-button"
                shape="round"
                routerLink={'/explore/catalogs?catalogId=' + userCatalogId}
              >
                {getTranslation('shopNow', userLanguageCode)}
              </IonButton>
            </IonMenuToggle>
          )}

          <IonMenuToggle autoHide={false}>
            <IonButton
              routerLink={'/explore'}
              fill="clear"
              className="show-on-desktop top-button"
            >
              <span className="animation">
                {getTranslation('explore', 'en')}
              </span>
            </IonButton>
          </IonMenuToggle>
          {!mobile && (
            <IonMenuToggle autoHide={false}>
              <IonButton
                fill="clear"
                className="icon-search"
                routerLink={'/search'}
              >
                <IonIcon size="medium" icon={search} />
              </IonButton>
            </IonMenuToggle>
          )}

          <IonToolbar class="segments">
            <IonSegment value={toolbar || ''} onIonChange={handleToolbar}>
              {userCatalogId && exploreLinks.length > 0 && (
                <IonSegmentButton className="top-button" value="explore">
                  <IonContent className="animation">Explore</IonContent>
                </IonSegmentButton>
              )}
              {userCatalogId && manageLinks.length > 0 && (
                <IonSegmentButton value="manage">
                  <span className="animation">Manage</span>
                </IonSegmentButton>
              )}
            </IonSegment>
          </IonToolbar>
        </div>
        {toolbar === '' && (
          <div className="list-container explore">
            <IonList>
              <IonMenuToggle
                className="menu-list"
                key={'default'}
                autoHide={false}
              >
                <LinkSection links={primaryLinks} large={true} />
              </IonMenuToggle>
              <IonMenuToggle key={'logout'} autoHide={false}>
                <LinkSection links={secondaryLinks} large={false} />
                <IonItem
                  lines="none"
                  detail={false}
                  className="small-item"
                  onClick={() => {
                    clearOrder()
                    logout()
                  }}
                >
                  <IonLabel>
                    {getTranslation('logout', userLanguageCode)}
                  </IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </div>
        )}
        {toolbar === 'explore' && (
          <div className="list-container explore">
            <IonList>
              <IonMenuToggle key={'orders'} autoHide={false}>
                <LinkSection links={exploreLinks} large={true} />
              </IonMenuToggle>
            </IonList>
          </div>
        )}
        {toolbar === 'manage' && manageLinks.length > 0 && (
          <div className="list-container">
            <IonList>
              <IonMenuToggle key={'orders'} autoHide={false}>
                <LinkSection links={manageLinks} large={true} />
              </IonMenuToggle>
            </IonList>
          </div>
        )}
      </IonContent>
    </IonMenu>
  )
}

export default SideMenu
