import { IonButton, IonIcon } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import LoginLayout from 'app/components/login-layout/LoginLayout'
import { useConnection } from 'app/context/connection/useConnection'
import { useSession } from 'app/context/session/useSession'
import { chevronBackOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getBrandConfig } from 'services/auth'
import { getTranslation } from 'translations'
import constants from '../../../../../constants'
import ChangePasswordForm from '../change-password-form/ChangePasswordForm'
import EmailCodeForm from '../email-code-form/EmailCodeForm'
import './ForgotPasswordForm.scss'

const useData = () => {
  const { connected } = useConnection()
  const [supportUrl, setSupportUrl] = useState(
    process.env.REACT_APP_DEFAULT_SUPPORT_LINK
  )
  const [code, setCode] = useState('')
  const [dataLoaded, setDataLoaded] = useState(false)
  const [hasCode, setHasCode] = useState(false)
  const { data: result } = useQuery({
    queryKey: ['brandConfig'],
    queryFn: () => getBrandConfig(constants.CONFIG_TYPE, connected)
  })

  useEffect(() => {
    if (result) {
      setSupportUrl(result?.brandDynamic?.config?.flags?.supportUrl)
      setDataLoaded(true)
    }

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params.code != null) {
      setHasCode(true)
      setCode(params.code)
    }
  }, [result])

  return { supportUrl, code, dataLoaded, hasCode }
}

const ForgotPasswordForm: React.FC = () => {
  const [codeSuccess, setCodeSuccess] = useState(false)
  const [resetSuccess, setResetSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const { supportUrl, code, dataLoaded, hasCode } = useData()

  const history = useHistory()
  const { userLanguageCode } = useSession()

  const handleSuccess = (payload: { email: string; codeSuccess: boolean }) => {
    setEmail(payload.email)
    setCodeSuccess(payload.codeSuccess)
  }

  return (
    <>
      {dataLoaded && (
        <LoginLayout>
          <div className="form-wrapper forgot-password-form">
            <h1
              onClick={() => {
                if (!resetSuccess) {
                  history.goBack()
                }
              }}
            >
              {!codeSuccess && !resetSuccess && !hasCode && (
                <>
                  <IonIcon
                    slot="end"
                    icon={chevronBackOutline}
                    color="medium"
                  />{' '}
                  <span>
                    {getTranslation('forgotPassword', userLanguageCode)}
                  </span>
                </>
              )}
              {codeSuccess && !resetSuccess && (
                <>
                  {' '}
                  <span>{getTranslation('checkEmail', userLanguageCode)}</span>
                </>
              )}
              {hasCode && !resetSuccess && (
                <>
                  {' '}
                  <span>
                    {getTranslation('enterNewPassword', userLanguageCode)}
                  </span>
                </>
              )}
              {resetSuccess && (
                <span>{getTranslation('passwordReset', userLanguageCode)}</span>
              )}
            </h1>
            {!hasCode && !codeSuccess && !resetSuccess && (
              <EmailCodeForm onSuccess={handleSuccess} />
            )}
            {codeSuccess && !resetSuccess && (
              <div className="inputs">
                <p className="verify-help">
                  {getTranslation('sentEmailTo', userLanguageCode)}{' '}
                  <strong>{email}</strong>{' '}
                  {getTranslation('withEmailInstructions', userLanguageCode)}
                </p>
                <IonButton
                  className="ion-margin-top"
                  type="button"
                  expand="block"
                  border-radius="15px"
                  onClick={() => {
                    history.replace('/login')
                    return false
                  }}
                >
                  {getTranslation('backToLogin', userLanguageCode)}
                </IonButton>
              </div>
            )}
            {/* {hasCode && !resetSuccess && ( */}
            {hasCode && !resetSuccess && (
              <ChangePasswordForm
                code={code}
                setResetSuccess={setResetSuccess}
              />
            )}
            {resetSuccess && (
              <>
                <p className="verify-help">
                  {getTranslation('tryLoggingIn', userLanguageCode)}
                </p>
                <IonButton
                  className="ion-margin-top"
                  type="button"
                  expand="block"
                  border-radius="15px"
                  onClick={() => {
                    history.replace('/login')
                    return false
                  }}
                >
                  {getTranslation('login', userLanguageCode)}
                </IonButton>
              </>
            )}
            <div className="support-link support-link-forgot">
              {getTranslation('needHelp', userLanguageCode)}{' '}
              <a href={supportUrl} target={'_blank'} rel="noreferrer">
                {getTranslation('getSupport', userLanguageCode)}
              </a>
            </div>
          </div>
        </LoginLayout>
      )}
    </>
  )
}

export default ForgotPasswordForm
