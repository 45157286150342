import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonRouterLink,
  IonText,
  IonTitle,
  IonicSlides
} from '@ionic/react'
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { Autoplay, Controller, Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from '../../../../components/image/image'
import './Slider.scss'
import SliderTimer from './slider-timer/SliderTimer'

interface Props {
  slides: any[]
}

const Slider: React.FC<Props> = ({ slides }) => {
  const [swiperImageInstance, setImageSwiperInstance] =
    useState<SwiperInterface>()
  const [swiperTextInstance, setTextSwiperInstance] =
    useState<SwiperInterface>()
  const [slideIndex, setSlideIndex] = useState(0)
  const timeBetweenSlides = 5000

  useEffect(() => {
    if (swiperImageInstance) {
      swiperImageInstance.update()
    }
  }, [swiperImageInstance])

  const moveToNewSlide = async (direction: string) => {
    if (swiperImageInstance) {
      if (direction === 'next') {
        if (swiperImageInstance.realIndex == slides.length - 1) {
          swiperImageInstance.slideTo(0)
        } else {
          swiperImageInstance.slideNext()
        }
      } else if (direction === 'prev') {
        if (swiperImageInstance.realIndex == 0) {
          swiperImageInstance.slideTo(slides.length - 1)
        } else {
          swiperImageInstance.slidePrev()
        }
      }
    }
  }

  const onSlideChange = async (event: any) => {
    setSlideIndex(event.realIndex)
  }

  return (
    <div className={`slider ${slides.length == 1 ? 'one-slide' : ''}`}>
      <div className="sliders-wrapper">
        <div className="image-slider">
          <Swiper
            onSwiper={(swiper) => {
              setImageSwiperInstance(swiper)
            }}
            modules={[IonicSlides, Autoplay, Controller]}
            controller={{ control: swiperTextInstance }}
            pagination={false}
            autoplay={{
              delay: timeBetweenSlides,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            speed={400}
            slidesPerView={1}
            onSlideChange={(event: any) => onSlideChange(event)}
          >
            {slides.map((slide: any, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <IonCard
                    className={
                      slide.slideVideo &&
                      slide.slideVideo.mp4 &&
                      slide.slideVideo.mp4 != ''
                        ? 'has-video'
                        : ''
                    }
                  >
                    <IonCardHeader>
                      {!slide.imageLink && slide.image && (
                        <Image src={slide.image} />
                      )}
                      {slide.imageLink && slide.image && (
                        <IonRouterLink
                          target={slide.external_url ? '_blank' : '_self'}
                          href={slide.imageLink}
                        >
                          <Image src={slide.image} />
                        </IonRouterLink>
                      )}
                      {slide.slideVideo &&
                        slide.slideVideo.mp4 &&
                        slide.slideVideo.mp4 != '' &&
                        !(slide.imageLink || slide.mainCTALink) && (
                          <video
                            height="auto"
                            width="100%"
                            autoPlay={true}
                            loop={true}
                            playsInline={true}
                            muted={true}
                          >
                            <source src={slide.slideVideo.mp4} />
                          </video>
                        )}
                      {slide.slideVideo &&
                        slide.slideVideo.mp4 &&
                        slide.slideVideo.mp4 != '' &&
                        (slide.imageLink || slide.mainCTALink) && (
                          <a
                            target={slide.external_url ? '_blank' : '_self'}
                            href={slide.imageLink || slide.mainCTALink}
                            rel="noreferrer"
                          >
                            <video
                              height="auto"
                              width="100%"
                              autoPlay={true}
                              loop={true}
                              playsInline={true}
                              muted={true}
                            >
                              <source src={slide.slideVideo.mp4} />
                            </video>
                          </a>
                        )}
                    </IonCardHeader>
                    {slide.hasCopy && (
                      <IonCardContent>
                        {!slide.imageLink && (
                          <IonTitle size={'large'} color={'secondary'}>
                            <div className="ion-text-wrap">
                              {slide.headline}
                            </div>
                          </IonTitle>
                        )}
                        {slide.imageLink && (
                          <IonRouterLink
                            target={slide.external_url ? '_blank' : '_self'}
                            href={slide.imageLink}
                          >
                            {slide.headline && (
                              <IonTitle size={'large'} color={'secondary'}>
                                <div className="ion-text-wrap">
                                  {slide.headline}
                                </div>
                              </IonTitle>
                            )}
                          </IonRouterLink>
                        )}
                        {slide.sub_headline && (
                          <IonText color={'secondary'}>
                            {slide.sub_headline}
                          </IonText>
                        )}
                        {(slide.mainCTALink || slide.secondaryCTALink) && (
                          <div className="cta-container">
                            {slide.mainCTALink && (
                              <>
                                {slide.mainCTAExternal && (
                                  <IonButton
                                    className="shop-button"
                                    href={slide.mainCTALink}
                                    target="_blank"
                                    color={'secondary'}
                                  >
                                    {slide.mainCTAText}
                                  </IonButton>
                                )}
                                {!slide.mainCTAExternal && (
                                  <IonButton
                                    className="shop-button"
                                    routerLink={slide.mainCTALink}
                                    color={'secondary'}
                                  >
                                    {slide.mainCTAText}
                                  </IonButton>
                                )}
                              </>
                            )}
                            {slide.secondaryCTALink && (
                              <div className="text-link">
                                {slide.secondaryCTAExternal && (
                                  <IonRouterLink
                                    color={'secondary'}
                                    href={slide.secondaryCTALink}
                                    target="_blank"
                                  >
                                    {slide.secondaryCTAText}
                                  </IonRouterLink>
                                )}
                                {!slide.secondaryCTAExternal && (
                                  <IonRouterLink
                                    color={'secondary'}
                                    routerLink={slide.secondaryCTALink}
                                  >
                                    {slide.secondaryCTAText}
                                  </IonRouterLink>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </IonCardContent>
                    )}
                  </IonCard>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="bottom-area">
          <div className="container">
            <div
              className={
                'background ' + (!slides[slideIndex].hasCopy ? 'no-copy' : '')
              }
            >
              <div className="text-slider">
                <Swiper
                  onSwiper={(swiper) => setTextSwiperInstance(swiper)}
                  modules={[IonicSlides, Controller]}
                  autoHeight={true}
                  pagination={false}
                  observer={true}
                  observeParents={true}
                  allowTouchMove={false}
                  speed={400}
                  slidesPerView={1}
                >
                  {slides.map((slide: any, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        <IonCard className={!slide.hasCopy ? 'no-copy' : ''}>
                          {slide.hasCopy && (
                            <IonCardContent>
                              {!slide.imageLink && (
                                <IonTitle size={'large'} color={'secondary'}>
                                  <div className="ion-text-wrap">
                                    {slide.headline}
                                  </div>
                                </IonTitle>
                              )}
                              {slide.imageLink && (
                                <IonRouterLink
                                  target={
                                    slide.external_url ? '_blank' : '_self'
                                  }
                                  href={slide.imageLink}
                                >
                                  <IonTitle size={'large'} color={'secondary'}>
                                    <div className="ion-text-wrap">
                                      {slide.headline}
                                    </div>
                                  </IonTitle>
                                </IonRouterLink>
                              )}
                              <IonText color={'secondary'}>
                                {slide.sub_headline}
                              </IonText>
                              {(slide.mainCTALink ||
                                slide.secondaryCTALink) && (
                                <div className="cta-container">
                                  {slide.mainCTAExternal && (
                                    <IonButton
                                      className="shop-button"
                                      href={slide.mainCTALink}
                                      target="_blank"
                                      color={'secondary'}
                                    >
                                      {slide.mainCTAText}
                                    </IonButton>
                                  )}
                                  {!slide.mainCTAExternal && (
                                    <IonButton
                                      className="shop-button"
                                      routerLink={slide.mainCTALink}
                                      color={'secondary'}
                                    >
                                      {slide.mainCTAText}
                                    </IonButton>
                                  )}
                                  {slide.secondaryCTALink && (
                                    <div className="text-link">
                                      {slide.secondaryCTAExternal && (
                                        <IonRouterLink
                                          color={'secondary'}
                                          href={slide.secondaryCTALink}
                                          target="_blank"
                                        >
                                          {slide.secondaryCTAText}
                                        </IonRouterLink>
                                      )}
                                      {!slide.secondaryCTAExternal && (
                                        <IonRouterLink
                                          color={'secondary'}
                                          routerLink={slide.secondaryCTALink}
                                        >
                                          {slide.secondaryCTAText}
                                        </IonRouterLink>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </IonCardContent>
                          )}
                        </IonCard>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
              {slides.length > 1 && (
                <div
                  className={
                    'controls ' + (!slides[slideIndex].hasCopy ? 'no-copy' : '')
                  }
                >
                  <IonButton
                    fill="clear"
                    onClick={() => moveToNewSlide('prev')}
                  >
                    <IonIcon icon={chevronBackOutline} color="secondary" />
                  </IonButton>
                  <div className="slider-timer">
                    <IonText color={'secondary'}>{slideIndex + 1}</IonText>
                    <SliderTimer
                      timeBetweenSlides={timeBetweenSlides}
                      slideIndex={slideIndex}
                    />
                    <IonText color={'secondary'}>{slides.length}</IonText>
                  </div>
                  <IonButton
                    className="next-button"
                    fill="clear"
                    onClick={() => moveToNewSlide('next')}
                  >
                    <IonIcon icon={chevronForwardOutline} color="secondary" />
                  </IonButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
