import {
  findPriceRange,
  getProduct,
  getProductFresh,
  totalStockAvailble
} from 'services/catalog'
import { getStoredKey } from 'services/storage'
import { getTranslation } from 'translations'
import constants from '../../../../constants'

export async function filterOutNoStockGroups(
  currentGroups: any[],
  variation: any
) {
  const correctedGroups: any[] = []
  for (const group of currentGroups) {
    let hasStockItems = false
    for (const stock_item of variation.stock_items) {
      if (stock_item.name == group.name) {
        const quantity_availible = await totalStockAvailble(
          stock_item.key,
          variation.primary_warehouse
        )
        if (quantity_availible > 0) {
          hasStockItems = true
        }
      }
    }

    correctedGroups.push({ name: group.name, hide: !hasStockItems })
  }

  return correctedGroups
}

export function getVariationIndex(variations: any, code: string): number {
  let variationIndex = 0
  variations.forEach((variation: any, index: number) => {
    if (variation.code == code) {
      variationIndex = index
    }
  })

  return variationIndex
}
export function calculateTotalSelectedStockPrice(
  currentPendingQuantities: any
) {
  let totalPrice: any = 0
  currentPendingQuantities.forEach((variation: any) => {
    variation.stock_items.forEach((stock: any) => {
      const quantity = parseInt(stock.quantity, 10)
      if (quantity > 0) {
        totalPrice += quantity * parseFloat(stock.price)
      }
    })
  })

  totalPrice = totalPrice.toFixed(2)

  return totalPrice
}

export function calculateTotalSelectedItems(currentPendingQuantities: any) {
  let totalItems = 0
  currentPendingQuantities.forEach((variation: any) => {
    variation.stock_items.forEach((stock: any) => {
      const quantity = parseInt(stock.quantity, 10)
      if (quantity > 0) {
        totalItems += quantity
      }
    })
  })

  return totalItems
}

export function customizeButtonText(
  isACartUpdate: boolean,
  product: any,
  userLanguageCode: string
) {
  if (isACartUpdate) {
    if (product.customizable) {
      return getTranslation('editCustomization', userLanguageCode)
    } else {
      return getTranslation('updateCart', userLanguageCode)
    }
  } else {
    return getTranslation('addToCart', userLanguageCode)
  }
}

export function customizeButtonTextOther(
  isACartUpdate: boolean,
  userLanguageCode: string
) {
  return isACartUpdate
    ? getTranslation('editCustomization', userLanguageCode)
    : getTranslation('customizeClub', userLanguageCode)
}

export function getGroups(selectedColor: any) {
  const tempGroups: any = []
  if (selectedColor.stock_items) {
    for (const stock of selectedColor.stock_items) {
      let groupExists = false
      for (const group of tempGroups) {
        if (group.name == stock.name) {
          groupExists = true
        }
      }
      if (
        !groupExists &&
        stock.alt_name &&
        stock.alt_name != '' &&
        stock.alt_name != '.'
      ) {
        tempGroups.push({ name: stock.name, hide: false })
      }
    }
  }

  return tempGroups
}

async function getQuantitiesAndEmbellishments(
  productData: any,
  anyCurrentEmbellishments: Function,
  appPriceType: string,
  stockQuantityAlreadyInCart: Function
) {
  const tempPendingQuantities: any[] = []
  let tempActiveEmbellishments: any[] = []

  for (const variation of productData.variations) {
    const variationStock: any[] = []
    const anyActiveEmbellishments = anyCurrentEmbellishments(
      productData.number,
      variation.code
    )
    for (const stock of variation.stock_items) {
      variationStock.push({
        quantity_availible: await totalStockAvailble(
          stock.key,
          variation.primary_warehouse
        ),
        key: stock.key,
        warehouseCode: variation.primary_warehouse,
        price:
          stock.prices[
            appPriceType == 'both' ? 'elastic_wholesale' : appPriceType
          ],
        quantity: stockQuantityAlreadyInCart(
          productData.number,
          variation.code,
          stock.key
        )
      })
    }

    tempPendingQuantities.push({
      variationCode: variation.code,
      stock_items: variationStock,
      embellishments: anyActiveEmbellishments
    })

    if (anyActiveEmbellishments && anyActiveEmbellishments.data) {
      tempActiveEmbellishments = anyActiveEmbellishments.data
    }
  }

  return {
    tempPending: tempPendingQuantities,
    tempActive: tempActiveEmbellishments
  }
}

async function getCallawayUrl(productData: any) {
  const callawayIframeUrl = await getStoredKey(constants.CALLAWAY_IFRAME_URL)

  let fullUrl = null

  if (
    callawayIframeUrl &&
    callawayIframeUrl != '' &&
    productData.customizable
  ) {
    fullUrl = callawayIframeUrl.replace('%{productNumber}', productData.number)
  }

  return fullUrl
}

export async function loadProductData(
  number: string,
  variationCode: string,
  userData: any,
  anyCurrentEmbellishments: Function,
  stockQuantityAlreadyInCart: Function,
  appPriceType: string
): Promise<any> {
  let productData: any = await getProduct(number)
  if (productData.number) {
    const allowedVariations: any[] = []
    let isCartUpdate = false
    let tempPendingQuantities: any[] = []
    let variationIndex = 0
    let selectedColor = null
    let tempActiveEmbellishments: any[] = []

    const freshProduct: any = await getProductFresh(
      productData.catalog_key,
      userData.customer_number,
      'price',
      'asc',
      '',
      false,
      true,
      true,
      1,
      number,
      true,
      ''
    )
    if (freshProduct.results && freshProduct.results.length === 1) {
      productData = freshProduct.results[0]
    }
    productData.variations.forEach((variation: any) => {
      if (!variation.filtered) {
        allowedVariations.push(variation)
      }
    })

    productData.variations = allowedVariations
    productData.priceRangeToShow = await findPriceRange(productData)

    const tempData = await getQuantitiesAndEmbellishments(
      productData,
      anyCurrentEmbellishments,
      appPriceType,
      stockQuantityAlreadyInCart
    )
    tempActiveEmbellishments = tempData.tempActive
    tempPendingQuantities = tempData.tempPending

    if (productData.variations && productData.variations.length > 0) {
      if (variationCode != '') {
        variationIndex = getVariationIndex(
          productData.variations,
          variationCode
        )
      }
      selectedColor = productData.variations[variationIndex]
    }
    if (calculateTotalSelectedStockPrice(tempPendingQuantities) > 0) {
      isCartUpdate = true
    }

    const fullUrl = await getCallawayUrl(productData)

    return {
      fullUrl: fullUrl,
      activeEmbellishments: tempActiveEmbellishments,
      isCartUpdate: isCartUpdate,
      pendingQuantities: tempPendingQuantities,
      productData: productData,
      variationIndex: variationIndex,
      selectedColor: selectedColor
    }
  }
}

export function addItemToCart(
  addByProductIDAndCode: Function,
  pendingQuantities: any[],
  product: any
) {
  let totalAdded = 0
  for (const { pendingVariation, index } of pendingQuantities.map(
    (pendingVariation, index) => ({ pendingVariation, index })
  )) {
    const variation = product.variations[index]
    for (const { stock, stockIndex } of variation.stock_items.map(
      (stock: any, stockIndex: number) => ({ stock, stockIndex })
    )) {
      const pendingStock = pendingVariation.stock_items[stockIndex]
      pendingStock.prices = stock.prices
      const stockQuantity = parseInt(pendingStock.quantity, 10)

      if (stockQuantity >= 1) {
        addByProductIDAndCode(
          product,
          variation,
          pendingStock,
          stockQuantity,
          pendingVariation.embellishments
            ? pendingVariation.embellishments
            : null
        )
        totalAdded += stockQuantity
      } else if (stockQuantity <= 0) {
        addByProductIDAndCode(
          product,
          variation,
          pendingStock,
          0,
          pendingVariation.embellishments
            ? pendingVariation.embellishments
            : null
        )
      }
    }
  }

  return totalAdded
}
