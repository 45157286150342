import { IonButton, IonIcon } from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { arrowUp } from 'ionicons/icons'
import { RefObject, useEffect, useState } from 'react'
import { getTranslation as t } from 'translations'
import './ScrollToTop.scss'

type ScrollToTopProps = {
  refScroll?: RefObject<HTMLIonContentElement>
  whenToShowPositionY: number
  positionY: number
}

const ScrollToTop = ({
  refScroll,
  whenToShowPositionY,
  positionY
}: ScrollToTopProps) => {
  const [showTopBtn, setShowTopBtn] = useState(false)

  const { userLanguageCode } = useSession()

  const hangleGoToTop = () => {
    refScroll?.current && refScroll?.current.scrollToTop(240)
  }

  useEffect(() => {
    setShowTopBtn(positionY > whenToShowPositionY)
  }, [positionY, whenToShowPositionY])

  return (
    <>
      {showTopBtn && (
        <div className="scroll-top">
          <div className="scroll-top__footer">
            <IonButton shape="round" onClick={hangleGoToTop}>
              <span className="scroll-top__footer__text">
                {t('backToTop', userLanguageCode)}
              </span>
              <IonIcon
                className="icon"
                size="small"
                icon={arrowUp}
                color="primary"
              />
            </IonButton>
          </div>
        </div>
      )}
    </>
  )
}

export default ScrollToTop
