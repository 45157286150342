import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonRouterLink,
  IonToolbar
} from '@ionic/react'
import { useCart } from 'app/context/cart/useCart'
import { useSession } from 'app/context/session/useSession'
import { closeOutline } from 'ionicons/icons'
import React from 'react'
import { getTranslation } from 'translations'

interface RemoveProductModalProps {
  setProductToDelete: Function
  productToDelete: any
  modalRef: any
}

const RemoveProductModal: React.FC<RemoveProductModalProps> = (props) => {
  const { removeCartItem } = useCart()
  const { userLanguageCode } = useSession()

  return (
    <IonModal
      className="pwa-modal remove"
      onIonModalDidDismiss={() => {
        props.setProductToDelete(null)
      }}
      isOpen={props.productToDelete != null}
      ref={props.modalRef}
      initialBreakpoint={0.25}
    >
      <IonHeader className="no-border-toolbar">
        <IonToolbar>
          <h2>{getTranslation('removeItem', userLanguageCode)}</h2>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                props.modalRef.current?.dismiss()
              }}
            >
              <IonIcon
                className="icon"
                size="large"
                icon={closeOutline}
                color="primary"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="choices">
          <IonRouterLink
            onClick={() => {
              removeCartItem(
                props.productToDelete.number,
                props.productToDelete.color
              )
              props.setProductToDelete(null)
              props.modalRef.current?.dismiss()
              return false
            }}
          >
            {getTranslation('yes', userLanguageCode)}
          </IonRouterLink>
          <IonRouterLink
            onClick={() => {
              props.modalRef.current?.dismiss()
              props.setProductToDelete(null)
            }}
          >
            {getTranslation('no', userLanguageCode)}
          </IonRouterLink>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default RemoveProductModal
