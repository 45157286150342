import { useConfig } from 'app/context/config/ConfigContext'
import { useEffect } from 'react'
import { setStoredKey } from 'services/storage'
import constants from '../../constants'

const useClientImage = () => {
  const { data, isLoading } = useConfig()

  useEffect(() => {
    if (data) {
      setStoredKey(constants.CLIENT_LOGO, data.brandDynamic.config.images.logo)
    }
  }, [data])

  return {
    brandLogo: data?.brandDynamic?.config?.images?.logo || '',
    brandHero: data?.brandDynamic?.config?.images?.mainBackground || '',
    dataLoaded: !isLoading
  }
}

export default useClientImage
