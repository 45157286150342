import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar
} from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { closeOutline } from 'ionicons/icons'
import React from 'react'
import { getTranslation } from 'translations'

interface OrderConfirmModalProps {
  setOrderConfirmOpen: Function
  modalRef: any
  modalOpen: boolean
  handleOrderSubmit: Function
}

const OrderConfirmModal: React.FC<OrderConfirmModalProps> = (props) => {
  const { userLanguageCode } = useSession()

  const toggleModal = () => props.setOrderConfirmOpen(!props.modalOpen)

  // this sets the height of the modal properly....
  const breakpoint = 270 / window.innerHeight

  return (
    <>
      <IonModal
        className="pwa-modal info"
        onIonModalDidDismiss={() => {
          props.setOrderConfirmOpen(false)
        }}
        isOpen={props.modalOpen}
        ref={props.modalRef}
        initialBreakpoint={breakpoint}
      >
        <IonHeader className="no-border-toolbar">
          <IonToolbar>
            <h2>{getTranslation('confirmOrder', userLanguageCode)}</h2>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  props.modalRef.current?.dismiss()
                }}
              >
                <IonIcon
                  className="icon"
                  size="large"
                  icon={closeOutline}
                  color="primary"
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <p>{getTranslation('confirmOrderBody', userLanguageCode)}</p>
          <IonFooter className="ion-padding footer ion-no-border">
            <IonButton shape="round" color={'secondary'} onClick={toggleModal}>
              {getTranslation('cancel', userLanguageCode)}
            </IonButton>
            <IonButton
              shape="round"
              onClick={() => {
                props.handleOrderSubmit()
                props.setOrderConfirmOpen(false)
              }}
            >
              {getTranslation('submitOrder', userLanguageCode)}
            </IonButton>
          </IonFooter>
        </IonContent>
      </IonModal>
    </>
  )
}

export default OrderConfirmModal
