import {
  IonButton,
  IonIcon,
  IonItem,
  IonTitle,
  IonicSlides
} from '@ionic/react'
import Image from 'app/components/image/image'
import {
  chevronBackOutline,
  chevronForwardOutline,
  linkOutline,
  playCircleOutline,
  sendOutline
} from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './Links.scss'

interface Props {
  links: any[]
  clientName: string
}

const getLinkIcon = (type: any) => {
  let icon = linkOutline
  if (type == 'video') {
    icon = playCircleOutline
  } else if (type == 'email') {
    icon = sendOutline
  }
  return icon
}

const getLinkURL = (type: any, url: any) => {
  let newURL = url
  if (type == 'email') {
    newURL = 'mailto:' + url
  }
  return newURL
}

const Links: React.FC<Props> = ({ links, clientName }) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>()
  const [textOnlyLinks, setTextOnlyLinks] = useState<any[]>([])
  const [imageLinks, setImageLinks] = useState<any[]>([])
  const [showSlider, setShowSlider] = useState<boolean>(false)

  const shouldDestroySlider = () => {
    setShowSlider(window.innerWidth >= 834)
  }

  useEffect(() => {
    window.addEventListener('resize', shouldDestroySlider)
    return () => window.removeEventListener('resize', shouldDestroySlider)
  })

  useEffect(() => {
    let currentLinksIndex = 0
    const textLinks: any[] = []
    const imageLinksTemp: any[] = []

    links.forEach((link: any) => {
      if (textLinks[currentLinksIndex] == null) {
        textLinks[currentLinksIndex] = []
      }
      if (link.image_url == null) {
        textLinks[currentLinksIndex].push(link)
        if (textLinks[currentLinksIndex].length == 10) {
          currentLinksIndex++
        }
      } else {
        imageLinksTemp.push(link)
      }
    })

    setTextOnlyLinks(textLinks)
    setImageLinks(imageLinksTemp)
    shouldDestroySlider()
  }, [links])

  const iconLink = (index: number, link: any) => {
    return (
      <div key={index} className="icon-link">
        <a
          href={getLinkURL(link.type, link.url)}
          target="_blank"
          rel="noreferrer"
        >
          <IonIcon icon={getLinkIcon(link.type)}></IonIcon> {link.title}
        </a>
      </div>
    )
  }

  const imageLink = (link: any) => {
    return (
      <a
        href={getLinkURL(link.type, link.url)}
        target="_blank"
        className="image-link"
        rel="noreferrer"
      >
        <Image src={link.image_url} />
        {link.title}
      </a>
    )
  }

  const moveToNewSlide = async (direction: string) => {
    if (swiperInstance) {
      if (direction === 'next') {
        swiperInstance.slideNext()
      } else if (direction === 'prev') {
        swiperInstance.slidePrev()
      }
    }
  }

  return (
    <div className="explore-links">
      {showSlider && textOnlyLinks.length + imageLinks.length > 3 && (
        <div className="slider-buttons">
          <IonButton
            className="next-button"
            fill="clear"
            onClick={() => moveToNewSlide('prev')}
          >
            <IonIcon icon={chevronBackOutline} color="secondary" />
          </IonButton>
          <IonButton
            className="next-button"
            fill="clear"
            onClick={() => moveToNewSlide('next')}
          >
            <IonIcon icon={chevronForwardOutline} color="secondary" />
          </IonButton>
        </div>
      )}
      <IonItem lines="none">
        <div className="links-section">
          <IonTitle class="link-text" size={'small'} color={'primary'}>
            <div className="ion-text-wrap">{clientName} Links</div>
          </IonTitle>

          {showSlider && (
            <Swiper
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              modules={[IonicSlides]}
              pagination={false}
              speed={400}
              spaceBetween={24}
              slidesPerView={3}
              slidesPerGroup={3}
            >
              {imageLinks.map((link: any, index: number) => {
                return (
                  <SwiperSlide key={index} className="link-image">
                    {imageLink(link)}
                  </SwiperSlide>
                )
              })}

              {textOnlyLinks.map((linkArray: any, index: number) => {
                return (
                  <SwiperSlide key={index} className="link-image">
                    {linkArray.map((link: any, linkIndex: number) => {
                      return iconLink(linkIndex, link)
                    })}
                  </SwiperSlide>
                )
              })}
            </Swiper>
          )}

          {!showSlider && (
            <>
              {imageLinks.map((link: any, index: number) => {
                return (
                  <div key={index} className="link-image">
                    {imageLink(link)}
                  </div>
                )
              })}

              {textOnlyLinks.map((linkArray: any, index: number) => {
                return (
                  <div key={index} className="link-image">
                    {linkArray.map((link: any, linkIndex: number) => {
                      return iconLink(linkIndex, link)
                    })}
                  </div>
                )
              })}
            </>
          )}
        </div>
      </IonItem>
    </div>
  )
}

export default Links
