import { IonItem } from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import React from 'react'
import { getTranslation } from 'translations'

interface LinkSectionProps {
  links: string[]
  large: boolean
}

const LinkSection: React.FC<LinkSectionProps> = (props) => {
  const { userLanguageCode } = useSession()

  return (
    <>
      {props.links.map((link: string, index: number) => {
        if (link == 'orders') {
          return (
            <IonItem
              key={index}
              lines="none"
              detail={false}
              className={props.large ? 'large-item' : 'small-item'}
              routerLink={'/manage/orders'}
            >
              {getTranslation('orders', userLanguageCode)}
            </IonItem>
          )
        }

        if (link == 'explore') {
          return (
            <IonItem
              key={index}
              lines="none"
              detail={false}
              className={props.large ? 'large-item' : 'small-item'}
              routerLink={'/explore'}
            >
              {getTranslation('explore', userLanguageCode)}
            </IonItem>
          )
        }

        if (link == 'dashboard') {
          return (
            <IonItem
              key={index}
              lines="none"
              detail={false}
              className={props.large ? 'large-item' : 'small-item'}
              routerLink={'/explore'}
            >
              {getTranslation('dashboard', userLanguageCode)}
            </IonItem>
          )
        }

        if (link == 'view_catalog') {
          return (
            <IonItem
              key={index}
              lines="none"
              detail={false}
              className={props.large ? 'large-item' : 'small-item'}
              routerLink={'/explore'}
            >
              {getTranslation('catalogs', userLanguageCode)}
            </IonItem>
          )
        }

        if (link == 'order_history') {
          return (
            <IonItem
              key={index}
              lines="none"
              detail={false}
              className={props.large ? 'large-item' : 'small-item'}
              routerLink={'/manage/orders'}
            >
              {getTranslation('orderHistory', userLanguageCode)}
            </IonItem>
          )
        }
      })}
    </>
  )
}

export default LinkSection
