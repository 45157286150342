import { checkmarkOutline } from 'ionicons/icons'
import { PopUpNotification } from 'models'

export const SaveNotification: PopUpNotification = {
  buttonRoute: '/manage/orders',
  buttonText: 'View Orders',
  text: 'Order Successfully Saved!'
}

export const AutoSaveNotification: PopUpNotification = {
  buttonRoute: '/manage/orders',
  buttonText: 'View Orders',
  text: 'Order Auto Saved.'
}

export const SubmitNotification: PopUpNotification = {
  buttonRoute: '/manage/orders',
  buttonText: 'View Orders',
  text: 'Order submitted',
  icon: checkmarkOutline
}

export function UpdateNotification(catalogId: string): PopUpNotification {
  return {
    buttonRoute: '/cart',
    buttonText: 'Go To Cart',
    text: 'Your Cart has been Updated!',
    icon: checkmarkOutline
  } as PopUpNotification
}

export function CartNotification(amount: number): PopUpNotification {
  let textMessage = '1 Item Added to Your Cart!'
  {
    amount > 1 && (textMessage = amount + ' Items Added to Your Cart!')
  }
  return {
    buttonRoute: '/cart',
    buttonText: 'Go To Cart',
    icon: checkmarkOutline,
    text: textMessage
    // amount: amount
  } as PopUpNotification
}
