import { IonButton, IonInput, IonLoading } from '@ionic/react'
import PWAModal, { PWAModalProps } from 'app/components/modal-layout/PWAModal'
import { useSession } from 'app/context/session/useSession'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { getStoreKeyName } from '@apollo/client/utilities'
import { setStoredKey } from 'services/storage'
import { updateUser } from 'services/user'
import constants from '../../../../../constants'

import InputWrapper from 'app/components/InputWrapper/InputWrapper'

import './ModalSettings.scss'

type ModalSettingsProps = Omit<PWAModalProps, 'header' | 'footer' | 'children'>

const ModalSettings: FC<ModalSettingsProps> = ({ isOpen, toggle }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({ reValidateMode: 'onBlur' })
  const [showLoading, setShowLoading] = useState(false)

  const { getUserInfo, userInfo } = useSession()

  const onSubmit = async (data: any) => {
    if (!userInfo) return
    const body = {
      first_name: data.first_name,
      last_name: data.last_name,
      username: data.username,
      email: data.email,
      ...(data.password && { password: data.password }),
      ...(data.password_confirmation && {
        password_confirmation: data.password_confirmation
      })
    }
    try {
      setShowLoading(true)
      const result = await updateUser(body, userInfo?._id)

      reset({ password: '', password_confirmation: '' })
      const storedResult: any = getStoreKeyName(constants.USER_INFO)
      await getUserInfo(true)
      toggle()
      if (!storedResult.validateDynamic) return
      storedResult.validateDynamic.user = result?.data?.updateAccountDynamic
      await setStoredKey(constants.USER_INFO, result.data.updateAccountDynamic)
    } catch (error) {
      console.error(error)
    } finally {
      setShowLoading(false)
    }
  }

  const getFormFields = async () => {
    await getUserInfo(true)
  }

  useEffect(() => {
    getFormFields()
  }, [])

  useEffect(() => {
    reset(userInfo)
  }, [reset, userInfo])

  return (
    <PWAModal
      footer={
        <>
          {' '}
          <IonButton
            shape="round"
            fill="outline"
            className="account-settings-footer"
            onClick={() => {
              reset()
              toggle()
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            shape="round"
            expand="block"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </IonButton>
        </>
      }
      header={<h2 className="title">Account Settings</h2>}
      {...{ isOpen, toggle }}
    >
      <div className="pwa-modal">
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          duration={5000}
        />
        <form
          role="form"
          className="form-modal modal-settings"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputWrapper
            labelMessage="First Name"
            hasError={!!errors.first_name}
            errorMessage={errors.first_name?.message as string}
          >
            <IonInput
              {...register('first_name', {
                required: 'first name is name required'
              })}
            ></IonInput>
          </InputWrapper>

          <InputWrapper
            labelMessage="Last Name"
            hasError={!!errors.last_name}
            errorMessage={errors.last_name?.message as string}
          >
            <IonInput
              {...register('last_name', {
                required: 'last name is name required'
              })}
            ></IonInput>
          </InputWrapper>

          <InputWrapper
            labelMessage="Username"
            hasError={!!errors.username}
            errorMessage={errors.username?.message as string}
          >
            <IonInput
              {...register('username', {
                required: 'username is name required'
              })}
            ></IonInput>
          </InputWrapper>

          <InputWrapper
            labelMessage="E-mail"
            hasError={!!errors.email}
            errorMessage={errors.email?.message as string}
          >
            <IonInput
              {...register('email', {
                required: 'Email is a required field',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Please enter a valid E-mail address'
                }
              })}
            />
          </InputWrapper>

          <InputWrapper
            labelMessage="Password"
            hasError={!!errors.password}
            errorMessage={errors.password?.message as string}
          >
            <IonInput
              type="password"
              {...register('password', {
                minLength: {
                  value: 6,
                  message: 'must be 6 chars'
                },
                validate: (value) => {
                  if (!value) return
                  return (
                    [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every(
                      (pattern) => pattern.test(value)
                    ) || 'must include lower, upper, number, and special chars'
                  )
                }
              })}
            ></IonInput>
          </InputWrapper>

          <InputWrapper
            labelMessage="Confirm Password"
            hasError={!!errors.password_confirmation}
            errorMessage={errors.password_confirmation?.message as string}
          >
            <IonInput
              type="password"
              {...register('password_confirmation', {
                validate: (val: string) => {
                  if (watch('password') != val) {
                    return 'Passwords do no match'
                  }
                }
              })}
            ></IonInput>
          </InputWrapper>
        </form>
      </div>
    </PWAModal>
  )
}

export default ModalSettings
