import { IonButton, IonContent, IonPage } from '@ionic/react'
import Image from 'app/components/image/image'
import { useSession } from 'app/context/session/useSession'
import useClientImage from 'app/hooks/useClientImage'
import { useHistory } from 'react-router'
import { getTranslation } from 'translations'
import './NoAccess.scss'

const NoAccess: React.FC = () => {
  const { dataLoaded, brandHero, brandLogo } = useClientImage()
  const history = useHistory()

  const { userLanguageCode, logout } = useSession()

  return (
    <>
      {dataLoaded && (
        <IonPage className="no-access">
          <IonContent>
            <Image className="brand-image" src={brandHero} />
            <Image className="brand-logo" src={brandLogo} />
            <IonContent class="ion-padding">
              <p>
                {getTranslation('cannotAccess', userLanguageCode)}
                <br />
                <br />
                {getTranslation('loginDifferentDevice', userLanguageCode)}
                <br />
                <IonButton
                  onClick={() => {
                    logout()
                    history.push('/login')
                  }}
                >
                  Login
                </IonButton>
              </p>
            </IonContent>
          </IonContent>
        </IonPage>
      )}
    </>
  )
}

export default NoAccess
