import { IonButton, IonIcon, IonicSlides } from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons'
import { useState } from 'react'
import { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getTranslation } from 'translations'
import './Announcements.scss'

type Announcement = {
  text: string
  created_at: string
}
interface Props {
  announcements: Announcement[]
}

const Announcements: React.FC<Props> = ({ announcements = [] }) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>()
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const { userLanguageCode } = useSession()

  const moveToNewSlide = async (direction: string) => {
    if (swiperInstance) {
      if (direction === 'next') {
        swiperInstance.slideNext()
      } else if (direction === 'prev') {
        swiperInstance.slidePrev()
      }
    }
  }

  const onWillChange = async (event: any) => {
    setCurrentIndex(event.realIndex)
  }

  return (
    <div className="announcements">
      <div className="announcements-container">
        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          modules={[IonicSlides]}
          pagination={false}
          autoHeight={true}
          slidesPerView={1}
          onSlideChangeTransitionStart={(event: any) => onWillChange(event)}
        >
          {announcements.map((announcement: Announcement, index: number) => {
            return (
              <SwiperSlide key={index}>
                <div className="slide-wrapper">
                  <div className="announce-title">
                    {getTranslation('latestAnnouncements', userLanguageCode)}
                  </div>
                  <div className="announce-text">{announcement.text}</div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        {announcements.length > 1 && (
          <div className="nav">
            <IonButton
              disabled={currentIndex === 0}
              fill="clear"
              onClick={() => moveToNewSlide('prev')}
            >
              <IonIcon icon={chevronBackOutline}></IonIcon>{' '}
            </IonButton>
            <div className="nav-text">
              {currentIndex + 1} {getTranslation('of', userLanguageCode)}{' '}
              {announcements.length}
            </div>
            <IonButton
              disabled={currentIndex + 1 === announcements.length}
              fill="clear"
              onClick={() => moveToNewSlide('next')}
            >
              <IonIcon icon={chevronForwardOutline}></IonIcon>{' '}
            </IonButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default Announcements
