import { API, graphqlOperation } from 'aws-amplify'
import { cloneDeep } from 'lodash'
import {
  CartItem,
  CartItemVariation,
  DropShipAddress,
  ElasticOrder,
  Order,
  Product,
  VariationImages
} from 'models'
import constants from '../constants'
import { saveOrderDynamic, updateOrderDynamic } from '../graphql/mutations'
import {
  orderDynamic,
  ordersDynamic,
  salesProgramsDynamic
} from '../graphql/queries'
import { getJwt } from './auth'
import { getFullCatalog, totalStockAvailble } from './catalog'
import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage'

const SmartySDK = require('smartystreets-javascript-sdk')
const SmartyCore = SmartySDK.core
const Lookup = SmartySDK.usStreet.Lookup
const orderPropertiesToRemove = [
  'products',
  'number',
  'programs',
  'discounts',
  'total_prices',
  'net_price',
  'state'
]
const productPropertiesToRemove = [
  'name',
  'isCustomized',
  'color_name',
  'images',
  'total_prices',
  'catalog_key',
  'primary_warehouse'
]
const variationPropertiesToRemove = ['can_embellish', 'prices', 'total_prices']

export async function getOrders(range: string, online: boolean) {
  const storedValue = await getStoredKey(constants.ORDERS + range)

  if (shouldUseCachedValue(storedValue, online, true)) {
    return storedValue
  } else if (online) {
    const clientName =
      (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'
    const jwt = await getJwt()
    const result: any = await API.graphql(
      graphqlOperation(ordersDynamic, {
        jwt: jwt,
        Range: range,
        client: clientName
      })
    )
    await setStoredKey(constants.ORDERS + range, result.data)
    return result.data
  } else {
    return {}
  }
}

export async function getOrder(
  id: string,
  online: boolean
): Promise<ElasticOrder | null> {
  const storedValue = await getStoredKey(constants.ORDERS + '_' + id)

  if (online || storedValue) {
    if (shouldUseCachedValue(storedValue, online, true)) {
      return storedValue
    } else {
      const clientName =
        (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'
      const jwt = await getJwt()
      const result: any = await API.graphql(
        graphqlOperation(orderDynamic, { jwt: jwt, id: id, client: clientName })
      )
      await setStoredKey(constants.ORDERS + '_' + id, result.data.orderDynamic)
      return result.data.orderDynamic
    }
  } else {
    return null
  }
}

export async function saveElasticOrder(
  elasticOrder: ElasticOrder,
  online: boolean
) {
  if (online) {
    const clientName =
      (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'
    const jwt = await getJwt()
    const result: any = await API.graphql(
      graphqlOperation(saveOrderDynamic, {
        jwt: jwt,
        body: removeBadProperties(elasticOrder),
        client: clientName
      })
    )
    return result.data
  } else {
    alert('You must have an internet connection to perform this action.')
    return false
  }
}

export async function getSalesPrograms(id: string, online: boolean) {
  if (online) {
    const clientName =
      (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'
    const jwt = await getJwt()
    const result: any = await API.graphql(
      graphqlOperation(salesProgramsDynamic, {
        jwt,
        document_id: id,
        client: clientName
      })
    )
    return result.data
  } else {
    alert('You must have an internet connection to perform this action.')
    return false
  }
}

export async function updateElasticOrder(
  elasticOrder: ElasticOrder,
  online: boolean
) {
  if (online) {
    const clientName =
      (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'
    const jwt = await getJwt()
    const result: any = await API.graphql(
      graphqlOperation(updateOrderDynamic, {
        jwt: jwt,
        body: removeBadProperties(elasticOrder),
        id: elasticOrder._id,
        client: clientName
      })
    )
    return result.data
  } else {
    alert('You must have an internet connection to perform this action.')
    return false
  }
}

// sending these properties to API causes an error so we have to remove them
function removeBadProperties(elasticOrder: ElasticOrder) {
  orderPropertiesToRemove.forEach((propertyStr: string) => {
    const property = propertyStr as keyof typeof elasticOrder
    if (elasticOrder.hasOwnProperty(property)) {
      delete elasticOrder[property]
    }
  })

  elasticOrder.pages.forEach((page: Order) => {
    page.page_products.forEach((product: CartItem) => {
      productPropertiesToRemove.forEach((propertyStr: string) => {
        const property = propertyStr as keyof typeof product
        if (product.hasOwnProperty(property)) {
          delete product[property]
        }
      })

      product.page_items.forEach((item: CartItemVariation) => {
        variationPropertiesToRemove.forEach((propertyStr: string) => {
          const property = propertyStr as keyof typeof item
          if (item.hasOwnProperty(property)) {
            delete item[property]
          }
        })
      })
    })
  })

  return elasticOrder
}

export async function validateAddress(
  address: DropShipAddress
): Promise<boolean> {
  const addressToCheck = new Lookup()
  addressToCheck.addressee = address.name
  addressToCheck.street = address.address1
  addressToCheck.street2 = address.address2
  addressToCheck.city = address.city
  addressToCheck.state = address.state
  addressToCheck.zipCode = address.zip
  addressToCheck.maxCandidates = 3
  addressToCheck.match = 'strict'

  const batch = new SmartyCore.Batch()
  batch.add(addressToCheck)

  const result = await handleSmartyLookup(batch)
  if (
    result.lookups &&
    result.lookups.length > 0 &&
    result.lookups[0].result.length > 0
  ) {
    return true
  } else {
    return false
  }
}

async function handleSmartyLookup(batch: any) {
  const credentials = new SmartyCore.SharedCredentials(
    process.env.REACT_APP_SMARTY_STREETS_CREDS
  )

  const clientBuilder = new SmartyCore.ClientBuilder(credentials).withBaseUrl(
    'https://us-street.api.smartystreets.com/street-address'
  )
  const client = clientBuilder.buildUsStreetApiClient()

  try {
    return await client.send(batch)
  } catch (err) {
    return 'There was an error'
  }
}

export function getProductImages(
  cartItem: CartItem,
  products: Product[]
): VariationImages | null {
  let images = null
  products.forEach((product) => {
    if (product.number === cartItem.product_number) {
      product.variations.forEach((varient) => {
        if (varient?.code === cartItem.color_code) {
          images = varient.images
        }
      })
    }
  })

  return images
}

export function getPrimaryWarehouse(
  cartItem: CartItem,
  products: Product[]
): string {
  let warehouse = ''
  products.forEach((product) => {
    if (product.number === cartItem.product_number) {
      warehouse = product.primary_warehouse
    }
  })

  return warehouse
}

export async function getTotalOrderPrice(
  cartItems: CartItem[]
): Promise<number> {
  const priceType = await getStoredKey(constants.APP_PRICE_TYPE)
  let total = 0.0
  if (priceType && priceType != '' && priceType != 'none') {
    cartItems.forEach((item: CartItem) => {
      item.page_items.forEach((stock: CartItemVariation) => {
        if (stock.quantity > 0) {
          if (stock.prices) {
            total += parseFloat(stock.prices.elastic_wholesale) * stock.quantity
          } else if (stock.total_prices) {
            total += parseFloat(stock.total_prices.elastic_wholesale)
          }
        }
      })
    })
  }

  return parseFloat(total.toFixed(2))
}

export function getBadgeType(orderState: string): string {
  let badgeType = 'medium'
  switch (orderState) {
    case 'draft':
      badgeType = 'light'
      break
    case 'final_submission':
      badgeType = 'dark'
      break

    default:
      break
  }

  return badgeType
}

export function getBadgeText(orderState: string): string {
  let badgeType = ''
  switch (orderState) {
    case 'final_submission':
      badgeType = 'Submitted'
      break

    default:
      badgeType = 'Draft'
      break
  }

  return badgeType
}

export function removeZeroedCartItems(items: CartItem[]) {
  const finalCartItems: CartItem[] = []
  items.forEach((item: CartItem) => {
    let hasStock = false
    item.page_items.forEach((stock: CartItemVariation) => {
      if (stock.quantity > 0) {
        hasStock = true
      }
    })

    if (hasStock) {
      finalCartItems.push(item)
    }
  })

  return finalCartItems
}

export async function modifyOrderWithAvailibility(
  catalogId: string,
  customerNumber: string,
  cartItems: CartItem[]
) {
  let modified = false
  let updatedCartItems = cloneDeep(cartItems)

  // hydrates stored value with fresh catalog data
  await getFullCatalog(
    catalogId,
    customerNumber,
    'price',
    'asc',
    '',
    false,
    true,
    true,
    '',
    '',
    true
  )

  for (const item of updatedCartItems) {
    for (const variation of item.page_items) {
      if (variation.quantity && variation.quantity > 0) {
        let warehouse_id = ''
        if (item.primary_warehouse) {
          warehouse_id = item.primary_warehouse
        } else if (
          variation.quantity_source &&
          variation.quantity_source.length > 0
        ) {
          warehouse_id = variation.quantity_source[0].source
        }

        const totalAvailibleStock = await totalStockAvailble(
          variation.stock_item_key,
          warehouse_id
        )
        if (variation.quantity > totalAvailibleStock) {
          variation.quantity = totalAvailibleStock
          modified = true
        }
      }
    }
  }

  updatedCartItems = removeZeroedCartItems(updatedCartItems)

  return { modified: modified, items: updatedCartItems }
}
