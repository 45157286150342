import { IonButton, IonInput, useIonLoading } from '@ionic/react'
import TextInput from 'app/components/text-input/TextInput'
import { useSession } from 'app/context/session/useSession'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { sendForgotPasswordEmail } from 'services/auth'
import { getTranslation } from 'translations'
import constants from '../../../../../constants'
import './EmailCodeForm.scss'

const EmailCodeForm = ({
  onSuccess
}: {
  onSuccess: (payload: { email: string; codeSuccess: boolean }) => void
}) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors }
  } = useForm({ mode: 'all', defaultValues: { email: '' } })
  const [present, dismiss] = useIonLoading()
  const { userLanguageCode } = useSession()
  const [resetting, setResetting] = useState(Boolean)

  async function getCode({ email }: { email: string }) {
    setResetting(true)
    await present({ message: 'Sending code...' })
    await sendForgotPasswordEmail(email)
    onSuccess({ email, codeSuccess: true })
    setResetting(false)
    await dismiss()
  }

  return (
    <form onSubmit={handleSubmit(getCode)} className="email-code-form">
      <TextInput label={getTranslation('email', userLanguageCode)}>
        <IonInput
          {...register('email', {
            required: true,
            pattern: {
              value: constants.EMAIL_REGEX,
              message: getTranslation('enterVaildEmail', userLanguageCode)
            }
          })}
        />
      </TextInput>
      {errors.email && (
        <p className="error">
          {getTranslation('enterVaildEmail', userLanguageCode)}
        </p>
      )}
      <IonButton
        className="ion-margin-top"
        type="submit"
        expand="block"
        disabled={resetting || !isDirty || !isValid}
        border-radius="15px"
      >
        {getTranslation('nextStep', userLanguageCode)}
      </IonButton>
    </form>
  )
}

export default EmailCodeForm
