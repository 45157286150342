import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonToolbar,
  useIonLoading,
  useIonViewWillEnter
} from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import Breadcrumbs from 'app/components/header/breadcrumbs/Breadcrumbs'
import {
  CartNotification,
  UpdateNotification
} from 'app/components/header/notification/NotificationTypes'
import { useCart } from 'app/context/cart/useCart'
import { useConfig } from 'app/context/config/ConfigContext'
import { useSession } from 'app/context/session/useSession'
import { useToaster } from 'app/context/toast/useToast'
import useMessage from 'app/hooks/useMessage'
import { arrowForwardOutline, closeOutline } from 'ionicons/icons'
import { cloneDeep } from 'lodash'
import type { Config } from 'models/Config'
import { useEffect, useRef, useState } from 'react'
import { getProduct } from 'services/catalog'
import { getTranslation } from 'translations'
import './ProductDetailDisplay.scss'
import ProductColorMenu from './product-color-menu/ProductColorMenu'
import ProductCustomizeMenu from './product-customize-menu/ProductCustomizeMenu'
import {
  addItemToCart,
  calculateTotalSelectedItems,
  calculateTotalSelectedStockPrice,
  customizeButtonText,
  customizeButtonTextOther,
  filterOutNoStockGroups,
  getGroups,
  loadProductData
} from './product-details-api'
import ProductSizeMenu from './product-size-menu/ProductSizeMenu'
import ProductSlides from './product-slides/ProductSlides'
import ProductTitle from './product-title/ProductTitle'

interface ProductDisplayProps {
  hideItemNumber: boolean
  mockNumber?: string
}

const ProductDetailDisplay: React.FC<ProductDisplayProps> = (props) => {
  const { data: brandConfig } = useConfig()
  const { data: userData } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo()
  })

  const pathnames = window.location.pathname.split('/')
  const number = props?.mockNumber || pathnames[pathnames.length - 1]
  const { data: productData } = useQuery({
    queryKey: ['product', number],
    queryFn: () => getProduct(number),
    enabled: !!number
  })
  const [product, setProduct] = useState<any>({})
  const [iframeUrl, setIframeUrl] = useState<string>('')
  const [cacheBust, setCacheBust] = useState<number>(0)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [selectedColor, setSelectedColor] = useState<any>({})
  const [selectedGroup, setSelectedGroup] = useState<any>('')
  const [showIframe, setShowIframe] = useState<boolean>(false)
  const [showSizeLabel, setShowSizeLabel] = useState<boolean>(true)
  const [totalSelectedItemCost, setTotalSelectedItemCost] =
    useState<string>('$0.00')
  const [totalSelectedItems, setTotalSelectedItems] = useState<number>(0)
  const [isACartUpdate, setIsACartUpdate] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [hasPendingItems, setHasPendingItems] = useState<boolean>(false)
  const [pendingQuantities, setPendingQuantities] = useState<any>([])
  const [groups, setGroups] = useState<any>([])
  const [activeEmbellishments, setActiveEmbellishments] = useState<any>([])

  const viewRef = useRef(null)
  const orderSectionRef = useRef<null | HTMLDivElement>(null)
  const customizedItemDetail = useRef<null | HTMLDivElement>(null)
  const modal = useRef<HTMLIonModalElement>(null)
  const addToCartButton = useRef<HTMLIonButtonElement>(null)
  const productSlider: any = useRef(null)

  const { userCatalogId, userLanguageCode, getUserInfo } = useSession()

  const {
    addByProductIDAndCode,
    stockQuantityAlreadyInCart,
    anyCurrentEmbellishments,
    appPriceType
  } = useCart()
  const { addNotification } = useToaster()
  const [present, dismiss] = useIonLoading()

  useIonViewWillEnter(() => {
    setCacheBust((oldNum) => {
      return oldNum + 1
    })
  })

  useEffect(() => {
    let variationCode = ''
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params.variationCode != null) {
      variationCode = params.variationCode
    }

    getProductData(number, variationCode)
    getBrandSettings()
  }, [cacheBust])

  useEffect(() => {
    async function getGroupsData() {
      const tempGroups = await filterOutNoStockGroups(
        getGroups(selectedColor),
        selectedColor
      )

      if (tempGroups.length > 1) {
        setGroups(tempGroups)
        setSelectedGroup('')
      } else if (tempGroups.length <= 1) {
        setSelectedGroup('ALL')
      }

      setGroups(tempGroups)
    }

    getGroupsData()
  }, [selectedColor])

  useEffect(() => {
    const price = calculateTotalSelectedStockPrice(pendingQuantities)
    setTotalSelectedItemCost('$' + price)
    setHasPendingItems(price != 0)

    setTotalSelectedItems(calculateTotalSelectedItems(pendingQuantities))

    // for custom items
    if (
      !loadingData &&
      pendingQuantities.length > 0 &&
      pendingQuantities[0].embellishments &&
      pendingQuantities[0].stock_items[0].quantity == 1
    ) {
      addToCart()
    }
  }, [pendingQuantities])

  useMessage('message', (send: any, payload: any) => {
    async function addCustomProductToCart(jsonString: string) {
      setShowIframe(false)
      await addCustomProduct(JSON.parse(jsonString))
      setActiveEmbellishments(() => {
        return JSON.parse(jsonString)
      })
    }

    if (typeof payload == 'string' && payload.startsWith('[{')) {
      addCustomProductToCart(payload)
    }
  })

  function getBrandSettings() {
    if (brandConfig?.brandDynamic?.config) {
      const config: Config = brandConfig.brandDynamic.config
      if (config.flags && config.flags.hideSizeLabelIn3upView) {
        setShowSizeLabel(!config.flags.hideSizeLabelIn3upView)
      }
    }
  }

  async function getProductData(number: string, variationCode: string) {
    setIsACartUpdate(false)
    setCurrentIndex(0)
    setLoadingData(true)
    setGroups([])
    setSelectedGroup('')
    setActiveEmbellishments([])
    try {
      const allProductInformation: any = await loadProductData(
        number,
        variationCode,
        userData,
        anyCurrentEmbellishments,
        stockQuantityAlreadyInCart,
        appPriceType
      )

      if (allProductInformation.productData.number) {
        setProduct(allProductInformation.productData)
        if (allProductInformation.selectedColor) {
          setSelectedColor(allProductInformation.selectedColor)
          setCurrentIndex(allProductInformation.variationIndex)
        }
        setPendingQuantities([...allProductInformation.pendingQuantities])
        setIsACartUpdate(allProductInformation.isCartUpdate)
        if (allProductInformation.activeEmbellishments.length > 0) {
          setActiveEmbellishments(allProductInformation.activeEmbellishments)
        }
        if (allProductInformation.fullUrl) {
          setIframeUrl(allProductInformation.fullUrl)
        }
      }

      setLoadingData(false)

      if (variationCode != '') {
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!productData?.customizable) {
            if (orderSectionRef && orderSectionRef.current) {
              orderSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
            }
          } else {
            if (customizedItemDetail && customizedItemDetail.current) {
              customizedItemDetail.current?.scrollIntoView({
                behavior: 'smooth'
              })
            }
          }
        }, 200)
      }
    } catch (error) {
      setLoadingData(false)
      console.error(error)
    }
  }

  async function updateStockQuantity(event: any, stockIndex: number) {
    let value: number | string = '0'
    if (event.detail.value) {
      value = event.detail.value?.replace(/[^0-9]/g, '')
    }

    if (parseInt(value as string, 10) < 0) {
      value = 0
    }

    setPendingQuantities((previousQuantities: any[]) => {
      previousQuantities[currentIndex].stock_items[stockIndex].quantity =
        parseInt(value as string, 10)
      return [...previousQuantities]
    })
  }

  async function addCustomProduct(data: any) {
    const tempPendingQuantities = cloneDeep(pendingQuantities)

    tempPendingQuantities[0].embellishments = {
      data: data,
      type: 'CallawayGolf'
    }
    tempPendingQuantities[0].stock_items[0].quantity = 1
    setPendingQuantities(tempPendingQuantities)
  }

  async function showCustomizer() {
    setShowIframe(true)
    await present({ message: 'Loading configurator...' })
  }

  async function addToCart() {
    const totalAdded = addItemToCart(
      addByProductIDAndCode,
      pendingQuantities,
      product
    )

    if (totalAdded > 0) {
      if (!isACartUpdate) {
        await addNotification(CartNotification(totalAdded))
        setIsACartUpdate(true)
      } else {
        await addNotification(UpdateNotification(userCatalogId))
      }
    }
  }

  return (
    <>
      <div className={`product-detail ${!loadingData ? 'reveal' : ''}`}>
        <IonLoading message="Loading product..." isOpen={loadingData} />
        {product.name && !loadingData && (
          <Breadcrumbs pageName={product.name} skipLast={true} />
        )}
        <ProductTitle
          showItemNumber={!props.hideItemNumber}
          product={product}
        />
        {!loadingData && product.variations && pendingQuantities.length > 0 && (
          <ProductSlides
            innerRef={productSlider}
            {...{
              selectedColor,
              product,
              currentIndex,
              setSelectedColor,
              setCurrentIndex
            }}
          />
        )}

        {product.customizable && iframeUrl != '' && (
          <div ref={customizedItemDetail}>
            <ProductCustomizeMenu
              embellishments={activeEmbellishments}
              showCustomizer={showCustomizer}
            />
          </div>
        )}
        {!product.customizable && !loadingData && product.variations && (
          <>
            <div className="order-title">
              <h3>{getTranslation('order', userLanguageCode)}</h3>
            </div>

            {product.variations.length > 1 && (
              <ProductColorMenu
                innerRef={productSlider}
                product={product}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />
            )}

            {pendingQuantities.length > 0 && (
              <div ref={orderSectionRef} className="input-menu">
                <ProductSizeMenu
                  updateStockQuantity={updateStockQuantity}
                  product={product}
                  currentIndex={currentIndex}
                  pendingQuantities={pendingQuantities}
                  groups={groups}
                  selectedGroup={selectedGroup}
                  selectGroup={setSelectedGroup}
                  showSizeTitle={showSizeLabel}
                />
              </div>
            )}
          </>
        )}
        {product.description && (
          <div className="product-description">
            <div className="product-description-title">
              {getTranslation('productDetails', userLanguageCode)}
            </div>
            {product.description}
          </div>
        )}
        <div className="purchase-container">
          {totalSelectedItems > 0 && (
            <p className="total-price">
              <span>{getTranslation('total', userLanguageCode)}</span>
              <strong>{totalSelectedItemCost}</strong>
            </p>
          )}
          {totalSelectedItems == 0 && (
            <p className="total-price">
              <span>{getTranslation('price', userLanguageCode)}</span>
              <strong>
                {product.priceRangeToShow &&
                  product.priceRangeToShow.split('/')[0]}
              </strong>
            </p>
          )}
          {totalSelectedItems != 0 && (
            <p className="total-price">
              <span>{getTranslation('qty', userLanguageCode)}</span>
              <strong>{totalSelectedItems}</strong>
            </p>
          )}

          {/* // this is needed to be hidden as a hack for the iFrame stuff when on an iFrame product */}
          <IonButton
            disabled={!hasPendingItems && !isACartUpdate}
            ref={addToCartButton}
            onClick={addToCart}
            onTouchStart={addToCart}
            fill="solid"
            color="secondary"
            className={
              product.customizable ? 'hidden add-cart-btn' : 'add-cart-btn'
            }
          >
            {customizeButtonText(isACartUpdate, product, userLanguageCode)}
          </IonButton>
          {product.customizable && (
            <IonButton
              onClick={() => showCustomizer()}
              fill="solid"
              color="secondary"
              className="add-cart-btn"
            >
              <>
                {customizeButtonTextOther(isACartUpdate, userLanguageCode)}{' '}
                <IonIcon
                  color="medium"
                  slot="end"
                  icon={arrowForwardOutline}
                ></IonIcon>
              </>
            </IonButton>
          )}
        </div>
      </div>
      <IonModal isOpen={showIframe} ref={modal}>
        <IonHeader className="no-border-toolbar">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  modal.current?.dismiss()
                  setShowIframe(false)
                }}
              >
                <IonIcon
                  className="icon"
                  size="large"
                  icon={closeOutline}
                  color="primary"
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {showIframe && (
            <div className="iframe-wrapper" ref={viewRef} id="iframe-id">
              <iframe
                src={iframeUrl}
                onLoad={() => {
                  dismiss()
                }}
              ></iframe>
            </div>
          )}
        </IonContent>
      </IonModal>
    </>
  )
}

export default ProductDetailDisplay
