import {
  IonButton,
  IonButtons,
  IonInput,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { getTranslation } from 'translations'

interface Props {
  product: any
  pendingQuantities: any[]
  groups: string[]
  currentIndex: number
  updateStockQuantity: Function
  selectedGroup: string
  selectGroup: Function
  showSizeTitle: boolean
}

const ProductSizeMenu: React.FC<Props> = (props) => {
  const { userLanguageCode } = useSession()
  return (
    <>
      {props.groups.length > 1 && (
        <div className="group-menu">
          {props.showSizeTitle && (
            <div className="title" role="heading">
              {getTranslation('size', userLanguageCode)}
            </div>
          )}
          <IonButtons>
            {props.groups.map((group: any, index: any) => {
              return (
                <div key={index}>
                  {!group.hide && (
                    <IonButton
                      className="button-size"
                      onClick={() => props.selectGroup(group.name)}
                      color={
                        props.selectedGroup === group.name
                          ? 'secondary'
                          : 'primary'
                      }
                    >
                      {group.name}
                    </IonButton>
                  )}
                </div>
              )
            })}
          </IonButtons>
        </div>
      )}
      {props.groups.length == 0 && props.selectedGroup === '' && (
        <p className="loading-groups">Loading sizes...</p>
      )}
      <div className="sizing-menu">
        <div className="title" role="heading">
          {getTranslation('enterQuantities', userLanguageCode)}
        </div>
        {props.selectedGroup && (
          <IonList role="group">
            <IonItem lines="none" className="size-items-header">
              <IonLabel className="quantity-label" slot="start">
                {getTranslation('qty', userLanguageCode)}
              </IonLabel>
              <IonLabel slot="start">
                {getTranslation('size', userLanguageCode)}
              </IonLabel>
            </IonItem>

            {props.product.variations[props.currentIndex].stock_items.map(
              (size: any, index: number) => {
                const existingQuantity =
                  props.pendingQuantities[props.currentIndex].stock_items[index]

                return existingQuantity ? (
                  <div key={index}>
                    {(props.selectedGroup === 'ALL' ||
                      props.selectedGroup === size.name) && (
                      <IonItem lines="none" className="size-item">
                        <IonInput
                          disabled={existingQuantity.quantity_availible <= 0}
                          slot="start"
                          class="text-input"
                          inputmode="numeric"
                          data-testid={`${size.alt_name}-input`}
                          onIonInput={async (event: any) => {
                            props.updateStockQuantity(event, index)
                          }}
                          value={
                            existingQuantity.quantity === 0
                              ? ''
                              : existingQuantity.quantity
                          }
                        />
                        <div>
                          {props.selectedGroup === 'ALL'
                            ? size.name
                            : size.alt_name}
                        </div>
                      </IonItem>
                    )}
                  </div>
                ) : null
              }
            )}
          </IonList>
        )}

        {props.selectedGroup === '' && (
          <div className="select-group">
            <p>{getTranslation('chooseOption', userLanguageCode)}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default ProductSizeMenu
