import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Header from 'app/components/header/Header'
import SideMenu from 'app/components/header/components/side-menu/SideMenu'
import { PrivateRoute } from 'app/components/private-route/PrivateRoute'
import { CartProvider } from 'app/context/cart/CartContext'
import { ConfigProvider } from 'app/context/config/ConfigContext'
import { ConnectionProvider } from 'app/context/connection/ConnectionContext'
import { SessionProvider } from 'app/context/session/SessionContext'
import { ToastProvider } from 'app/context/toast/ToastContext'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Cart from './pages/cart/Cart'
import Explore from './pages/explore/Explore'
import ForgotPassword from './pages/forgot-password/ForgotPassword'
import Login from './pages/login/Login'
import NoAccess from './pages/no-access/NoAccess'
import OrderDetailPage from './pages/order-detail/OrderDetailPage'
import OrderListing from './pages/order-listing/OrderListing'
import ProductDetail from './pages/product-detail/ProductDetail'
import ProductListing from './pages/product-listing/ProductListing'
import Search from './pages/search/Search'
import Submitted from './pages/submitted/Submitted'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/ionic-swiper.css'
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
import '/node_modules/swiper/swiper.scss'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'

/* Project specific themes */
import '../theme/fonts.scss'
import '../theme/global.scss'
import '../theme/modal.scss'
import '../theme/variables.scss'

setupIonicReact({
  swipeBackEnabled: false
})

const queryClient = new QueryClient()

const EnterpriseApp: React.FC = () => {
  return (
    <IonApp>
      <ConnectionProvider>
        <SessionProvider>
          <ToastProvider>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider>
                <ReactQueryDevtools initialIsOpen={false} />
                <IonReactRouter>
                  <CartProvider>
                    <SideMenu />
                    <Header />

                    {/* use iOS mode since the animations match more closely to what was designed */}
                    <IonRouterOutlet id="main" mode={'ios'}>
                      <Route path="/" exact>
                        <Login />
                      </Route>
                      <Route path="/no-access" exact>
                        <NoAccess />
                      </Route>
                      <Route path="/login" exact>
                        <Login />
                      </Route>
                      <Route path="/forgot-password" exact>
                        <ForgotPassword />
                      </Route>
                      <PrivateRoute path="/explore" exact>
                        <Explore />
                      </PrivateRoute>
                      <PrivateRoute path="/search">
                        <Search />
                      </PrivateRoute>
                      <PrivateRoute path="/cart" exact>
                        <Cart />
                      </PrivateRoute>
                      <PrivateRoute path="/submitted/:id">
                        <Submitted />
                      </PrivateRoute>
                      <PrivateRoute path="/product-detail/:id">
                        <ProductDetail />
                      </PrivateRoute>

                      <PrivateRoute path="/edit-product/:id">
                        <ProductDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/explore/catalogs">
                        <ProductListing />
                      </PrivateRoute>
                      <PrivateRoute path="/manage/orders/order-detail/:id">
                        <OrderDetailPage />
                      </PrivateRoute>
                      <PrivateRoute path="/manage/orders" exact>
                        <OrderListing />
                      </PrivateRoute>
                      <PrivateRoute path="/manage" exact>
                        <OrderListing />
                      </PrivateRoute>
                      <Route>
                        <Redirect to="/explore" />
                      </Route>
                    </IonRouterOutlet>
                  </CartProvider>
                </IonReactRouter>
              </ConfigProvider>
            </QueryClientProvider>
          </ToastProvider>
        </SessionProvider>
      </ConnectionProvider>
    </IonApp>
  )
}

export default EnterpriseApp
