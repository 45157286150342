import React, { FC, ReactElement } from 'react'
import cn from 'classnames'

import './InputWrapper.scss'

const ErrorMessage: FC<{ message?: string }> = ({ message }) => {
  return (
    <div role="alert" className="error-text">
      {message}
    </div>
  )
}

type InputStructureProps = {
  labelMessage: string
  hasError: boolean
  errorMessage: string
  errorMessageHide?: boolean
  isRequired?: boolean
  children: ReactElement
}

const InputWrapper: FC<InputStructureProps> = ({
  labelMessage,
  hasError,
  errorMessage,
  isRequired = false,
  errorMessageHide = false,
  children
}) => {
  return (
    <div
      role="group"
      className={cn('input-wrapper ', {
        'input-wrapper--is-invalid': hasError
      })}
    >
      <LabelContainer message={labelMessage} required={isRequired} />
      {React.cloneElement(children, { 'aria-label': labelMessage })}
      {hasError && !errorMessageHide && <ErrorMessage message={errorMessage} />}
    </div>
  )
}

const LabelContainer: FC<{ message?: string; required?: boolean }> = ({
  message,
  required = false
}) => {
  return (
    <div className="label-container">
      {message}
      {required && <span className="required-label">Required</span>}
    </div>
  )
}

export default Object.assign(InputWrapper, {
  LabelContainer: LabelContainer,
  ErrorMessage: ErrorMessage
})
