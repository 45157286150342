import useBrandConfig from 'app/hooks/use-brand-config'
import type { BrandConfig } from 'models/Config'
import { createContext, useContext } from 'react'
import { defaultConfig } from 'services/auth'

const ConfigContext = createContext<{ data?: BrandConfig; isLoading: boolean }>(
  {
    data: defaultConfig,
    isLoading: false
  }
)

export default ConfigContext

type Props = {
  children: React.ReactNode
}

export const ConfigProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading } = useBrandConfig()
  return (
    <ConfigContext.Provider value={{ isLoading, data }}>
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => {
  const config = useContext(ConfigContext)

  return config
}
