import { IonBreadcrumb, IonBreadcrumbs } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'app/context/session/useSession'
import cn from 'classnames'
import { User } from 'models/User'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getProduct } from 'services/catalog'
import './Breadcrumbs.scss'

const mappedNames = [
  {
    routeName: 'product-listing',
    displayName: 'Catalogs'
  },
  {
    routeName: 'manage',
    displayName: 'Manage'
  },
  {
    routeName: 'catalogs',
    displayName: 'Catalogs'
  },
  {
    routeName: 'explore',
    displayName: 'Explore'
  },
  {
    routeName: 'brands',
    displayName: 'Brands'
  },
  {
    routeName: 'cart',
    displayName: 'Shopping Cart'
  },
  {
    routeName: 'orders',
    displayName: 'Orders'
  },
  {
    routeName: 'order-detail',
    displayName: 'Order Details'
  },
  {
    routeName: 'product-detail',
    displayName: 'Product'
  },
  {
    routeName: 'user-manager',
    displayName: 'Manager'
  }
]

async function getProductData(id: string) {
  const foundProduct: any = await getProduct(id)
  if (foundProduct.name) return foundProduct.name
  else return id
}

async function processBreadcrumb(
  splitString: string[],
  breadcrumb: string,
  index: number,
  notLink: boolean
): Promise<any> {
  let breadcrumbTitle = breadcrumb
  let hrefString = '/' + breadcrumb

  if (index > 0) {
    hrefString = '/' + splitString[index - 1] + '/' + breadcrumb
    breadcrumbTitle = await getProductData(breadcrumb)
  }

  mappedNames.forEach((mapping: any) => {
    if (breadcrumb === mapping.routeName) {
      breadcrumbTitle = mapping.displayName
    }
  })

  if (notLink) {
    return { title: breadcrumbTitle, href: '' }
  }

  return { href: hrefString, title: breadcrumbTitle }
}

async function generateBreadcrumbs({
  mockedLocation,
  skipLast,
  pageName,
  mockedBreadcrumbs,
  user
}: {
  mockedLocation?: string
  skipLast?: boolean
  pageName?: string
  mockedBreadcrumbs?: any[]
  user?: User
}) {
  const asPathWithoutQuery =
    (mockedLocation || '').length > 0
      ? (mockedLocation as String)
      : location.pathname.split('?')[0]
  const asPathNestedRoutes = asPathWithoutQuery
    .split('/')
    .filter((v) => v.length > 1)
  const breadcrumbs: any[] = []

  for (
    let index = 0;
    index < asPathNestedRoutes.length - (skipLast ? 1 : 0);
    index++
  ) {
    const breadcrumb = await processBreadcrumb(
      asPathNestedRoutes,
      asPathNestedRoutes[index],
      index,
      false
    )

    if (
      breadcrumb.title === 'Product' ||
      breadcrumb.title === 'Shopping Cart'
    ) {
      const catalogBreadcrumb = { href: '', title: '' }
      if (user?.customer?.name) {
        catalogBreadcrumb.title = user.customer.name
      }
      if ((user?.catalogs || []).length > 0) {
        const search = localStorage.getItem('search')
        catalogBreadcrumb.href = `/explore/catalogs${
          search || `?catalogId=${user?.catalogs?.[0].key}`
        }`
      }

      if (catalogBreadcrumb.href != '' && catalogBreadcrumb.title != '') {
        breadcrumbs.push(catalogBreadcrumb)
      }

      if (breadcrumb.title == 'Shopping Cart') {
        breadcrumb.href = ''
        breadcrumbs.push(breadcrumb)
      }
    } else if (breadcrumb.title == 'Order Details') {
      breadcrumb.href = ''
      breadcrumbs.push(breadcrumb)
    } else {
      breadcrumbs.push(breadcrumb)
    }
  }

  if (pageName) {
    breadcrumbs.push(
      await processBreadcrumb(
        asPathNestedRoutes,
        pageName,
        asPathNestedRoutes.length,
        true
      )
    )
  }

  return mockedBreadcrumbs || breadcrumbs
}
interface Props {
  pageName?: string
  skipLast?: boolean
  productStyle?: boolean
  mockedLocation?: string
  mockedBreadcrumbs?: Array<any>
}

const Breadcrumbs: React.FC<Props> = ({
  pageName,
  skipLast,
  productStyle,
  mockedLocation,
  mockedBreadcrumbs
}) => {
  const history = useHistory()
  const { getUserInfo } = useSession()
  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo()
  })

  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState<any[]>([])

  const handleBreadcrumbs = useCallback(async () => {
    const crumbs = await generateBreadcrumbs({
      mockedBreadcrumbs,
      skipLast,
      pageName,
      mockedLocation,
      user: data
    })
    setBreadcrumbRoutes(crumbs)
  }, [data, mockedBreadcrumbs, mockedLocation, pageName, skipLast])

  useEffect(() => {
    handleBreadcrumbs()
    return history.listen(() => {
      handleBreadcrumbs()
    })
  }, [history, handleBreadcrumbs])

  return (
    <div
      className={cn('breadcrumb-wrapper', {
        'product-style': productStyle
      })}
    >
      <IonBreadcrumbs>
        {breadcrumbRoutes.map((breadcrumb: any, index: number) => {
          return (
            <IonBreadcrumb
              onClick={() => {
                if (breadcrumb.href == null) {
                  history.goBack()
                }
              }}
              disabled={breadcrumb.href == ''}
              key={index}
              routerLink={breadcrumb.href ?? undefined}
              separator={breadcrumbRoutes.length - 1 != index}
            >
              <div
                className={`breadcrumb-text ${
                  breadcrumbRoutes.length - 1 === index ? 'last' : ''
                } ${skipLast ? '' : 'skipLast'}`}
              >
                {breadcrumb.title}
              </div>
            </IonBreadcrumb>
          )
        })}
      </IonBreadcrumbs>
    </div>
  )
}

export default Breadcrumbs
