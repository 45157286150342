import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  useIonLoading
} from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import EmptyList from 'app/components/empty-list/EmptyList'
import Breadcrumbs from 'app/components/header/breadcrumbs/Breadcrumbs'
import PWAModal from 'app/components/modal-layout/PWAModal'
import SummaryItem from 'app/components/summary-item/SummaryItem'
import { useCart } from 'app/context/cart/useCart'
import { useConfig } from 'app/context/config/ConfigContext'
import { useConnection } from 'app/context/connection/useConnection'
import { useSession } from 'app/context/session/useSession'
import {
  alertCircleOutline,
  cartOutline,
  chevronDownOutline
} from 'ionicons/icons'
import { cloneDeep, isEqual } from 'lodash'
import {
  CartItem,
  CartItemVariation,
  DropShipAddress,
  DropShipFieldErrors,
  DropShipFieldMaxLengths,
  DropShipFieldRegExps,
  SalesProgram,
  UserLocation
} from 'models'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import {
  getSalesPrograms,
  getTotalOrderPrice,
  validateAddress
} from 'services/order'
import { getTranslation } from 'translations'
import './CartDetails.scss'
import DropShipModal from './drop-ship-modal/DropShipModal'
import OrderConfirmModal from './order-confrm/OrderConfirmModal'
import RemoveProductModal from './remove-product-modal/RemoveProductModal'
import ShipToModal from './ship-to-modal/ShipToModal'

const CartDetails: React.FC = () => {
  const { data: brandConfig } = useConfig()

  const [showError, setShowError] = useState<boolean>(false)
  const {
    createOrder,
    totalCartItems,
    currentOrder,
    cartItems,
    changeDropShipAddress,
    dropShipAddress,
    updateRemainingCredits,
    showModifiedError
  } = useCart()
  const [hideItemNumber, setHideItemNumber] = useState<boolean>(false)
  const [availableLocations, setAvailableLocations] = useState<UserLocation[]>(
    []
  )
  const [activeSalesProgram, setActiveSalesProgram] =
    useState<SalesProgram | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [selectedLocationName, setSelectedLocationName] = useState<string>('')
  const [selectedLocationDisplay, setSelectedLocationDisplay] =
    useState<any>(null)
  const [totalPrice, setTotalPrice] = useState<number>(0.0)
  const [maxOrderAmount, setMaxOrderAmount] = useState<number>(0.0)
  const [minOrderAmount, setMinOrderAmount] = useState<number>(0.0)
  const [totalDiscount, setTotalDiscount] = useState<number>(0.0)
  const [totalAmountDue, setTotalAmountDue] = useState<number>(0.0)
  const [productToDelete, setProductToDelete] = useState<any>(null)
  const [shippingAddressOpen, setShippingAddressOpen] = useState<boolean>(false)
  const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false)
  const [dropShipOpen, setDropShipOpen] = useState<boolean>(false)
  const [dropShipOnly, setDropShipOnly] = useState<boolean>(false)
  const [dropShipAddressError, setDropShipAddressError] =
    useState<boolean>(false)
  const [smartStreetError, setSmartStreetError] = useState(false)
  const [dropshipAllowed, setDropShipAllowed] = useState<boolean>(false)
  const [dropShipEmailField, setDropShipEmailField] = useState<boolean>(false)
  const [dropShipPhoneField, setDropShipPhoneField] = useState<boolean>(false)
  const [orderSaving, setOrderSaving] = useState<boolean>(false)
  const [dropShipFieldMaxLengths, setDropShipFieldMaxLengths] =
    useState<DropShipFieldMaxLengths>({
      address1MaxLength: 1000,
      address2MaxLength: 1000,
      cityMaxLength: 1000,
      nameMaxLength: 1000,
      stateMaxLength: 1000,
      zipMaxLength: 1000,
      emailMaxLength: 1000,
      phoneMaxLength: 1000
    })

  const [dropShipFieldRegExps, setDropShipFieldRegExps] =
    useState<DropShipFieldRegExps>({
      nameRegExp: undefined,
      address1RegExp: undefined,
      address2RegExp: undefined,
      cityRegExp: undefined,
      stateRegExp: undefined,
      zipRegExp: undefined,
      phoneRegExp: undefined,
      emailRegExp: undefined
    })

  const [dropShipFieldErrors, setDropShipFieldErrors] =
    useState<DropShipFieldErrors>({
      address1: false,
      address2: false,
      city: false,
      name: false,
      state: false,
      zip: false,
      email: false,
      phone: false
    })

  const [newDropShipAddress, setNewDropShipAddress] =
    useState<DropShipAddress>(dropShipAddress)
  const [previousDropShipAddress, setPreviousDropShipAddress] =
    useState<DropShipAddress>(dropShipAddress)
  const { getUserInfo, userCatalogId, userLanguageCode } = useSession()
  const { data: userData } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo(true)
  })
  const [present, dismiss] = useIonLoading()
  const history = useHistory()
  const { connected } = useConnection()

  const removeModal = useRef<HTMLIonModalElement>(null)
  const addressModal = useRef<HTMLIonModalElement>(null)
  const dropShipModal = useRef<HTMLIonModalElement>(null)
  const orderConfirmModal = useRef<HTMLIonModalElement>(null)
  const dropShipError = useRef<HTMLParagraphElement>(null)

  const handleBrandConfig = useCallback(() => {
    if (brandConfig?.brandDynamic?.config?.flags) {
      if (brandConfig.brandDynamic.config.flags.dropShip) {
        if (brandConfig.brandDynamic.config.flags.dropShip.alwaysEnabled) {
          setDropShipAllowed(true)
        }

        if (brandConfig.brandDynamic.config.flags.dropShip.additionalFields) {
          brandConfig.brandDynamic.config.flags.dropShip.additionalFields.forEach(
            (field: any) => {
              if (field.name == 'email') {
                setDropShipEmailField(true)
              }

              if (field.name == 'phone') {
                setDropShipPhoneField(true)
              }
            }
          )
        }

        if (brandConfig.brandDynamic.config.flags.dropShip.fieldMaxLengths) {
          const newMaxLengths: DropShipFieldMaxLengths =
            brandConfig.brandDynamic.config.flags.dropShip.fieldMaxLengths
          setDropShipFieldMaxLengths(newMaxLengths)
        }

        if (brandConfig.brandDynamic.config.flags.dropShip.fieldRegExps) {
          const newRegExps: DropShipFieldRegExps =
            brandConfig.brandDynamic.config.flags.dropShip.fieldRegExps
          setDropShipFieldRegExps(newRegExps)
        }
      }

      if (brandConfig.brandDynamic.config.flags.hideProductDataInUI) {
        setHideItemNumber(
          brandConfig.brandDynamic.config.flags.hideProductDataInUI
            .product_number
        )
      }
    }
  }, [brandConfig])

  const handleUserData = useCallback(() => {
    if (userData && !selectedLocationDisplay) {
      setAvailableLocations(userData.customer.locations)
      setMinMaxOrderAmount(
        userData?.catalogs?.[0],
        userData.currency_code ?? ''
      )
      if (
        userData.customer.client_fields.drop_ship == 'Y' ||
        userData.customer.client_fields.drop_ship == 'M'
      ) {
        setSelectedLocation('drop_ship')
        setSelectedLocationDisplay(null)
        setDropShipAllowed(true)
      } else if (dropShipAddress && dropShipAddress.inUse) {
        setSelectedLocation('drop_ship')
        setSelectedLocationDisplay(null)
        setDropShipOnly(true)
      } else {
        setSelectedLocation(
          userData?.customer?.locations.length === 0
            ? 'drop_ship'
            : userData.customer.locations[0].number
        )
      }
    }
  }, [userData, dropShipAddress, selectedLocationDisplay])

  useEffect(() => {
    if (brandConfig) {
      handleUserData()
      handleBrandConfig()
    }
  }, [brandConfig, handleBrandConfig, handleUserData])

  useEffect(() => {
    async function getLocationDisplayText() {
      if (newDropShipAddress && newDropShipAddress.inUse) {
        setSelectedLocationDisplay(newDropShipAddress)
        setSelectedLocationName(newDropShipAddress.name)
      } else {
        if (userData?.customer?.locations) {
          userData.customer.locations.forEach((location: any) => {
            if (location.number == selectedLocation) {
              setSelectedLocationDisplay(location.address)
              setSelectedLocationName(location.name)
            }
          })
        }
      }
    }

    getLocationDisplayText()
  }, [newDropShipAddress, selectedLocation, userData])

  useEffect(() => {
    setPrices(cartItems)

    // if this order already has a drop ship address set, set it in the view
    if (currentOrder) {
      if (currentOrder.pages[0]) {
        if (
          currentOrder.pages[0].drop_ship_address &&
          currentOrder.pages[0].drop_ship_address.address1
        ) {
          const existingDropShipAddress = cloneDeep(
            currentOrder.pages[0].drop_ship_address
          )
          existingDropShipAddress.inUse = true
          setNewDropShipAddress(existingDropShipAddress)
        }
      }
    }
  }, [cartItems, activeSalesProgram])

  useEffect(() => {
    changeDropShipAddress(newDropShipAddress)

    if (newDropShipAddress.inUse) {
      setSelectedLocation('drop_ship')
      setSelectedLocationDisplay(newDropShipAddress)
      setSelectedLocationName(newDropShipAddress.name)
    }
  }, [newDropShipAddress])

  useEffect(() => {
    if (!activeSalesProgram) {
      loadSalesPrograms()
    }
  }, [currentOrder])

  async function loadSalesPrograms() {
    if (currentOrder && currentOrder._id) {
      const salesPrograms = await getSalesPrograms(currentOrder._id, connected)
      const active = (salesPrograms?.salesProgramsDynamic?.achieved_programs ||
        [])[0]
      const program = salesPrograms?.salesProgramsDynamic?.programs.find(
        (program: any) => program.id === active.id
      )
      if (active) {
        setActiveSalesProgram({
          ...program,
          amount: parseFloat(active.discounts[0].discount)
        })
      }
    }
  }

  function setMinMaxOrderAmount(catalog: any, currency: string) {
    const minValue = catalog.min_order_amount ?? 0
    const maxValue = catalog.max_order_amount ?? 1000000000
    const currencySpecificVMaxs = catalog.max_order_amount_for_currency
      ? JSON.parse(catalog.max_order_amount_for_currency)
      : {}
    const currencySpecificVMins = catalog.min_order_amount_for_currency
      ? JSON.parse(catalog.min_order_amount_for_currency)
      : {}
    if (currencySpecificVMaxs[currency]) {
      setMaxOrderAmount(currencySpecificVMaxs[currency])
    } else {
      setMaxOrderAmount(maxValue)
    }

    if (currencySpecificVMins[currency]) {
      setMinOrderAmount(currencySpecificVMins[currency])
    } else {
      setMinOrderAmount(minValue)
    }
  }

  async function saveCurrentOrder() {
    if (selectedLocationDisplay != null) {
      setOrderSaving(true)
      if (cartItems.length > 0) {
        await present({ message: 'Saving order...' })
      }
      const result = await createOrder(false, false)

      if (!result) {
        alert('Something went wrong, please try again.')
        return ''
      }
      await dismiss()

      setOrderSaving(false)

      history.replace('/explore')

      return result
    } else {
      alert('Please set a drop shipment address.')
    }
  }

  async function setPrices(theItems: CartItem[]) {
    const theTotalPrice = await getTotalOrderPrice(theItems)
    setTotalPrice(theTotalPrice)

    if (activeSalesProgram) {
      const discountAllowed = parseFloat(activeSalesProgram.amount.toFixed(2))
      const totalDiscountRemaining = activeSalesProgram.amount - theTotalPrice
      updateRemainingCredits(totalDiscountRemaining)
      let totalAmountDiscounted = 0
      if (totalDiscountRemaining > 0) {
        totalAmountDiscounted = theTotalPrice
        setTotalAmountDue(0.0)
      } else {
        totalAmountDiscounted = discountAllowed
        setTotalAmountDue(theTotalPrice - discountAllowed)
      }
      setTotalDiscount(totalAmountDiscounted)
    } else {
      setTotalAmountDue(theTotalPrice)
    }
  }

  function changeAddress(locationNumber: string) {
    setNewDropShipAddress((oldAddress) => ({
      ...oldAddress,
      inUse: false
    }))

    if (locationNumber != 'drop_ship') {
      setSelectedLocation(locationNumber)
    } else {
      setShippingAddressOpen(false)

      setDropShipOpen(true)
    }
  }

  async function doOrderSubmit() {
    if (selectedLocationDisplay != null) {
      setOrderSaving(true)
      const orderNum = await createOrder(false, true)

      // orderNum will be false if an error was thrown from skillet
      // in this case, the user has tried to submit multiple orders and skillet only allows them to submit one order
      if (!orderNum) {
        setShowError(true)
      }
      if (orderNum) {
        history.push('/submitted/' + orderNum)
      }

      setOrderSaving(false)
    } else {
      alert('Please set a drop shipment address.')
    }
  }

  async function doChangeDropShipAddress(data: any) {
    setPreviousDropShipAddress(data)

    const compare = isEqual(data, previousDropShipAddress)

    let validAddress: boolean
    if (!smartStreetError) {
      validAddress = await validateAddress(data)
    } else {
      validAddress = !compare ? await validateAddress(data) : true
    }

    setSmartStreetError(!validAddress)

    if (validAddress) {
      setDropShipAddressError(false)
      setDropShipOpen(false)
      setNewDropShipAddress((oldAddress) => ({
        ...oldAddress,
        ...data,
        inUse: true
      }))
    } else {
      setDropShipAddressError(true)
      if (!validAddress) {
        setDropShipFieldErrors((oldValues) => ({
          ...oldValues,
          address1: true,
          address2: true,
          city: true,
          zip: true,
          state: true
        }))
      }
      dropShipModal.current?.scrollIntoView({ block: 'nearest' })
    }
  }

  return (
    <>
      {cartItems.length === 0 ? (
        <IonContent>
          {showModifiedError && (
            <div className="modified-error empty">
              <IonIcon
                className="icon"
                icon={alertCircleOutline}
                color="danger"
              />
              <p>{getTranslation('cartItemsModified', userLanguageCode)}</p>
            </div>
          )}
          <EmptyList
            icon={cartOutline}
            title="Empty Cart"
            firstLineSubext={'No item in your cart.'}
            secondLineSubtext="Items added to cart will appear here."
            buttonText="Start Shopping"
            buttonLink={'/explore/catalogs?catalogId=' + userCatalogId}
          />
        </IonContent>
      ) : (
        <IonContent className={activeSalesProgram ? 'has-bar' : ''}>
          <div className="wrapper">
            <Breadcrumbs />
            <h1>{getTranslation('shoppingCart', userLanguageCode)}</h1>

            <>
              <div className="custom-select">
                <IonLabel>
                  {getTranslation('shipTo', userLanguageCode)}
                </IonLabel>
                <div
                  onClick={() => {
                    availableLocations.length === 0 ||
                    userData?.customer.client_fields.drop_ship == 'Y' ||
                    userData?.customer.client_fields.drop_ship == 'M'
                      ? setDropShipOpen(true)
                      : setShippingAddressOpen(true)
                  }}
                  className="fake-select"
                >
                  {selectedLocation == 'drop_ship' ? (
                    <> {getTranslation('shippingAddress', userLanguageCode)} </>
                  ) : (
                    <>
                      {' '}
                      {getTranslation('pickUp', userLanguageCode)}{' '}
                      {selectedLocationName}{' '}
                    </>
                  )}
                  <IonIcon
                    className="icon"
                    icon={chevronDownOutline}
                    color="primary"
                  />
                </div>
              </div>
              {selectedLocationDisplay && (
                <div className="address-seperator">
                  <div className="selected-address">
                    <h3>
                      {getTranslation('shipmentAddress', userLanguageCode)}
                    </h3>
                    <p>
                      {selectedLocationDisplay.name && (
                        <>
                          {selectedLocationDisplay.name} <br />
                        </>
                      )}
                      {selectedLocationDisplay.address1
                        ? selectedLocationDisplay.address1
                        : selectedLocationDisplay.street1}{' '}
                      <br />
                      {(selectedLocationDisplay.street2 ||
                        selectedLocationDisplay.address2) && (
                        <>
                          {selectedLocationDisplay.address2
                            ? selectedLocationDisplay.address2
                            : selectedLocationDisplay.street2}{' '}
                          <br />
                        </>
                      )}
                      {selectedLocationDisplay.city},{' '}
                      {selectedLocationDisplay.state}{' '}
                      {selectedLocationDisplay.zip}
                    </p>
                  </div>
                </div>
              )}
            </>

            {showModifiedError && (
              <div className="modified-error">
                <IonIcon
                  className="icon"
                  icon={alertCircleOutline}
                  color="danger"
                />
                <p>{getTranslation('cartItemsModified', userLanguageCode)}</p>
              </div>
            )}
            {totalPrice > maxOrderAmount && (
              <div className="modified-error">
                <IonIcon
                  className="icon"
                  icon={alertCircleOutline}
                  color="danger"
                />
                <p>
                  {getTranslation('cartOrderAmountTooLarge', userLanguageCode)
                    .replace('{amount}', maxOrderAmount.toFixed(2))
                    .replace(
                      '{excess}',
                      (totalPrice - maxOrderAmount).toFixed(2)
                    )}
                </p>
              </div>
            )}
            {totalPrice < minOrderAmount && (
              <div className="modified-error">
                <IonIcon
                  className="icon"
                  icon={alertCircleOutline}
                  color="danger"
                />
                <p>
                  {getTranslation(
                    'cartOrderAmountTooSmall',
                    userLanguageCode
                  ).replace('{amount}', minOrderAmount)}
                </p>
              </div>
            )}
            <h2>{getTranslation('cartSummary', userLanguageCode)}</h2>
            <p className="total-items">
              {totalCartItems} {getTranslation('items', userLanguageCode)}
            </p>

            {cartItems.map((product: CartItem, index: number) => {
              let totalQuantity = 0
              product.page_items.forEach((variation: CartItemVariation) => {
                totalQuantity += variation.quantity
              })

              return (
                <SummaryItem
                  key={index}
                  product={product}
                  totalQuantity={totalQuantity}
                  isEditable={true}
                  setProductToDelete={setProductToDelete}
                  showItemNumber={!hideItemNumber}
                />
              )
            })}

            <div className="save-for-later">
              <IonButton
                disabled={orderSaving}
                color={'secondary'}
                onClick={async () => {
                  await saveCurrentOrder()
                }}
              >
                {getTranslation('saveForLater', userLanguageCode)}
              </IonButton>
              <IonButton
                disabled={orderSaving}
                color={'secondary'}
                routerLink={'/explore/catalogs?catalogId=' + userCatalogId}
              >
                {getTranslation('continueShopping', userLanguageCode)}
              </IonButton>
            </div>
          </div>
          <div className="cart-cost-details">
            <h2>
              {getTranslation('priceDetails', userLanguageCode)} (
              {totalCartItems} {getTranslation('itemsUpper', userLanguageCode)})
            </h2>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <p>{getTranslation('totalPrice', userLanguageCode)}</p>
                </IonCol>
                <IonCol>
                  <p>${totalPrice.toFixed(2)}</p>
                </IonCol>
              </IonRow>
              {totalDiscount != null && (
                <IonRow className="with-border">
                  <IonCol>
                    <p>{getTranslation('discount', userLanguageCode)}</p>
                  </IonCol>
                  <IonCol>
                    <p>-${totalDiscount.toFixed(2)}</p>
                  </IonCol>
                </IonRow>
              )}
              {totalAmountDue != null && (
                <IonRow className="amount-due">
                  <IonCol>
                    <p>{getTranslation('totalAmountDue', userLanguageCode)}</p>
                  </IonCol>
                  <IonCol>
                    <p>${totalAmountDue.toFixed(2)}</p>
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>
          </div>
          {currentOrder && (
            <div className="place-order">
              <IonButton
                disabled={
                  orderSaving ||
                  totalPrice > maxOrderAmount ||
                  totalPrice < minOrderAmount
                }
                color={'secondary'}
                onClick={async () => setOrderModalOpen(true)}
              >
                {getTranslation('placeOrder', userLanguageCode)}
              </IonButton>
            </div>
          )}
          <RemoveProductModal
            modalRef={removeModal}
            productToDelete={productToDelete}
            setProductToDelete={setProductToDelete}
          />
          <ShipToModal
            dropShipOnly={dropShipOnly}
            modalRef={addressModal}
            setShippingAddressOpen={setShippingAddressOpen}
            shippingAddressOpen={shippingAddressOpen}
            availableLocations={availableLocations}
            changeAddress={changeAddress}
            selectedLocation={selectedLocation}
            dropshipAllowed={dropshipAllowed}
          />
          <DropShipModal
            dropShipOpen={dropShipOpen}
            setDropShipOpen={setDropShipOpen}
            dropShipErrorRef={dropShipError}
            dropShipAddressError={dropShipAddressError}
            dropShipFieldErrors={dropShipFieldErrors}
            dropShipFieldRegExps={dropShipFieldRegExps}
            dropShipEmailField={dropShipEmailField}
            dropShipPhoneField={dropShipPhoneField}
            dropShipFieldMaxLengths={dropShipFieldMaxLengths}
            dropshipAllowed={dropshipAllowed}
            doChangeDropShipAddress={doChangeDropShipAddress}
            newDropShipAddress={newDropShipAddress}
          />
          <OrderConfirmModal
            setOrderConfirmOpen={setOrderModalOpen}
            modalRef={orderConfirmModal}
            modalOpen={orderModalOpen}
            handleOrderSubmit={doOrderSubmit}
          />
        </IonContent>
      )}
      <ShipToModal
        dropShipOnly={dropShipOnly}
        modalRef={addressModal}
        setShippingAddressOpen={setShippingAddressOpen}
        shippingAddressOpen={shippingAddressOpen}
        changeAddress={changeAddress}
        selectedLocation={selectedLocation}
        dropshipAllowed={dropshipAllowed}
        availableLocations={availableLocations}
      />
      <DropShipModal
        dropShipOpen={dropShipOpen}
        setDropShipOpen={setDropShipOpen}
        dropShipErrorRef={dropShipError}
        dropShipAddressError={dropShipAddressError}
        dropShipFieldErrors={dropShipFieldErrors}
        dropShipFieldRegExps={dropShipFieldRegExps}
        dropShipEmailField={dropShipEmailField}
        dropShipPhoneField={dropShipPhoneField}
        dropShipFieldMaxLengths={dropShipFieldMaxLengths}
        dropshipAllowed={dropshipAllowed}
        doChangeDropShipAddress={doChangeDropShipAddress}
        newDropShipAddress={newDropShipAddress}
      />
      <PWAModal
        isOpen={showError}
        header={<h2>{getTranslation('cannotPlaceOrder', userLanguageCode)}</h2>}
        toggle={() => setShowError(false)}
        size="small"
        footer={
          <IonButton shape="round" onClick={() => setShowError(false)}>
            {getTranslation('close', userLanguageCode)}
          </IonButton>
        }
      >
        <p>{getTranslation('cannotPlaceOrderDescription', userLanguageCode)}</p>
      </PWAModal>
    </>
  )
}

export default CartDetails
