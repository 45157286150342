import React, { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import constants from '../../../constants'
import { User } from '../../../models/User'
import {
  authUser,
  cognitoLogout,
  getJwt,
  getSession,
  refreshSession
} from '../../../services/auth'
import {
  clearStorage,
  getStoredKey,
  setStoredKey
} from '../../../services/storage'
import { getUser } from '../../../services/user'

export const SessionContext = createContext<{
  error: string | undefined
  isAuthenticated: boolean
  authValidated: boolean
  userCatalogId: string
  userLanguageCode: string
  userInfo?: User
  getUserInfo: (userFreshData?: boolean) => Promise<User | undefined>
  login: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<boolean>
  logout: () => Promise<void>
}>({
  error: undefined,
  isAuthenticated: false,
  authValidated: false,
  userCatalogId: '',
  userLanguageCode: '',
  userInfo: undefined,
  getUserInfo: async () => {
    throw new Error('Method not implemented')
  },
  login: async () => {
    throw new Error('Method not implemented')
  },
  logout: async () => {
    throw new Error('Method not implemented')
  }
})

interface SessionProps {
  children: any
  isTesting?: boolean
}

export const SessionProvider: React.FC<SessionProps> = ({
  isTesting,
  children
}) => {
  const [error, setError] = useState<string | undefined>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [authValidated, setAuthValidated] = useState<boolean>(false)
  const [userCatalogId, setUserCatalogId] = useState<string>('')
  const [userLanguageCode, setUserLanguageCode] = useState<string>('en')
  const [userInfo, setUserInfo] = useState<User | undefined>()
  const history = useHistory()

  useEffect(() => {
    if (!isTesting) {
      checkAuthenticationStatus()
    } else {
      setIsAuthenticated(true)
      setAuthValidated(true)
    }
  }, [])

  const checkAuthenticationStatus = async () => {
    try {
      let session = await getSession()

      if (session && session?.isValid()) {
        setIsAuthenticated(true)
        setError('')
      } else {
        await refreshSession()
        session = await getSession()

        if (session && session?.isValid()) {
          setIsAuthenticated(true)
          setError('')
        }
      }

      setAuthValidated(true)
    } catch (catchError: any) {
      setAuthValidated(true)
      setIsAuthenticated(true)
      setError('')
    }
  }

  const getUserInfo = async (
    userFreshData = true
  ): Promise<User | undefined> => {
    const jwt = await getJwt()
    const userDetails = userFreshData
      ? await getUser(jwt, true)
      : await getStoredKey(constants.USER_INFO)
    setUserInfo(userDetails?.validateDynamic?.user)
    if (!userDetails) return

    if (userDetails?.validateDynamic?.user?.customer?.b2c === false) {
      history.push('/no-access')
    }

    if (userDetails?.validateDynamic?.user?.catalogs[0]) {
      setUserCatalogId(userDetails.validateDynamic.user.catalogs[0].key)
    }

    return userDetails.validateDynamic.user
  }

  const login = async (
    username: string,
    password: string,
    remember: boolean
  ): Promise<boolean> => {
    try {
      const authenticationData = {
        username,
        password
      }
      const attempt = await authUser(authenticationData, remember, true)
      if (attempt) {
        setUserLanguageCode(await getStoredKey(constants.USER_LANGUAGE_CODE))
        setError('')
        setIsAuthenticated(true)
        return true
      } else {
        return false
      }
    } catch (catchError: any) {
      setIsAuthenticated(false)
      setError(catchError.toString())

      return false
    }
  }

  const logout = async (): Promise<void> => {
    try {
      await cognitoLogout()

      const savedUsername = await getStoredKey(constants.STORED_USERNAME)
      const savedPassword = await getStoredKey(constants.STORED_PASSWORD)

      await clearStorage()

      if (savedUsername && savedPassword) {
        await setStoredKey(constants.STORED_PASSWORD, savedPassword)
        await setStoredKey(constants.STORED_USERNAME, savedUsername)
      }
      setError('')
      setIsAuthenticated(false)
    } catch (catchError: any) {
      setError(catchError.toString())
    }
  }

  return (
    <SessionContext.Provider
      value={{
        error,
        isAuthenticated,
        authValidated,
        userCatalogId,
        userLanguageCode,
        getUserInfo,
        userInfo,
        login,
        logout
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}
