import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonText
} from '@ionic/react'
import { useConfig } from 'app/context/config/ConfigContext'
import './ProductItem.scss'

interface ProductItemProps {
  index: number
  imageToShow: string
  name: string
  number: string
  priceRangeToShow: string
  customizable: any
  variations: any[]
  showItemNumber: boolean
  onClick: () => void
}

const ProductItem: React.FC<ProductItemProps> = (props) => {
  const { data: brandConfig } = useConfig()
  function productDetailsText() {
    if (props.customizable) {
      return ' - Customizable'
    }

    if (props.variations.length > 1) {
      return (
        props.variations.filter((item) => {
          return !item.filtered
        }).length + ' Colors'
      )
    }

    return ''
  }

  return (
    <IonCol
      size="6"
      style={{ '--ion-grid-column-padding': '0' }}
      className={
        props.index % 2 === 0 ? 'ion-padding-end' : 'ion-padding-start'
      }
    >
      <IonCard
        className={'product'}
        routerLink={`/product-detail/${props.number}`}
        onClick={props.onClick}
      >
        <IonCardHeader>
          <img
            src={
              props.imageToShow ||
              brandConfig?.brandDynamic?.config?.flags?.missingImage ||
              '/assets/blank.png'
            }
            className="product-image"
          />
        </IonCardHeader>
        <IonCardContent className="ion-padding">
          <h3>
            {props.name} <br />
          </h3>
          {props.showItemNumber && (
            <IonText color="medium">
              {props.number} <br />
            </IonText>
          )}
          <IonText color="medium">
            {props.priceRangeToShow} <br />
          </IonText>
          {productDetailsText().length > 0 && (
            <IonText color="medium">{productDetailsText()}</IonText>
          )}
        </IonCardContent>
      </IonCard>
    </IonCol>
  )
}

export default ProductItem
