import { IonPage } from '@ionic/react'
import CartDetails from './components/CartDetails'

const Cart: React.FC = () => {
  return (
    <IonPage className="page-with-menu cart-page">
      <CartDetails />
    </IonPage>
  )
}

export default Cart
