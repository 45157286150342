import { IonButton, IonGrid, IonIcon, IonItem } from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { arrowForwardOutline } from 'ionicons/icons'
import { getTranslation } from 'translations'
import ProductCustomTable from '../product-custom-table/ProductCustomTable'

interface ProductCustomizeMenuProps {
  showCustomizer: Function
  embellishments: any[]
}

const ProductCustomizeMenu: React.FC<ProductCustomizeMenuProps> = (props) => {
  const { userLanguageCode } = useSession()

  return (
    <IonItem lines="none">
      <div className="customize-menu">
        <div className="icon-container">
          <IonIcon
            className="icon"
            size="large"
            icon={'assets/wrench.svg'}
            color="dark"
          ></IonIcon>
        </div>
        <div className="text-description">
          <h2 className="text-title">
            {props.embellishments.length == 0
              ? getTranslation('customizeYourClub', userLanguageCode)
              : getTranslation('clubCustomizedInCart', userLanguageCode)}{' '}
          </h2>
          {props.embellishments.length == 0 &&
            getTranslation('clubCustomizeInterested', userLanguageCode)}
        </div>
        {props.embellishments.length > 0 && (
          <div className="embellishment-display">
            <IonGrid style={{ '--ion-grid-padding': 0 }}>
              <ProductCustomTable embellishments={props.embellishments} />
            </IonGrid>
          </div>
        )}
        <IonButton
          fill="solid"
          color="dark"
          className="add-cart-btn"
          onClick={() => props.showCustomizer()}
        >
          {props.embellishments.length == 0
            ? getTranslation('customizeClub', userLanguageCode)
            : getTranslation('editCustomization', userLanguageCode)}{' '}
          <IonIcon
            color="secondary"
            slot="end"
            icon={arrowForwardOutline}
          ></IonIcon>
        </IonButton>
      </div>
    </IonItem>
  )
}

export default ProductCustomizeMenu
