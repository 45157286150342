import { IonInput, IonLabel } from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { ComponentProps, FC, forwardRef } from 'react'
import {
  Control,
  FieldName,
  UseFormRegisterReturn,
  useController
} from 'react-hook-form'
import { getTranslation } from 'translations'
import './Input.scss'

type IonInputProps = ComponentProps<typeof IonInput>

export interface InputProps extends IonInputProps {
  register?: UseFormRegisterReturn
  errorMessage?: string
  className?: string
  'data-testid'?: string
  control?: Control<any>
  name?: FieldName<any>
}

const Input: FC<InputProps> = forwardRef<HTMLIonInputElement, InputProps>(
  function Input({ name = '', control, label, type, ...rest }, ref) {
    const { userLanguageCode } = useSession()
    const { field } = useController({ control, name })
    return (
      <div className="text-input">
        <div className="label-container">
          <IonLabel class="input-label" position="floating">
            {label}
          </IonLabel>
          <IonLabel class="input-label required-label" position="floating">
            {getTranslation('required', userLanguageCode)}
          </IonLabel>
        </div>
        <IonInput
          ref={ref}
          aria-label={label ?? ''}
          fill="outline"
          type={type}
          onIonInput={(event) => field.onChange(event.target.value)}
          {...rest}
        />
      </div>
    )
  }
)

export default Input
