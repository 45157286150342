import { generateTagQueryParam } from 'services/catalog'

function generatePreSearch(product_numbers: string[]) {
  let queryString = ''
  if (product_numbers) {
    for (let index = 0; index < product_numbers.length; index++) {
      if (index == 0) {
        queryString += '&query='
      } else {
        queryString += ','
      }

      queryString += product_numbers[index]
    }
  }

  return queryString
}

function getCallToActionDetail(cta: any) {
  let text = ''
  let link = ''
  let external = false

  if (cta.on_click_full && cta.on_click_full.catalog_key) {
    text = cta.display_text
    link =
      '/explore/catalogs?catalogId=' +
      cta.on_click_full.catalog_key +
      generateTagQueryParam(cta.on_click_full.tags) +
      generatePreSearch(cta.on_click_full.product_numbers)
  } else if (cta.on_click_string_only) {
    text = cta.display_text
    link = cta.on_click_string_only
    external = true
  }

  return { text, link, external }
}

export function getExtraSlideDetails(slide: any): any {
  let slideHasCopy = false
  if (slide.headline || slide.sub_headline) {
    slideHasCopy = true
  }

  // image link
  if (slide.product_filter_set && slide.product_filter_set.catalog_key) {
    slideHasCopy = true
    slide.imageLink =
      '/explore/catalogs?catalogId=' + slide.product_filter_set.catalog_key
  } else if (slide.external_url) {
    slide.imageLink = slide.external_url
    slideHasCopy = true
  }

  // main CTA
  if (slide.call_to_actions && slide.call_to_actions[0]) {
    const mainLinkAndText = getCallToActionDetail(slide.call_to_actions[0])

    slide.mainCTAText = mainLinkAndText.text
    slide.mainCTALink = mainLinkAndText.link
    slide.mainCTAExternal = mainLinkAndText.external
    slideHasCopy = true
  }

  // secondary CTA
  if (slide.call_to_actions && slide.call_to_actions[1]) {
    const secondaryLinkAndText = getCallToActionDetail(slide.call_to_actions[1])

    slide.secondaryCTAText = secondaryLinkAndText.text
    slide.secondaryCTALink = secondaryLinkAndText.link
    slide.secondaryCTAExternal = secondaryLinkAndText.external

    slideHasCopy = true
  }

  slide.hasCopy = slideHasCopy
  return slide
}
