import { useCallback, useEffect, useState } from 'react'
import './SliderTimer.scss'

interface Props {
  slideIndex: number
  timeBetweenSlides: number
}

const ExploreSliderTimer: React.FC<Props> = ({
  timeBetweenSlides,
  slideIndex
}) => {
  const [slideTimerWidth, setSlideTimerWidth] = useState(0)
  const [slideTimerValue, setSliderTimerValue] = useState(0)
  const timeInterval = 50

  const calculateTimerWidth = useCallback(() => {
    const value = (slideTimerValue / timeBetweenSlides) * 100
    return value < 100 ? value : 100
  }, [timeBetweenSlides, slideTimerValue])

  useEffect(() => {
    const timer = setTimeout(() => {
      setSliderTimerValue(slideTimerValue + timeInterval)
      setSlideTimerWidth(calculateTimerWidth())
    }, timeInterval)

    return () => clearTimeout(timer)
  }, [calculateTimerWidth, slideTimerValue])

  useEffect(() => {
    setSlideTimerWidth(0)
    setSliderTimerValue(0)
  }, [slideIndex])

  return (
    <div className="slider-timer-line">
      <div className="inner">
        <div
          className="over-inner"
          style={{ width: slideTimerWidth + '%' }}
        ></div>
      </div>
    </div>
  )
}

export default ExploreSliderTimer
