import { API, graphqlOperation } from 'aws-amplify'
import constants from '../constants'
import { exploreDynamic } from '../graphql/queries'
import { getJwt } from './auth'
import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage'

export async function getUserDashboard(
  custumer_number: string,
  online: boolean
) {
  const storedValue = await getStoredKey(constants.EXPLORE_SLIDES)

  if (shouldUseCachedValue(storedValue, online, true)) {
    return storedValue
  } else {
    const clientName =
      (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'
    const jwt = await getJwt()
    try {
      const result = await API.graphql(
        graphqlOperation(exploreDynamic, {
          client: clientName,
          customer_number: custumer_number,
          jwt: jwt
        })
      )
      await setStoredKey(constants.EXPLORE_SLIDES, result)
      return result
    } catch {
      return {}
    }
  }
}
