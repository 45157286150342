import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { useSession } from '../../context/session/useSession'

export const PrivateRoute: React.FC<React.PropsWithChildren<RouteProps>> = ({
  children,
  ...rest
}) => {
  const { isAuthenticated, authValidated } = useSession()

  return authValidated ? (
    <Route {...rest}>
      {isAuthenticated ? children : <Redirect to="/login" />}
    </Route>
  ) : null
}
