/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const saveOrderDynamic = /* GraphQL */ `
  mutation SaveOrderDynamic(
    $cookie: String
    $body: ElasticOrderInput
    $jwt: String
    $client: String
  ) {
    saveOrderDynamic(cookie: $cookie, body: $body, jwt: $jwt, client: $client) {
      _id
      number
      state
      net_price
      products {
        number
        name
        _id
        position
        description
        minimum_purchase
        region
        catalog_key
        primary_warehouse
        price_range {
          wBASEUSD
          rBASEUSD
          elastic_wholesale
          elastic_retail
        }
        variations {
          _id
          name
          code
          permissions
          embellishment_strategy
          can_embellish
          is_dropped
          stock_items {
            number
            name
            key
            upc
            sku
            position
            sold_in_multiples_of
            prices {
              wBASEUSD
              rBASEUSD
              elastic_wholesale
              elastic_retail
            }
            alt_name
          }
          images {
            A {
              icon
              detail
              large
              print
              original
            }
            P {
              icon
              detail
              large
              print
              original
            }
            S {
              icon
              detail
              large
              print
              original
            }
          }
          primary_warehouse
          filtered
          tags {
            Brand
          }
        }
        features {
          name
          items
        }
      }
    }
  }
`;
export const updateOrderDynamic = /* GraphQL */ `
  mutation UpdateOrderDynamic(
    $cookie: String
    $body: ElasticOrderInput
    $id: String
    $jwt: String
    $client: String
  ) {
    updateOrderDynamic(
      cookie: $cookie
      body: $body
      id: $id
      jwt: $jwt
      client: $client
    ) {
      _id
      number
      state
      net_price
      products {
        number
        name
        _id
        position
        description
        minimum_purchase
        region
        catalog_key
        primary_warehouse
        price_range {
          wBASEUSD
          rBASEUSD
          elastic_wholesale
          elastic_retail
        }
        variations {
          _id
          name
          code
          permissions
          embellishment_strategy
          can_embellish
          is_dropped
          stock_items {
            number
            name
            key
            upc
            sku
            position
            sold_in_multiples_of
            prices {
              wBASEUSD
              rBASEUSD
              elastic_wholesale
              elastic_retail
            }
            alt_name
          }
          images {
            A {
              icon
              detail
              large
              print
              original
            }
            P {
              icon
              detail
              large
              print
              original
            }
            S {
              icon
              detail
              large
              print
              original
            }
          }
          primary_warehouse
          filtered
          tags {
            Brand
          }
        }
        features {
          name
          items
        }
      }
    }
  }
`;
export const updateManagedUserDynamic = /* GraphQL */ `
  mutation UpdateManagedUserDynamic(
    $body: ManagedUserObjectInput
    $id: String
    $jwt: String
    $client: String
  ) {
    updateManagedUserDynamic(body: $body, id: $id, jwt: $jwt, client: $client) {
      _id
      origin
      email
      currency_code
      type
      associated_customers
      is_manager
      is_disabled
      custom_catalogs_only
      first_name
      last_name
      username
      account_number
      customer_number
      name
      customer_name
      billing_address_lines
      inventory_source
      contribution_ids
      primary_user_id
      catalogs {
        _id
        allow_dropship
        archived
        classification
        created_at
        default_product_sort
        description
        inventory_mode
        key
        max_order_amount
        min_order_amount
        max_order_units
        min_order_units
        max_order_amount_for_currency
        min_order_amount_for_currency
      }
      customer {
        _id
        about
        access_key
        address {
          _id
          city
          country
          state
          street1
          street2
          street3
          zip
        }
        b2c
        country
        created_at
        name
        locations {
          _id
          account_notice
          address {
            _id
            city
            country
            state
            street1
            street2
            street3
            zip
          }
          address_lines
          name
          number
        }
        client_fields {
          ship_via
          drop_ship
        }
        number
        wholesale_price_group
        retail_price_group
      }
      search_recommendations {
        catalog_key
        custom_catalog_id
        display_name
        product_numbers
        tags
      }
    }
  }
`;
export const deleteManagedUserDynamic = /* GraphQL */ `
  mutation DeleteManagedUserDynamic(
    $id: String
    $jwt: String
    $client: String
  ) {
    deleteManagedUserDynamic(id: $id, jwt: $jwt, client: $client) {
      _id
    }
  }
`;
export const addManagedUserDynamic = /* GraphQL */ `
  mutation AddManagedUserDynamic(
    $body: ManagedUserInput
    $jwt: String
    $client: String
  ) {
    addManagedUserDynamic(body: $body, jwt: $jwt, client: $client) {
      _id
      origin
      email
      currency_code
      type
      associated_customers
      is_manager
      is_disabled
      custom_catalogs_only
      first_name
      last_name
      username
      account_number
      customer_number
      name
      customer_name
      billing_address_lines
      inventory_source
      contribution_ids
      primary_user_id
      catalogs {
        _id
        allow_dropship
        archived
        classification
        created_at
        default_product_sort
        description
        inventory_mode
        key
        max_order_amount
        min_order_amount
        max_order_units
        min_order_units
        max_order_amount_for_currency
        min_order_amount_for_currency
      }
      customer {
        _id
        about
        access_key
        address {
          _id
          city
          country
          state
          street1
          street2
          street3
          zip
        }
        b2c
        country
        created_at
        name
        locations {
          _id
          account_notice
          address {
            _id
            city
            country
            state
            street1
            street2
            street3
            zip
          }
          address_lines
          name
          number
        }
        client_fields {
          ship_via
          drop_ship
        }
        number
        wholesale_price_group
        retail_price_group
      }
      search_recommendations {
        catalog_key
        custom_catalog_id
        display_name
        product_numbers
        tags
      }
    }
  }
`;
export const contactBrandDynamic = /* GraphQL */ `
  mutation ContactBrandDynamic(
    $body: ContactBrandInput
    $jwt: String
    $client: String
  ) {
    contactBrandDynamic(body: $body, jwt: $jwt, client: $client) {
      first_name
      last_name
      email
      company_name
      phone_number
      about
      interested_products
      brand_name
      brand_to_emails
      address {
        city
        country
        state
        street1
        street2
        street3
        zip
      }
    }
  }
`;
export const updateAccountDynamic = /* GraphQL */ `
  mutation UpdateAccountDynamic(
    $body: AccountUpdateObject
    $jwt: String
    $client: String
    $id: String
  ) {
    updateAccountDynamic(body: $body, jwt: $jwt, client: $client, id: $id) {
      _id
      origin
      email
      currency_code
      type
      associated_customers
      is_manager
      is_disabled
      custom_catalogs_only
      first_name
      last_name
      username
      account_number
      customer_number
      name
      customer_name
      billing_address_lines
      inventory_source
      contribution_ids
      primary_user_id
      catalogs {
        _id
        allow_dropship
        archived
        classification
        created_at
        default_product_sort
        description
        inventory_mode
        key
        max_order_amount
        min_order_amount
        max_order_units
        min_order_units
        max_order_amount_for_currency
        min_order_amount_for_currency
      }
      customer {
        _id
        about
        access_key
        address {
          _id
          city
          country
          state
          street1
          street2
          street3
          zip
        }
        b2c
        country
        created_at
        name
        locations {
          _id
          account_notice
          address {
            _id
            city
            country
            state
            street1
            street2
            street3
            zip
          }
          address_lines
          name
          number
        }
        client_fields {
          ship_via
          drop_ship
        }
        number
        wholesale_price_group
        retail_price_group
      }
      search_recommendations {
        catalog_key
        custom_catalog_id
        display_name
        product_numbers
        tags
      }
    }
  }
`;
