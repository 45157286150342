import Image from 'app/components/image/image'
import useProductImage from 'app/hooks/use-product-image'

const VariationImage = ({ variation }: { variation: any }) => {
  const productImage = useProductImage(variation, 'icon')
  return (
    <div className="img-thumb">
      <Image className="product-image" src={productImage} thumbnail />
    </div>
  )
}
export default VariationImage
