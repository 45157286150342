import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonRouterLink,
  IonToolbar
} from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { checkmarkOutline, closeOutline } from 'ionicons/icons'
import React from 'react'
import { getTranslation } from 'translations'

interface ShipToModalProps {
  setShippingAddressOpen: Function
  shippingAddressOpen: boolean
  modalRef: any
  availableLocations: any[]
  changeAddress: Function
  selectedLocation: any
  dropshipAllowed: boolean
  dropShipOnly: boolean
}

const ShipToModal: React.FC<ShipToModalProps> = (props) => {
  const { userLanguageCode } = useSession()

  return (
    <IonModal
      className="pwa-modal"
      onIonModalDidDismiss={() => {
        props.setShippingAddressOpen(false)
      }}
      isOpen={props.shippingAddressOpen}
      ref={props.modalRef}
      animated={false}
      initialBreakpoint={0.3}
    >
      <IonHeader className="no-border-toolbar">
        <IonToolbar>
          <h2>{getTranslation('shipTo', userLanguageCode)}</h2>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                props.modalRef.current?.dismiss()
              }}
            >
              <IonIcon
                className="icon"
                size="large"
                icon={closeOutline}
                color="primary"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="choices">
          {!props.dropShipOnly &&
            props.availableLocations.map((location: any, index: number) => {
              return (
                <IonItem
                  lines="none"
                  key={index}
                  onClick={() => {
                    {
                      props.changeAddress(location.number)
                      props?.modalRef?.current?.dismiss()
                    }
                  }}
                >
                  <IonRouterLink
                    slot="start"
                    className={
                      location.number == props.selectedLocation
                        ? 'selected'
                        : ''
                    }
                  >
                    {location.name}
                  </IonRouterLink>
                  {location.number == props.selectedLocation && (
                    <IonIcon
                      className="icon"
                      size="large"
                      icon={checkmarkOutline}
                      color="primary"
                      slot="end"
                    />
                  )}
                </IonItem>
              )
            })}
          {props.dropshipAllowed && (
            <IonItem
              lines="none"
              onClick={() => {
                {
                  props.changeAddress('drop_ship')
                  props?.modalRef?.current?.dismiss()
                }
              }}
            >
              <IonRouterLink
                slot="start"
                className={
                  props.selectedLocation == 'drop_ship' ? 'selected' : ''
                }
              >
                {getTranslation('shippingAddress', userLanguageCode)}
              </IonRouterLink>
              {props.selectedLocation == 'drop_ship' && (
                <IonIcon
                  className="icon"
                  size="large"
                  icon={checkmarkOutline}
                  color="primary"
                  slot="end"
                />
              )}
            </IonItem>
          )}
        </div>
      </IonContent>
    </IonModal>
  )
}

export default ShipToModal
