import { IonIcon } from '@ionic/react'
import useOnClickOutside from 'app/hooks/useOnClickOutside'
import classNames from 'classnames'
import { closeOutline } from 'ionicons/icons'
import {
  RefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { useHistory } from 'react-router'

const ACTION_WIDTH = 250

type ActionListProps = {
  userName: string
  visible: boolean
  objectRef: RefObject<HTMLButtonElement>
  domRect?: DOMRect
  hideAccountSettings?: boolean
  hideUserManager?: boolean
  toggle: () => void
  accountSettings: () => void
  logout: () => void
  actions: {
    onClick: () => void
    content: string
  }[]
}

const ActionList = ({
  visible,
  actions,
  objectRef,
  domRect,
  toggle,
  accountSettings,
  logout,
  hideAccountSettings,
  hideUserManager,
  userName
}: ActionListProps) => {
  const [pivot, setPivot] = useState<DOMRect | undefined>({
    y: 0,
    x: 0,
    width: 200,
    height: 100,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    toJSON: () => null
  })
  const ulRef = useRef(null)
  const navigate = useHistory()
  useOnClickOutside(ulRef, () => toggle())
  const setDimensions = useCallback(() => {
    setPivot(domRect)
  }, [domRect])

  useLayoutEffect(() => {
    const element = objectRef?.current
    if (!element) {
      return
    }

    setDimensions()

    if (typeof ResizeObserver === 'function') {
      const resizeObserver = new ResizeObserver(() => setDimensions())
      resizeObserver.observe(element)
      window.addEventListener('resize', setDimensions)
      return () => {
        if (!resizeObserver) {
          return
        }
        resizeObserver.disconnect()
      }
    }
    return () => {
      window.removeEventListener('resize', setDimensions)
    }
  }, [objectRef, setDimensions])

  if (!domRect || !pivot || !visible) return null
  return (
    <>
      <div className="backdrop" onDoubleClick={toggle}></div>
      <ul
        aria-hidden={visible}
        ref={ulRef}
        aria-label="dropdown"
        onBlur={toggle}
        className={classNames('dropdown-button', { open: visible })}
        style={{
          top: pivot?.y + pivot.height,
          left: pivot.right - ACTION_WIDTH
        }}
      >
        <li className="user-name">
          {userName && <span className="name">{userName}</span>}

          <span role="button" className="close-button" onClick={toggle}>
            <IonIcon icon={closeOutline} color="primary" />
          </span>
        </li>
        {actions.map((action: any) => (
          <li
            aria-hidden
            role="button"
            tabIndex={0}
            className="dropdown-button-item"
            onClick={action.onClick}
            key={action.content}
          >
            <span className="dropdown-item">{action.content}</span>
          </li>
        ))}
        <hr />
        {!hideUserManager && (
          <li
            tabIndex={0}
            role="button"
            className="dropdown-button-item"
            onClick={() => {
              navigate.push('/user-manager')
              toggle()
            }}
          >
            User Manager
          </li>
        )}
        {!hideAccountSettings && (
          <li
            tabIndex={0}
            role="button"
            className="dropdown-button-item"
            onClick={accountSettings}
          >
            Account Settings
          </li>
        )}
        <li
          onClick={() => {
            logout()
          }}
          role="button"
          className="dropdown-button-item"
        >
          Log Out
        </li>
      </ul>
    </>
  )
}

export default ActionList
