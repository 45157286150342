import { IonCheckbox, IonIcon } from '@ionic/react'
import { addOutline, removeOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import type { Facet, Option } from '../FacetFilters'
import './FacetGroup.scss'
import FacetCheckbox from './facet-checkbox/FacetCheckbox'

interface Props {
  facet: Facet
  toggleFacet: (payload: {
    facet?: { value: string; checked: boolean }
    all?: { value: string[]; checked: boolean }
  }) => void
  selectedFacets: any[]
  index?: number
}

const FacetGroup: React.FC<Props> = ({
  facet,
  selectedFacets,
  toggleFacet,
  index
}) => {
  const [open, setOpen] = useState<boolean>(index === 0)
  const [checked, setChecked] = useState(false)
  const values = facet.options.map(
    (term: { title: string }) => `${facet.title}|${term.title}`
  )

  useEffect(() => {
    setChecked(values.every((value) => selectedFacets.includes(value)))
  }, [selectedFacets, values])

  return (
    <div className="facet-group">
      <div>
        <IonCheckbox
          mode="md"
          color="secondary"
          onIonChange={(e) => {
            setChecked(e.detail.checked)
            toggleFacet({ all: { value: values, checked: e.detail.checked } })
          }}
          checked={checked}
          justify="start"
          labelPlacement="end"
        >
          <h3>{facet.title}</h3>
        </IonCheckbox>
        <IonIcon
          icon={open ? removeOutline : addOutline}
          color="primary"
          onClick={() => setOpen(!open)}
        />
      </div>
      <div className={'options' + (open ? ' options--expanded' : '')}>
        {facet.options.map((option: Option) => {
          return (
            <FacetCheckbox
              key={`${facet.title}|${option.title}`}
              option={facet}
              term={option}
              toggleFacet={toggleFacet}
              selectedFacets={selectedFacets}
            />
          )
        })}
      </div>
    </div>
  )
}

export default FacetGroup
