import { ManagedUserInput } from 'API'
import { API, graphqlOperation } from 'aws-amplify'
import constants from '../constants'
import {
  addManagedUserDynamic,
  deleteManagedUserDynamic,
  updateAccountDynamic,
  updateManagedUserDynamic
} from '../graphql/mutations'
import { managedUsersDynamic, validateDynamic } from '../graphql/queries'
import { getJwt } from './auth'
import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage'

export async function getUser(id_token: string, online: boolean) {
  const storedValue = await getStoredKey(constants.USER_INFO)
  if (shouldUseCachedValue(storedValue, online, true)) {
    return storedValue
  } else {
    const clientName =
      (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'
    try {
      const result: any = await API.graphql(
        graphqlOperation(validateDynamic, { client: clientName, ct: id_token })
      )
      await setStoredKey(constants.USER_INFO, result.data)
      return result.data
    } catch {
      return {}
    }
  }
}

export async function updateUser(
  body: UserUpdateObject,
  userId: string
): Promise<any> {
  const jwt = await getJwt()
  const clientName =
    (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'

  try {
    return API.graphql(
      graphqlOperation(updateAccountDynamic, {
        body: { user: body },
        client: clientName,
        id: userId,
        jwt: jwt
      })
    )
  } catch {
    return {}
  }
}

export async function getManagedUsers(): Promise<any> {
  const jwt = await getJwt()
  const clientName =
    (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'

  try {
    return API.graphql(
      graphqlOperation(managedUsersDynamic, {
        client: clientName,
        jwt: jwt
      })
    )
  } catch {
    return {}
  }
}

export async function updateManagedUser(
  body: ManagedUserInput,
  userId: string
): Promise<any> {
  const jwt = await getJwt()
  const clientName =
    (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'

  try {
    return API.graphql(
      graphqlOperation(updateManagedUserDynamic, {
        body: { user: body },
        client: clientName,
        id: userId,
        jwt: jwt
      })
    )
  } catch {
    return {}
  }
}

export async function deleteManagedUser(userId: string): Promise<any> {
  const jwt = await getJwt()
  const clientName =
    (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'

  try {
    return API.graphql(
      graphqlOperation(deleteManagedUserDynamic, {
        client: clientName,
        id: userId,
        jwt: jwt
      })
    )
  } catch {
    return {}
  }
}

export async function addManagedUser(
  body: ManagedUserInput,
  userId: string
): Promise<any> {
  const jwt = await getJwt()
  const clientName =
    (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'

  try {
    return API.graphql(
      graphqlOperation(addManagedUserDynamic, {
        body: body,
        client: clientName,
        id: userId,
        jwt: jwt
      })
    )
  } catch {
    return {}
  }
}

export interface UserUpdateObject {
  email: string
  password?: string
  password_confirmation?: string
  last_name: string
  first_name: string
  username: string
}
