import { IonPage, useIonViewWillEnter } from '@ionic/react'
import { useConfig } from 'app/context/config/ConfigContext'
import type { Config, SortMenuFlags } from 'models/Config'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import constants from '../../../constants'
import ProductList from './components/ProductListing'

const getSortOptionById = (id: string) => {
  switch (id) {
    case 'catalog_asc':
      return constants.CATALOG_SORT_OPTIONS.workbook.asc
    case 'catalog_desc':
      return constants.CATALOG_SORT_OPTIONS.workbook.desc
    case 'price_asc':
      return constants.CATALOG_SORT_OPTIONS.price.asc
    case 'price_desc':
      return constants.CATALOG_SORT_OPTIONS.price.desc
    case 'name_asc':
      return constants.CATALOG_SORT_OPTIONS.name.asc
    case 'name_desc':
      return constants.CATALOG_SORT_OPTIONS.name.desc
    case 'prdouct_asc':
      return constants.CATALOG_SORT_OPTIONS.number.asc
    case 'relevance_asc':
      return constants.CATALOG_SORT_OPTIONS.relevance.asc
    case 'inventory_asc':
      return constants.CATALOG_SORT_OPTIONS.inventory.asc
    default:
      return null
  }
}

const filters = (search?: string) => {
  let sortOption = null
  const urlSearchParams = new URLSearchParams(search)
  const params = Object.fromEntries(urlSearchParams.entries())

  if (params.sort != null) {
    sortOption = getSortOptionById(params.sort)
  }

  return { sortOption }
}

const ProductListing: React.FC = ({ mockSearch }: { mockSearch?: string }) => {
  const { data: brandConfig } = useConfig()
  const [sortOptions, setSortOptions] = useState<any[]>([])
  const [initialSortOption, setInitialSortOption] = useState<any>(null)
  const [preFilters, setPreFilters] = useState<any | null>(null)

  const history = useHistory()

  const setTheFilters = useCallback(() => {
    setInitialSortOption(null)
    setSortOptions([])
    setPreFilters(filters(mockSearch || window.location.search))
  }, [mockSearch])

  useEffect(() => {
    if (!initialSortOption) {
      if (brandConfig?.brandDynamic?.config?.flags) {
        getSortOptions(brandConfig.brandDynamic.config)
      }
    }
  }, [brandConfig?.brandDynamic.config, initialSortOption, preFilters])

  // check each time we enter this view for a pre-search
  useIonViewWillEnter(async () => {
    setTheFilters()
  })

  useEffect(() => {
    if (mockSearch) {
      setTheFilters()
    }
  }, [mockSearch, setTheFilters])

  useEffect(() => {
    return history.listen((location: any, action: any) => {
      if (location.pathname.indexOf('/catalogs') !== -1) {
        if (action == 'POP') {
          setTheFilters()
        }
      }
    })
  }, [history, setTheFilters])

  function getSortOptions(config: Config) {
    const sortOptionsToSet = []

    if (config?.flags?.dropdownOptionsConfig) {
      const availibleSortOptions: SortMenuFlags =
        config?.flags?.dropdownOptionsConfig

      if (availibleSortOptions.workbook) {
        setInitialSortOption(constants.CATALOG_SORT_OPTIONS.workbook.asc)

        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.workbook.asc)
        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.workbook.desc)
      }

      if (availibleSortOptions.price) {
        if (!availibleSortOptions.workbook) {
          setInitialSortOption(constants.CATALOG_SORT_OPTIONS.price.asc)
        }

        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.price.asc)
        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.price.desc)
      }

      if (availibleSortOptions.name) {
        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.name.asc)
        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.name.desc)
      }

      if (availibleSortOptions.number) {
        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.number.asc)
      }

      if (availibleSortOptions.inventory) {
        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.inventory.asc)
      }

      if (availibleSortOptions.relevance_order) {
        sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.relevance.asc)
      }
    }

    // if all sorts are disabled, we need at least one we can use
    if (sortOptionsToSet.length == 0) {
      sortOptionsToSet.push(constants.CATALOG_SORT_OPTIONS.price.asc)
      setInitialSortOption(constants.CATALOG_SORT_OPTIONS.price.asc)
    }

    setSortOptions(sortOptionsToSet)
  }

  // react-router should be doing this anyway, but this component is rendering in product-details and cart
  return window.location.pathname === '/explore/catalogs' ? (
    <IonPage className="page-with-menu">
      {sortOptions.length > 0 && preFilters && (
        <ProductList
          preFilters={preFilters}
          initialSort={initialSortOption}
          sortOptions={sortOptions}
          hideItemNumber={
            !!brandConfig?.brandDynamic?.config?.flags.hideProductDataInUI
              .product_number
          }
        />
      )}
    </IonPage>
  ) : null
}

export default ProductListing
