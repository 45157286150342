import { Ref, RefObject } from 'react'

import {
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonMenuToggle
} from '@ionic/react'
import { search } from 'ionicons/icons'
import { getTranslation } from 'translations'
import { URL_PRODUCTS_PAGE } from '../constansts'
type LeftSideProps = {
  hoverRef?: RefObject<HTMLElement>
  userLanguageCode: string
  userCatalogId?: string
}

const LeftSideMenu = ({
  hoverRef,
  userLanguageCode,
  userCatalogId
}: LeftSideProps) => {
  return (
    <IonButtons slot="start">
      {userCatalogId && (
        <IonButton
          className="button-native show-on-desktop"
          type="button"
          expand="block"
          routerLink={URL_PRODUCTS_PAGE + userCatalogId}
        >
          {getTranslation('shopNow', userLanguageCode)}
        </IonButton>
      )}

      <IonMenuToggle>
        <IonButton
          routerLink={'/explore'}
          ref={hoverRef as Ref<HTMLIonButtonElement> | undefined}
          className="show-on-desktop header-button"
        >
          <span className="animation">{getTranslation('explore', 'en')}</span>
        </IonButton>
      </IonMenuToggle>

      <IonMenuButton className="hidden-on-desktop" data-testid="menu" />
      <IonButton routerLink={'/search'}>
        <IonIcon size="medium" icon={search} />
      </IonButton>
    </IonButtons>
  )
}

export default LeftSideMenu
