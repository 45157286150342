import { CartItem } from 'models'
import { findItemImage } from 'services/catalog'
import useBrandConfig from './use-brand-config'

const useProductImage = (product: CartItem, type = 'detail') => {
  const { data: brandConfig } = useBrandConfig()
  const missingImage = brandConfig?.brandDynamic?.config?.flags?.missingImage

  return findItemImage(product, type, missingImage)
}

export default useProductImage
