import { menuController } from '@ionic/core/components'
import { IonHeader, IonRouterLink, IonToolbar } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import ModalSettings from 'app/components/header/components/modal-settings/ModalSettings'
import { useCart } from 'app/context/cart/useCart'
import { useConfig } from 'app/context/config/ConfigContext'
import { useConnection } from 'app/context/connection/useConnection'
import useHover from 'app/hooks/useHover'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { getBrandConfig } from 'services/auth'
import { getTranslation } from 'translations'
import constants from '../../../constants'
import { useSession } from '../../context/session/useSession'
import Image from '../image/image'
import './Header.scss'
import ActionList from './components/ActionList'
import LeftSideMenu from './components/LeftSideMenu'
import RightSideMenu from './components/RightSideMenu'
import { TIME_TO_OPEN } from './constansts'
import Notification from './notification/Notification'

const actions = [
  { content: 'Preferences', onClick: () => {} },
  { content: 'Support', onClick: () => {} }
]

const remainingCreditsText = (remainingCredits: number): string => {
  return `${remainingCredits < 0 ? '-' : ''}$${Math.abs(
    remainingCredits
  ).toFixed(2)}`
}

const Header: React.FC = ({
  mockLocationPath
}: {
  mockLocationPath?: string
}) => {
  const { data: brandConfig } = useConfig()
  const [clientLogo, setClientLogo] = useState<string>()
  const { connected } = useConnection()
  const { data } = useQuery({
    queryKey: ['brandConfig', constants.CONFIG_TYPE],
    queryFn: () => getBrandConfig(constants.CONFIG_TYPE, connected)
  })

  useEffect(() => {
    if (data) {
      setClientLogo(data?.brandDynamic?.config?.images?.logo)
    }
  }, [data])
  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo()
  })
  const profileRef = useRef<HTMLButtonElement>(null)
  const [profileRec, setProfileRect] = useState<DOMRect>()
  const [visible, setVisible] = useState(false)
  const [displayDiscount, setDisplayDiscount] = useState(false)
  const [hideAccountSettings, setHideAccountSettings] = useState(true)
  const [hoverRef, isHovered] = useHover()
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const toggleSettings = () => setIsSettingsOpen(!isSettingsOpen)
  const history = useHistory()
  const {
    isAuthenticated,
    getUserInfo,
    userLanguageCode,
    userCatalogId,
    logout,
    userInfo
  } = useSession()

  const { totalCartItems, remainingCredits } = useCart()

  useEffect(() => {
    if (isAuthenticated) {
      if (brandConfig?.brandDynamic?.config?.flags) {
        setHideAccountSettings(
          brandConfig?.brandDynamic.config.flags.hideAccountSettings
        )
      }
    }
  }, [brandConfig, isAuthenticated])

  useEffect(() => {
    if (profileRef?.current) {
      setProfileRect(profileRef?.current?.getBoundingClientRect())
    }
  }, [profileRef])

  useEffect(() => {
    const pathname = mockLocationPath || history.location.pathname
    setDisplayDiscount(pathname == '/cart')
    history.listen((event: any) => {
      setDisplayDiscount(event.pathname == '/cart')
    })
  }, [])

  const toggleProfile = () => setVisible(!visible)

  const openMenu = async () => {
    if (await menuController.isOpen('sidebar')) return
    setTimeout(async () => {
      await menuController.toggle()
    }, TIME_TO_OPEN)
  }

  useEffect(() => {
    if (isHovered) openMenu()
  }, [isHovered])

  if (user?.customer && !user.customer.b2c) {
    return <></>
  }

  const userName = userInfo?.first_name || ''
  return (
    <>
      {isAuthenticated && (
        <IonHeader className="app-header">
          <ModalSettings isOpen={isSettingsOpen} toggle={toggleSettings} />
          <IonToolbar mode="md">
            <LeftSideMenu
              {...{
                hoverRef,
                userLanguageCode,
                userCatalogId
              }}
            />

            {clientLogo != '' && (
              <IonRouterLink routerLink="/explore" className="logo-center">
                <Image className="menu-logo" src={clientLogo} />
              </IonRouterLink>
            )}
            <RightSideMenu
              visible={false}
              {...{ totalCartItems, userName }}
              objectRef={profileRef}
              openProfile={toggleProfile}
            />
          </IonToolbar>
          <Notification />
          <ActionList
            {...{
              visible,
              actions,
              userName: `${userInfo?.first_name} ${userInfo?.last_name}`,
              logout,
              hideAccountSettings
            }}
            domRect={profileRec}
            objectRef={profileRef}
            toggle={toggleProfile}
            hideUserManager={!userInfo?.is_manager}
            accountSettings={toggleSettings}
          />
          {remainingCredits && displayDiscount && totalCartItems > 0 && (
            <p
              className={
                'total-discount ' + (remainingCredits < 0 ? 'over' : '')
              }
            >
              {getTranslation('remainingCredits', userLanguageCode)}:{' '}
              <strong>{remainingCreditsText(remainingCredits)}</strong>
            </p>
          )}
        </IonHeader>
      )}
    </>
  )
}

export default Header
