const constants = {
  EXPLORE_SLIDES: 'EXPLORE_SLIDES',
  CONFIG_TYPE: 'scramble',
  BREAKPOINTS: {
    sm: 576,
    md: 768,
    lg: 1440,
    xl: 1920
  },
  CACHED_IMAGE: 'IMAGE_STORE_',
  BRAND: 'BRAND_',
  USER_LANGUAGE_CODE: 'USER_LANGUAGE_CODE',
  CLIENT: 'CLIENT_',
  CLIENT_NAME: 'CLIENT_NAME',
  CATALOG: 'CATALOG_',
  EMAIL_REGEX:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ORDERS: 'ORDERS',
  CALLAWAY_IFRAME_URL: 'CALLAWAY_IFRAME_URL',
  USER_INFO: 'USER_INFO',
  BRAND_LIST: 'BRAND_LIST',
  CLIENT_EXCHANGE_USER: 'CLIENT_EXCHANGE_USER',
  CURRENT_ORDER_ID: 'CURRENT_ORDER_ID',
  CLIENT_LOGO: 'CLIENT_LOGO',
  API_COOKIE: 'API_COOKIE',
  API_TOKEN: 'API_TOKEN',
  APP_PRICE_TYPE: 'APP_PRICE_TYPE',
  CURRENT_CATALOG_RESULTS: 'CURRENT_CATALOG_RESULTS',
  CURRENT_CATALOG_SHIPMENTS: 'CURRENT_CATALOG_SHIPMENTS',
  CURRENT_CLIENT_NAME: 'CURRENT_CLIENT_NAME',
  DEFAULT_CLIENT_NAME: 'callawaygolf',
  REFERESH_TOKEN: 'REFRESH_TOKEN',
  COGNITO_POOL_ID: 'COGNITO_POOL_ID',
  COGNITO_CLIENT_ID: 'COGNITO_CLIENT_ID',
  PRODUCT_RESULTS_PER_PAGE: 20,
  STORED_USERNAME: 'STORED_USERNAME',
  STORED_PASSWORD: 'STORED_PASSWORD',
  CATALOG_SORT_OPTIONS: {
    catalog: {
      asc: {
        name: 'Catalog Order',
        id: 'catalog_asc',
        skillet_id: 'catalog',
        order: 'asc'
      }
    },
    workbook: {
      asc: {
        name: 'Catalog Asc',
        id: 'catalog_asc',
        skillet_id: 'catalog',
        order: 'asc'
      },
      desc: {
        name: 'Catalog Desc',
        id: 'catalog_desc',
        skillet_id: 'catalog',
        order: 'desc'
      }
    },
    name: {
      asc: {
        name: 'Name (A-Z)',
        id: 'name_asc',
        skillet_id: 'name',
        order: 'asc'
      },
      desc: {
        name: 'Name (Z-A)',
        id: 'name_desc',
        skillet_id: 'name',
        order: 'desc'
      }
    },
    price: {
      asc: {
        name: 'Price (Low - High)',
        id: 'price_asc',
        skillet_id: 'price',
        order: 'asc'
      },
      desc: {
        name: 'Price (High - Low)',
        id: 'price_desc',
        skillet_id: 'price',
        order: 'desc'
      }
    },
    number: {
      asc: {
        name: 'Product # (Low - High)',
        id: 'prdouct_asc',
        skillet_id: 'product',
        order: 'asc'
      },
      desc: {
        name: 'Product # (High - Low)',
        id: 'prdouct_desc',
        skillet_id: 'product',
        order: 'desc'
      }
    },
    inventory: {
      asc: {
        name: 'Availible',
        id: 'inventory_asc',
        skillet_id: 'inventory',
        order: 'asc'
      }
    },
    relevance: {
      asc: {
        name: 'Relevance',
        id: 'relevance_asc',
        skillet_id: 'relevance',
        order: 'asc'
      }
    }
  },
  BRAND_SORT_OPTIONS: [
    {
      name: 'A to Z',
      id: 'ascending'
    },
    {
      name: 'Z to A',
      id: 'descending'
    }
  ]
}

export default constants
