import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonText,
  IonToolbar
} from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { closeOutline } from 'ionicons/icons'
import { RefObject, useEffect, useState } from 'react'
import { getTranslation } from 'translations'
import './FacetFilters.scss'
import FacetGroup from './facet-group/FacetGroup'

export type Option = {
  title: string
  amount: number
}

export type Facet = {
  title: string
  options: Option[]
}

export interface Props {
  modalRef: RefObject<HTMLIonModalElement>
  handleFilterClose: () => void
  availibleFacets: any[]
  selectedFacets: string[]
  clearFilters: () => void
  searchTerm: string
  isOpen?: boolean
  search?: string
  setSearch?: Function
  applyFacet: (payload: {
    facet?: { value: string; checked: boolean }
    all?: { value: string[]; checked: boolean }
  }) => void
  applySearch: (search?: string) => void
}

const FacetSearch = (props: Props) => {
  const { userLanguageCode } = useSession()

  const handleSearch = () => {
    props.applySearch(props.search)
    props.handleFilterClose()
  }

  return (
    <div className="search-field">
      <IonItem>
        <IonInput
          placeholder={getTranslation('searchProducts', userLanguageCode)}
          onKeyDown={(e: any) => {
            if (e.code === 'Enter') {
              handleSearch()
            }
          }}
          onIonInput={(e: any) => {
            if (props.setSearch) {
              props.setSearch(e.detail.value)
            }
          }}
          value={props.search}
        />
      </IonItem>
      <IonIcon onClick={handleSearch} src="/assets/icon/search.svg" />
    </div>
  )
}

function filterDisplayText({
  searchTerm = '',
  selectedFacets
}: {
  selectedFacets: string[]
  searchTerm?: string
}) {
  if (selectedFacets.length > 0 || searchTerm !== '') {
    return `(${selectedFacets.length + (searchTerm !== '' ? 1 : 0)})`
  }

  return ''
}

const FacetFilterList = (props: Props) => {
  const { userLanguageCode } = useSession()
  const [selectedFacets, setSelectedFacets] = useState<string[]>([])
  const [search, setSearch] = useState('')
  const toggleFacet = ({
    facet,
    all
  }: {
    facet?: { value: string; checked: boolean }
    all?: { value: string[]; checked: boolean }
  }) => {
    if (all) {
      setSelectedFacets((facets) => {
        return all.checked
          ? [...facets, ...all.value]
          : facets.filter((facet) => !all.value.includes(facet))
      })
    } else if (facet) {
      setSelectedFacets((facets) => {
        return facet.checked
          ? [...facets, facet.value]
          : facets.filter((f) => f !== facet.value)
      })
    }

    props.applyFacet({ all, facet })
  }

  useEffect(() => {
    setSelectedFacets(props.selectedFacets)
  }, [props.selectedFacets])

  useEffect(() => {
    if (props.searchTerm) {
      setSearch(props.searchTerm)
    }
  }, [props.searchTerm])

  return (
    <div className="filter-action-sheet facet-action-sheet">
      <FacetSearch {...props} search={search} setSearch={setSearch} />
      <div className="filter-options">
        {props.availibleFacets
          .filter(({ title }) => title !== 'Color')
          .map((facet: any, i: number) => {
            return (
              <FacetGroup
                key={i}
                index={i}
                facet={facet}
                toggleFacet={toggleFacet}
                selectedFacets={selectedFacets}
              />
            )
          })}
      </div>

      <div className="filter-actions">
        <IonButton
          onClick={() => {
            setSelectedFacets([])
            setSearch('')
            props.clearFilters()
          }}
          color="secondary"
          shape="round"
          disabled={selectedFacets.length === 0 && props.searchTerm === ''}
        >
          <IonText slot="start">
            {getTranslation('clearFilters', userLanguageCode)}{' '}
            {filterDisplayText({
              selectedFacets,
              searchTerm: props.searchTerm
            })}
          </IonText>
        </IonButton>
        <IonButton
          onClick={() => {
            props.handleFilterClose()
            props.applySearch(search)
          }}
          color="primary"
          shape="round"
        >
          <IonText slot="start">
            {getTranslation('applyFilters', userLanguageCode)}
          </IonText>
        </IonButton>
      </div>
    </div>
  )
}

const FacetFilters = (props: Props) => {
  const { userLanguageCode } = useSession()

  return (
    <IonModal
      className="pwa-modal sort"
      ref={props.modalRef}
      initialBreakpoint={0.9}
      isOpen={props.isOpen}
    >
      <IonHeader className="no-border-toolbar">
        <IonToolbar mode="ios">
          <h2>{getTranslation('filterBy', userLanguageCode)}</h2>
          <IonButtons slot="end">
            <IonButton onClick={props.handleFilterClose}>
              <IonIcon
                className="icon"
                size="large"
                icon={closeOutline}
                color="primary"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FacetFilterList {...props} />
      </IonContent>
    </IonModal>
  )
}

export default FacetFilters
