import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonRouterLink,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  ScrollDetail
} from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import EmptyList from 'app/components/empty-list/EmptyList'
import Breadcrumbs from 'app/components/header/breadcrumbs/Breadcrumbs'
import { useConnection } from 'app/context/connection/useConnection'
import { useSession } from 'app/context/session/useSession'
import useBrandConfig from 'app/hooks/use-brand-config'
import { chevronDownOutline, optionsOutline } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { getCatalog } from 'services/catalog'
import { getTranslation } from 'translations'
import constants from '../../../../constants'
import './ProductListing.scss'
import {
  generateFacetPWAQueryString,
  generateFacetQueryString,
  generateFacets,
  totalFacetDisplayCount
} from './ProductListing.utils'
import FacetFilters from './facet-filters/FacetFilters'
import ProductItem from './product-item/ProductItem'
import ScrollToTop from './scrollToTop/ScrollToTop'
import SortFilters from './sort-filters/SortFilters'

export type SortOption = {
  id: string
  name: string
  order: string
  skillet_id: string
}

interface ProductListingProps {
  sortOptions: SortOption[]
  initialSort: any
  preFilters: any
  hideItemNumber: boolean
  mockSearch?: string
}

const ProductListing: React.FC<ProductListingProps> = (props) => {
  const { data: brandConfig } = useBrandConfig()
  const [totalResultCount, setTotalResultCount] = useState<number>(0)
  const [catalogName, setCatalogName] = useState<string>('')
  const [resultList, setResultList] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [windowPosition, setWindowPosition] = useState<number>(0)
  const scrollTopRef = useRef<HTMLIonContentElement>(null)
  const [availibleFacets, setAvailibleFacets] = useState<any>([])
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const { connected } = useConnection()
  const history = useHistory()
  const { getUserInfo, userLanguageCode } = useSession()
  const { data: userData } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo()
  })

  const sortRef = useRef<HTMLIonModalElement>(null)
  const filterRef = useRef<HTMLIonModalElement>(null)

  const selectSort = async (sortOption: SortOption) => {
    setLoading(true)
    sortRef.current?.dismiss()
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = {
      ...Object.fromEntries(urlSearchParams.entries()),
      sort: sortOption.id,
      page: '1'
    }
    history.push({
      pathname: '/explore/catalogs',
      search: '?' + new URLSearchParams(params).toString()
    })
  }

  const applySearch = (search?: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (search) {
      setLoading(true)
      const params = {
        ...Object.fromEntries(urlSearchParams.entries()),
        query: search,
        page: '1'
      }
      history.push({
        pathname: '/explore/catalogs',
        search: '?' + new URLSearchParams(params).toString()
      })
    }
  }

  const applyFacet = ({
    facet,
    all
  }: {
    facet?: { value: string; checked: boolean }
    all?: { value: string[]; checked: boolean }
  }) => {
    setLoading(true)
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const urlParams: any = {
      catalogId: params.catalogId,
      page: '1',
      sort: params.sort || props.sortOptions[0].id
    }
    if (all) {
      if (all.checked) {
        const tags = generateFacetPWAQueryString(all.value)
        urlParams.tags = params?.tags ? `${params.tags}|${tags}` : tags
      } else {
        // remove filter tags
        urlParams.tags = params.tags
          .split('|')
          .map((v) => v.replace(':', '|'))
          .filter((v) => !all.value.includes(v))
          .map((v) => v.replace('|', ':'))
          .join('|')
      }
    } else if (facet) {
      // read the current tags from the url
      // facet.true: add the facet to the tag param
      if (facet.checked) {
        urlParams.tags = generateFacetPWAQueryString([
          ...(params?.tags || '').split('|'),
          facet.value
        ])
      } else {
        const value = generateFacetPWAQueryString([facet.value])
        urlParams.tags = (params?.tags || '')
          .split('|')
          .filter((v) => v !== value)
          .join('|')
      }
    }

    if (!urlParams.tags.length) {
      delete urlParams.tags
    }

    history.push({
      pathname: '/explore/catalogs',
      search: '?' + new URLSearchParams(urlParams).toString()
    })
  }

  const clearFilters = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const urlParams: any = {
      catalogId: params.catalogId,
      page: '1',
      sort: params.sort || props.sortOptions[0].id
    }
    if (
      history.location.search !==
      `?${new URLSearchParams(urlParams).toString()}`
    ) {
      setLoading(true)
      history.push({
        pathname: '/explore/catalogs',
        search: '?' + new URLSearchParams(urlParams).toString()
      })
    }
  }

  const fetchAllPages = useCallback(
    async (page: number) => {
      // the user has clicked on an item in the list
      // they have viewed the details of that item
      // and then they clicked the back navigation button
      // so they should be in the last scroll position they were in before they went to the detail view
      setLoading(true)
      const pages = Array.from({ length: page }, (_, i) => i + 1)
      const urlSearchParams = new URLSearchParams(
        props.mockSearch || window.location.search
      )
      const params = Object.fromEntries(urlSearchParams.entries())
      const catalogId = params.catalogId
      const {
        query = '',
        tags = '',
        sort
      } = Object.fromEntries(
        new URLSearchParams(history.location.search).entries()
      )

      const tagsQueryString = generateFacetQueryString(
        tags
          .split('|')
          .map((s) => s.replace(':', '|'))
          .filter(Boolean)
      )
      const [sortId, sortDir] = (sort ? sort : props.sortOptions[0].id).split(
        '_'
      )

      const results = await Promise.all(
        pages.map(async (page) => {
          const catalogResults = await getCatalog({
            catalogId,
            customer: userData?.customer_number || '',
            sort: sortId || props.initialSort.skillet_id,
            sort_dir: sortDir || props.initialSort.order,
            tagsQueryString,
            dropped: false,
            tag_facets: true,
            hoist_quantities: true,
            page,
            keyword: query,
            online: connected,
            fallbackImage:
              brandConfig?.brandDynamic?.config?.flags?.missingImage || ''
          })
          return catalogResults.results.filter(
            (result: any) => result.variations.length > 0
          )
        })
      )

      const itemNumber = localStorage.getItem('itemNumber')
      const foundItem = results.find((item) => item.number === itemNumber)
      const position = +(localStorage.getItem('windowPosition') || 0)

      if (itemNumber) {
        if (foundItem && position > 0) {
          scrollTopRef?.current?.scrollToPoint(0, +(position || 0))
          localStorage.removeItem('itemNumber')
          localStorage.removeItem('windowPosition')
        }
      }
    },
    [
      brandConfig?.brandDynamic?.config?.flags?.missingImage,
      connected,
      history.location.search,
      props.initialSort.order,
      props.initialSort.skillet_id,
      props.mockSearch,
      props.sortOptions,
      userData?.customer_number
    ]
  )

  const loadNextCatalogPage = useCallback(async () => {
    const urlSearchParams = new URLSearchParams(
      props.mockSearch || window.location.search
    )
    const params = Object.fromEntries(urlSearchParams.entries())
    const catalogId = params.catalogId
    if (catalogId !== '') {
      const {
        query = '',
        tags = '',
        sort,
        page = 1
      } = Object.fromEntries(
        new URLSearchParams(window.location.search).entries()
      )

      const pageNumber = parseInt(page as string, 10) || 1

      const tagsQueryString = generateFacetQueryString(
        tags
          .split('|')
          .map((s) => s.replace(':', '|'))
          .filter(Boolean)
      )

      if (
        (userData?.catalogs || []).length > 0 &&
        userData?.customer_number !== ''
      ) {
        const [sortId, sortDir] = (sort || props.sortOptions[0].id).split('_')

        const catalogResults = await getCatalog({
          catalogId,
          customer: userData?.customer_number || '',
          sort: sortId || props.initialSort.skillet_id,
          sort_dir: sortDir || props.initialSort.order,
          tagsQueryString,
          dropped: false,
          tag_facets: true,
          hoist_quantities: true,
          page: pageNumber,
          keyword: query,
          online: connected,
          fallbackImage:
            brandConfig?.brandDynamic?.config?.flags?.missingImage || ''
        })
        if (catalogResults.facets.Region && pageNumber === 1) {
          setAvailibleFacets(generateFacets(catalogResults.facets))
        }
        const resultItems = catalogResults.results.filter(
          (result: any) => result.variations.length > 0
        )
        setTotalResultCount(catalogResults.totalResults)
        if (pageNumber === 1) {
          setResultList(resultItems)
        } else {
          setResultList((s) => [
            ...s,
            ...resultItems.filter(
              (r: any) => !s.map(({ _id }) => _id).includes(r._id)
            )
          ])
        }
        setLoading(false)
        setIsFirstLoad(false)
      }
    }
  }, [
    props.mockSearch,
    props.initialSort.skillet_id,
    props.initialSort.order,
    userData,
    connected,
    brandConfig?.brandDynamic?.config?.flags?.missingImage,
    props.sortOptions
  ])

  const loadResults = async (ev: any) => {
    if (!loading) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      const page = (parseInt(params?.page, 10) || 1) + 1

      if (resultList.length < page * constants.PRODUCT_RESULTS_PER_PAGE) {
        const url = new URL(window.location.href)
        url.searchParams.set('page', page.toString())
        window.history.pushState({}, '', url)
      }
      setTimeout(() => ev.target.complete(), 2000)
    }
  }
  function handleScroll(ev: CustomEvent<ScrollDetail>) {
    localStorage.setItem('windowPosition', String(ev.detail.scrollTop))
    setWindowPosition(ev.detail.scrollTop)
  }

  useEffect(() => {
    const itemNumber = localStorage.getItem('itemNumber')
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const page = parseInt(params.page, 10)

    if (itemNumber && page) {
      fetchAllPages(page)
    }
  }, [fetchAllPages])

  useEffect(() => {
    if (window.location.search.length > 0 && userData) {
      loadNextCatalogPage()
    }
    // loadNextCatalogPage gets called in an infinite loop when it's added here, we need to fix that
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, userData])

  useEffect(() => {
    if (userData?.customer) {
      setCatalogName(userData.customer.name)
    }
  }, [userData])

  useEffect(() => {
    setInfiniteDisabled(
      resultList.length === totalResultCount && resultList.length !== 0
    )
  }, [resultList, totalResultCount])

  const urlParams = useMemo((): any => {
    const urlSearchParams = new URLSearchParams(history.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const generateFacetsFromUrl = (tags: string) =>
      tags.split('|').map((v) => v.replace(':', '|'))
    return {
      ...params,
      tags: generateFacetsFromUrl(params?.tags || '').filter(Boolean)
    }
  }, [history.location.search])

  return (
    <IonContent
      scrollEvents={true}
      onIonScroll={handleScroll}
      className="product-list"
      ref={scrollTopRef}
    >
      <div className="padding-area">
        {catalogName !== '' && <Breadcrumbs pageName={catalogName} />}
        <div className="catalog-header">
          <IonTitle size="large" class="catalog-name">
            <div className="ion-text-wrap">{catalogName}</div>
          </IonTitle>
          <IonText color="primary" class="result-text">
            <div className="ion-text-wrap">
              {!loading && `Results Found (${totalResultCount})`}
            </div>
          </IonText>
        </div>
      </div>
      <IonGrid style={{ '--ion-grid-padding': 0 }}>
        <IonRow className="filters">
          <IonCol size="6">
            <button
              className="custom-button"
              onClick={() => sortRef.current?.present()}
            >
              <IonText slot="start">
                <span>{getTranslation('sortBy', userLanguageCode)}: </span>
                <strong
                  style={{
                    maxWidth: '70px',
                    display: 'inline-block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    verticalAlign: 'top'
                  }}
                >
                  {props.sortOptions.find(({ id }) => id === urlParams?.sort)
                    ?.name || props.sortOptions[0].name}
                </strong>
              </IonText>
              <IonIcon icon={chevronDownOutline} color="primary" />
            </button>
          </IonCol>
          <IonCol size="6">
            <button
              className={
                'custom-button ' +
                ((urlParams?.tags || '').length > 0 || urlParams.query !== ''
                  ? 'has-filters'
                  : '')
              }
              onClick={() => filterRef.current?.present()}
            >
              <IonText slot="start">
                {(urlParams?.tags || []).length == 0 &&
                  getTranslation('viewFilters', userLanguageCode)}
                {(urlParams?.tags || []).length > 0 &&
                  getTranslation('activeFilters', userLanguageCode)}
                {totalFacetDisplayCount(urlParams)}
              </IonText>
              <IonIcon
                icon={optionsOutline}
                color={
                  urlParams.tags.length > 0 || urlParams.query !== ''
                    ? 'secondary'
                    : 'primary'
                }
              />
            </button>
          </IonCol>
        </IonRow>
        {resultList.length == 0 && !isFirstLoad && (
          <div className="no-results">
            <EmptyList
              title="No Results Found"
              firstLineSubext={
                'Please adjust your search or filter options and try again'
              }
            />
            <IonRouterLink onClick={clearFilters}>Clear filters</IonRouterLink>
          </div>
        )}
        <IonRow>
          {loading ? (
            <div className="products-loading">
              <IonSpinner />
              <IonLabel>Loading products...</IonLabel>
            </div>
          ) : (
            resultList.map((product: any, index: number) => {
              return (
                <ProductItem
                  key={product._id}
                  index={index}
                  {...product}
                  showItemNumber={!props.hideItemNumber}
                  onClick={() => {
                    localStorage.setItem('itemNumber', product.number)
                    localStorage.setItem('search', window.location.search)
                  }}
                />
              )
            })
          )}
        </IonRow>
        <IonInfiniteScroll
          onIonInfinite={loadResults}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonGrid>
      {/* hack: scroll to top takes up 50px at the bottom, which overflows into the last items in the grid, so this empty div moves them up. Margin bottom doesn't work because ionic adds margin 0px !important */}
      <div style={{ height: '50px', width: '100%' }} />
      <SortFilters
        selectedSortOption={
          props.sortOptions.find(({ id }) => id === urlParams.sort) ||
          props.sortOptions[0]
        }
        modalRef={sortRef}
        handleSortClose={() => sortRef.current?.dismiss()}
        selectSort={selectSort}
        sortOptions={props.sortOptions}
      />
      <FacetFilters
        modalRef={filterRef}
        handleFilterClose={() => filterRef?.current?.dismiss()}
        availibleFacets={availibleFacets}
        applySearch={applySearch}
        selectedFacets={urlParams.tags}
        clearFilters={clearFilters}
        searchTerm={urlParams.query}
        applyFacet={applyFacet}
      />
      <ScrollToTop
        refScroll={scrollTopRef}
        whenToShowPositionY={600}
        positionY={windowPosition}
      />
    </IonContent>
  )
}

export default ProductListing
