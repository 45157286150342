import { IonButton, IonIcon } from '@ionic/react'
import { useToaster } from 'app/context/toast/useToast'
import cn from 'classnames'
import { useEffect, useState } from 'react'

const Notification: React.FC = () => {
  const { notifications, popNotification } = useToaster()
  const totalTime = 5000

  const [slideTimerWidth, setSlideTimerWidth] = useState(0)
  const [slideTimerValue, setSliderTimerValue] = useState(0)
  const timeInterval = 50

  useEffect(() => {
    const width = calculateTimerWIdth()
    if (notifications.length > 0 && width < 100) {
      const timer = setTimeout(() => {
        setSliderTimerValue(slideTimerValue + timeInterval)
        setSlideTimerWidth(width)
      }, timeInterval)

      return () => clearTimeout(timer)
    } else {
      if (notifications.length > 0) {
        popNotification()
      }
      if (slideTimerValue != 0 && slideTimerWidth != 0) {
        setSlideTimerWidth(0)
        setSliderTimerValue(0)
      }
    }
  }, [slideTimerValue, notifications])

  function calculateTimerWIdth() {
    const value = (slideTimerValue / totalTime) * 100
    return value < 100 ? value : 100
  }
  return (
    <>
      {notifications.length > 0 && (
        <div
          className={cn(
            'custom-notification ' + (notifications[0].icon ? '' : 'no-icon'),
            notifications[0].className
          )}
        >
          <div className="flex-area">
            <div className="notification-message">
              {notifications[0].icon ? (
                <IonIcon icon={notifications[0].icon} color="primary"></IonIcon>
              ) : (
                <></>
              )}
              <p>
                {notifications[0].amount} {notifications[0].text}
              </p>
            </div>
            <IonButton
              onClick={() => {
                popNotification()
              }}
              className="shop-button"
              shape="round"
              routerLink={notifications[0].buttonRoute}
              color={'primary'}
            >
              {notifications[0].buttonText}
            </IonButton>
          </div>

          <div
            style={{ width: slideTimerWidth + '%' }}
            className="progress-bar"
          ></div>
        </div>
      )}
    </>
  )
}

export default Notification
