import {
  IonContent,
  IonLoading,
  IonPage,
  useIonAlert,
  useIonLoading
} from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'app/context/session/useSession'
import { useCallback, useEffect, useState } from 'react'
import { getUserDashboard } from 'services/dashboard'
import { getStoredKey } from 'services/storage'
import { getTranslation } from 'translations'
import constants from '../../../constants'
import Announcements from './components/announcements/Announcements'
import Links from './components/links/Links'
import ExploreSlider from './components/slider/Slider'
import { getExtraSlideDetails } from './utils'

const defaultSlides = [
  {
    image: '/assets/scenic.jpg',
    headline: 'Welcome to the B2B',
    call_to_actions: [],
    hasCopy: true
  }
]

const Explore: React.FC = () => {
  const [foundUpdate, setFoundUpdate] = useState(false)
  const [presentAlert] = useIonAlert()
  const [present, dismiss] = useIonLoading()
  const { getUserInfo, userLanguageCode } = useSession()
  const { data: userData, isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo()
  })
  const { data: dashboardData } = useQuery({
    queryKey: ['dashboard', userData?.customer?.number],
    queryFn: () => getUserDashboard(userData?.customer?.number as string, true),
    enabled: !!userData?.customer?.number
  })

  const { data: clientName } = useQuery({
    queryKey: ['clientName'],
    queryFn: () => getStoredKey(constants.CLIENT_NAME)
  })

  const [{ slides, links, announcements }, setState] = useState({
    slides: [],
    links: [],
    announcements: []
  })

  const checkForAppUpdate = useCallback(async () => {
    const check = localStorage.getItem('APP_UPDATE')
    if (!foundUpdate) {
      if (check && check == 'ready') {
        setFoundUpdate(true)
        presentAlert({
          header: getTranslation('updateAvailible', userLanguageCode),
          message: getTranslation('updateAvailibleText', userLanguageCode),
          buttons: [
            {
              text: getTranslation('cancel', userLanguageCode),
              role: 'cancel'
            },
            {
              text: getTranslation('ok', userLanguageCode),
              role: 'confirm',
              handler: () => {
                localStorage.setItem('APP_UPDATE', 'installing')
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }
            }
          ]
        })
      } else if (check && check == 'installing') {
        await dismiss()
        present({ message: getTranslation('installing', userLanguageCode) })
        setFoundUpdate(false)
      } else if (check && check == 'installed') {
        setFoundUpdate(true)
        localStorage.setItem('APP_UPDATE', 'fully_installed')
        window.location.reload()
      } else if (check && check == 'fully_installed') {
        localStorage.removeItem('APP_UPDATE')
        presentAlert({
          header: getTranslation('installed', userLanguageCode),
          message: getTranslation('latestInstall', userLanguageCode),
          buttons: ['OK']
        })
      }
    }
  }, [dismiss, foundUpdate, present, presentAlert, userLanguageCode])

  useEffect(() => {
    // check for app update every 5 minutes, unless its installing
    const check = localStorage.getItem('APP_UPDATE')
    setInterval(checkForAppUpdate, check == 'installing' ? 3000 : 300000)

    // also fire it immeditatly on page load once
    checkForAppUpdate()
  }, [checkForAppUpdate])

  useEffect(() => {
    if (dashboardData) {
      setState({
        slides:
          dashboardData.data.exploreDynamic.dashboard.marquee.items.length > 0
            ? dashboardData.data.exploreDynamic.dashboard.marquee.items.map(
                getExtraSlideDetails
              )
            : defaultSlides,
        links:
          dashboardData?.data?.exploreDynamic?.dashboard?.links_list?.links ||
          [],
        announcements: (
          dashboardData?.data?.exploreDynamic?.notifications || []
        ).sort(
          (a: any, b: any) =>
            (new Date(a.created_at) as any) - (new Date(b.created_at) as any)
        )
      })
    }
  }, [dashboardData])

  return (
    <IonPage className="page-with-menu">
      <IonContent>
        {isLoading && <IonLoading message="Loading..." isOpen />}
        {slides.length > 0 && <ExploreSlider slides={slides} />}
        {announcements.length > 0 && (
          <Announcements announcements={announcements} />
        )}
        {links.length > 0 && <Links links={links} clientName={clientName} />}
      </IonContent>
    </IonPage>
  )
}

export default Explore
