import { IonCheckbox, IonItem } from '@ionic/react'
import type { Facet, Option } from '../../FacetFilters'
import './FacetCheckbox.scss'
interface Props {
  term: Option
  option: Facet
  toggleFacet: (payload: {
    facet?: { value: string; checked: boolean }
    all?: { value: string[]; checked: boolean }
  }) => void
  selectedFacets: string[]
}

const FacetCheckbox: React.FC<Props> = ({
  option,
  term,
  selectedFacets,
  toggleFacet
}) => {
  const value = `${option.title}|${term.title}`

  return (
    <IonItem lines="none">
      <IonCheckbox
        mode="md"
        onIonChange={(e) => {
          toggleFacet({ facet: { checked: e.detail.checked, value } })
        }}
        color="secondary"
        checked={selectedFacets.includes(value)}
        value={value}
        data-testid={`${term.title.split(' ').join('-')}-checkbox`}
        justify="start"
        labelPlacement="end"
      >
        <span className="facet-checkbox">
          {term.title} ({term.amount})
        </span>
      </IonCheckbox>
    </IonItem>
  )
}

export default FacetCheckbox
