import { IonButton, IonInput, useIonLoading } from '@ionic/react'
import TextInput from 'app/components/text-input/TextInput'
import { useSession } from 'app/context/session/useSession'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { changePassword } from 'services/auth'
import { getTranslation } from 'translations'
import './ChangePasswordForm.scss'
const ChangePasswordForm = ({
  code,
  setResetSuccess
}: {
  code: string
  setResetSuccess: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: { password: '', confirmPassword: '' }
  })
  const [resetting, setResetting] = useState(Boolean)
  const { userLanguageCode } = useSession()
  const [present, dismiss] = useIonLoading()

  const onSubmit = async ({ password }: { password: string }) => {
    if (isValid) {
      setResetting(true)
      await present({ message: 'Changing password...' })

      const resetResult: any = await changePassword(code, password)

      if (resetResult?.data?.resetPasswordDynamic?.ok == true) {
        setResetSuccess(true)
        dismiss()
      } else {
        await dismiss()
        setResetting(false)
        if (
          resetResult?.data?.resetPasswordDynamic?.reset_password_token?.includes(
            'is invalid'
          )
        ) {
          alert(
            'Forgot password token is expired, please submit your email address again.'
          )
        } else {
          alert('Something went wrong, please try again.')
        }
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="change-password-form">
      <TextInput label={getTranslation('newPassword', userLanguageCode)}>
        <IonInput
          {...register('password', {
            required: true,
            min: 8
          })}
        />
      </TextInput>
      <TextInput label={getTranslation('repeatPassword', userLanguageCode)}>
        <IonInput
          {...register('confirmPassword', {
            required: true,
            min: 8,
            validate: (val: string) => {
              if (watch('password') != val) {
                return getTranslation('passwordReqs', userLanguageCode)
              }
            }
          })}
        />
      </TextInput>
      {errors?.confirmPassword && (
        <p className="error">{errors.confirmPassword.message}</p>
      )}
      <IonButton
        type="submit"
        className="ion-margin-top"
        expand="block"
        border-radius="15px"
        disabled={resetting || !code || !isValid}
      >
        {getTranslation('savePassword', userLanguageCode)}
      </IonButton>
    </form>
  )
}

export default ChangePasswordForm
