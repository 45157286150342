import { useEffect, useState } from 'react'
import constants from '../../constants'

const useBreakpoint = () => {
  const [mobile, setMobile] = useState(false)
  const [tablet, setTablet] = useState(false)
  const [desktop, setDesktop] = useState(false)
  const [desktopWide, setDesktopWide] = useState(false)

  useEffect(() => {
    const mobileQuery = window.matchMedia(
      `(max-width: ${constants.BREAKPOINTS.md}px)`
    )
    setMobile(mobileQuery.matches)

    const tabletQuery = window.matchMedia(
      `(min-width: ${constants.BREAKPOINTS.md}px)`
    )
    setTablet(tabletQuery.matches)

    const desktopQuery = window.matchMedia(
      `(min-width: ${constants.BREAKPOINTS.lg}px)`
    )
    setDesktop(desktopQuery.matches)

    const desktopWideQuery = window.matchMedia(
      `(min-width: ${constants.BREAKPOINTS.xl}px)`
    )
    setDesktopWide(desktopWideQuery.matches)

    const listenerMobile = () => setMobile(mobileQuery.matches)
    const listenerTablet = () => setTablet(tabletQuery.matches)
    const listenerDesktop = () => setDesktop(desktopQuery.matches)
    const listenerDesktopWide = () => setDesktopWide(desktopWideQuery.matches)

    window.addEventListener('resize', listenerMobile)
    window.addEventListener('resize', listenerTablet)
    window.addEventListener('resize', listenerDesktop)
    window.addEventListener('resize', listenerDesktopWide)
    return () => {
      window.removeEventListener('resize', listenerMobile)
      window.removeEventListener('resize', listenerTablet)
      window.removeEventListener('resize', listenerDesktop)
      window.removeEventListener('resize', listenerDesktopWide)
    }
  }, [])

  return { mobile, tablet, desktop, desktopWide }
}

export default useBreakpoint
