import { IonButton, IonIcon } from '@ionic/react'
import './EmptyList.scss'

interface Props {
  title: string
  firstLineSubext: string
  secondLineSubtext?: string
  buttonText?: string
  buttonLink?: string
  icon?: any
}

const EmptyList: React.FC<Props> = ({
  title,
  firstLineSubext,
  secondLineSubtext,
  buttonLink,
  buttonText,
  icon
}) => {
  return (
    <div className="empty-list" role="group">
      {icon && (
        <div className="ion-text-center">
          <IonIcon size="large" icon={icon} color={'primary'} />
        </div>
      )}
      <h2>{title}</h2>
      <div className="text">
        <p
          className="ion-text-center"
          dangerouslySetInnerHTML={{ __html: firstLineSubext }}
        ></p>
        {secondLineSubtext && (
          <p className="ion-text-center">{secondLineSubtext}</p>
        )}
      </div>
      {buttonLink && buttonText && (
        <IonButton routerLink={buttonLink} expand="full" shape="round">
          {buttonText}
        </IonButton>
      )}
    </div>
  )
}

export default EmptyList
