import { RefObject } from 'react'

import { IonBadge, IonButton, IonButtons, IonIcon } from '@ionic/react'

import { cartOutline } from 'ionicons/icons'

type RightSideProps = {
  openProfile: () => void
  visible: boolean
  objectRef?: RefObject<HTMLButtonElement>
  userName: string
  totalCartItems: number
}

const RightSideMenu = ({ totalCartItems }: RightSideProps) => {
  return (
    <IonButtons slot="end">
      <IonButton
        aria-label="cartButton"
        className="cart-button"
        routerLink={'/cart'}
      >
        <IonIcon icon={cartOutline} color="primary" />
        {totalCartItems > 0 && (
          <IonBadge className="cart-badge-number" color="danger">
            {totalCartItems}
          </IonBadge>
        )}
      </IonButton>
    </IonButtons>
  )
}

export default RightSideMenu
