import { useQuery } from '@tanstack/react-query'
import { useConnection } from 'app/context/connection/useConnection'
import { getBrandConfig } from 'services/auth'
import constants from '../../constants'

const useBrandConfig = (connection?: boolean) => {
  const { connected } = useConnection()
  const query = useQuery({
    queryKey: ['brandConfig', constants.CONFIG_TYPE],
    queryFn: () =>
      getBrandConfig(constants.CONFIG_TYPE, connection || connected)
  })

  return query
}

export default useBrandConfig
