import { useQuery } from '@tanstack/react-query'
import Image from 'app/components/image/image'
import { useConnection } from 'app/context/connection/useConnection'
import React, { FC } from 'react'
import { getBrandConfig } from 'services/auth'
import constants from '../../../constants'
import './loginLayout.scss'

type LoginLayoutProps = {
  children?: React.ReactNode
  modal?: boolean
}

const LoginLayout: FC<LoginLayoutProps> = ({ children }: any) => {
  // const { brandLogo, brandHero } = useClientImage()
  const [{ brandLogo, brandHero }, setImages] = React.useState<{
    brandLogo: string
    brandHero: string
  }>({ brandLogo: '', brandHero: '' })
  const { connected } = useConnection()
  const { data } = useQuery({
    queryKey: ['brandConfig', constants.CONFIG_TYPE],
    queryFn: () => getBrandConfig(constants.CONFIG_TYPE, connected)
  })

  React.useEffect(() => {
    if (data) {
      setImages({
        brandLogo: data?.brandDynamic?.config?.images?.logo || '',
        brandHero: data?.brandDynamic?.config?.images?.mainBackground || ''
      })
    }
  }, [data])

  return (
    <div className="login-layout">
      <Image className="brand-image brand-hero" src={brandHero} />
      <div className="ion-padding form-content">
        <div className="center-content">
          <Image className="brand-logo brand-logo-control" src={brandLogo} />
          {children}
        </div>
      </div>
    </div>
  )
}

export default LoginLayout
