import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import { API, graphqlOperation } from 'aws-amplify'
import constants from '../constants'
import {
  brandDynamic,
  clientDynamic,
  cognitoInfo,
  forgotPasswordDynamic,
  resetPasswordDynamic
} from '../graphql/queries'
import type { BrandConfig } from '../models/Config'
import { AuthUserData } from '../models/auth-user-types'
import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage'
import { getUser } from './user'
const AmazonCognitoIdentity = require('amazon-cognito-identity-js')

export const defaultConfig = {
  brandDynamic: {
    client: '',
    config: {
      images: { logo: '', mainBackground: '' },
      flags: {
        embellishmentStrategies: [],
        dropShip: {
          ivendixRequireLocation: false,
          requireLocation: false,
          limitToCustomerCountry: false,
          alwaysEnabled: false,
          defaultCountry: '',
          fieldMaxLengths: {
            nameMaxLength: 0,
            cityMaxLength: 0,
            stateMaxLength: 0,
            zipMaxLength: 0,
            address1MaxLength: 0,
            address2MaxLength: 0
          },
          availableCountries: [],
          additionalFields: [],
          fieldRegExps: {
            nameRegExp: '',
            address1RegExp: '',
            address2RegExp: '',
            cityRegExp: '',
            stateRegExp: '',
            zipRegExp: ''
          }
        },
        missingImage: '',
        hideCatalogPositionOption: false,
        hideNotOrderable: false,
        viewProducts: false,
        showBrands: false,
        noProducts: false,
        directoryMode: false,
        algoliaConfig: {
          applicationId: '',
          brandsIndex: '',
          searchApiKey: ''
        },
        initialPriceType: '',
        hideSizeLabelIn3upView: false,
        supportUrl: '',
        hideProductDataInUI: {
          product_number: false,
          stock_item_name: false,
          variation_name: false,
          variation_number: false
        },
        hideAccountSettings: false,
        dropdownOptionsConfig: {
          inventory: false,
          name: false,
          number: false,
          price: false,
          relevance_order: false,
          workbook: false
        }
      }
    }
  }
}

export async function getBrandConfig(
  type: string,
  online: boolean
): Promise<BrandConfig> {
  const storedValue = await getStoredKey(constants.BRAND + type)

  if (shouldUseCachedValue(storedValue, online, true)) {
    return storedValue
  } else {
    const clientName =
      (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'

    try {
      const result: any = await API.graphql(
        graphqlOperation(brandDynamic, { type: type, client: clientName })
      )
      await setStoredKey(constants.BRAND + type, result.data)
      return result.data
    } catch {
      return defaultConfig
    }
  }
}

export async function sendForgotPasswordEmail(email: string): Promise<any> {
  const clientName =
    (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'

  const body = {
    user: {
      email: email
    }
  }
  try {
    return API.graphql(
      graphqlOperation(forgotPasswordDynamic, {
        body: body,
        client: clientName
      })
    )
  } catch {
    return {}
  }
}

export async function getCognitoPoolInfo(): Promise<any> {
  try {
    const clientName =
      (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'
    return API.graphql(graphqlOperation(cognitoInfo, { client: clientName }))
  } catch {
    return {}
  }
}

export async function changePassword(
  token: string,
  password: string
): Promise<any> {
  const clientName =
    (await getStoredKey(constants.CURRENT_CLIENT_NAME)) || 'callawaygolf'

  const body = {
    user: {
      password: password,
      password_confirmation: password,
      reset_password_token: token
    }
  }
  try {
    return API.graphql(
      graphqlOperation(resetPasswordDynamic, { body: body, client: clientName })
    )
  } catch {
    return {}
  }
}

export async function getClientConfig(clientName: string) {
  const storedValue = await getStoredKey(constants.CLIENT + clientName)

  if (storedValue) {
    return storedValue
  } else {
    try {
      const result: any = await API.graphql(
        graphqlOperation(clientDynamic, { client: clientName })
      )
      await setStoredKey(constants.CLIENT + clientName, result.data)
      return result.data
    } catch {
      return {}
    }
  }
}

const authorize = (
  authenticationDetails: AuthenticationDetails,
  pool: CognitoUserPool
): Promise<CognitoUserSession> => {
  const userData = {
    Username: authenticationDetails.getUsername(),
    Pool: pool
  }

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser?.authenticateUser(authenticationDetails, {
      onSuccess: (session: CognitoUserSession) => {
        resolve(session)
      },
      onFailure: (error: any) => {
        reject(error)
      }
    })
  })
}

export async function authUser(
  params: AuthUserData,
  remember: boolean,
  online: boolean
) {
  try {
    const pool = await getCognitoPool()

    const { username, password } = params

    const authenticationData = {
      Username: username,
      Password: password
    }

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)

    const session = await authorize(authenticationDetails, pool)
    await setUserLoggedInDetails(session, remember, online)

    return true
  } catch (error: any) {
    console.log('error', error)
    return false
  }
}

async function setUserLoggedInDetails(
  authDetails: CognitoUserSession,
  remember: boolean,
  online: boolean
) {
  const id_token: CognitoIdToken = authDetails.getIdToken()
  const refresh_token = authDetails.getRefreshToken().getToken()

  if (remember) {
    await setStoredKey(constants.REFERESH_TOKEN, refresh_token)
  }

  // TODO: For Phase 2/3 figure out where to get user language code from
  await setStoredKey(constants.USER_LANGUAGE_CODE, 'en')

  await getUser(id_token.getJwtToken(), online)
}

const getCognitoUser = async () => {
  const cognitoPool = await getCognitoPool()

  return cognitoPool?.getCurrentUser()
}

export const getRegularUserPool = async () => {
  try {
    const availablePools = await getCognitoPoolInfo()

    return (availablePools?.data?.cognitoInfo || []).find(
      ({ name }: { name: string }) => name === 'users'
    )
  } catch (error) {
    console.error(error)
    return false
  }
}

const getCognitoPool = async () => {
  try {
    // both of these are false
    const poolId = await getStoredKey(constants.COGNITO_POOL_ID)
    const clientId = await getStoredKey(constants.COGNITO_CLIENT_ID)

    if (poolId && clientId) {
      return new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: poolId,
        ClientId: clientId
      })
    } else {
      return null
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getSession = async () => {
  const cognitoUser = await getCognitoUser()

  return cognitoUser?.getSession(
    (error: Error, result: CognitoUserSession | null) => {
      if (error) {
        console.error('Error getting session', JSON.stringify(error))
      }
      return result
    }
  )
}

export const cognitoLogout = async () => {
  const cognitoUser = await getCognitoUser()
  await cognitoUser?.signOut()

  return '/'
}

export const refreshSession = async () => {
  const cognitoUser = await getCognitoUser()
  const refreshToken = await getStoredKey(constants.REFERESH_TOKEN)
  if (!refreshToken) {
    return false
  }

  return new Promise((resolve, reject) => {
    const cognitoRefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken
    })

    cognitoUser?.refreshSession(
      cognitoRefreshToken,
      (error: Error, result: CognitoUserSession | null) => {
        if (error) {
          console.error('Error refreshing token', JSON.stringify(error))
          reject(error)
        }

        resolve(result?.getAccessToken()?.getJwtToken())
      }
    )
  })
}

export const getJwt = async () => {
  let session = await getSession()

  try {
    if (session && session?.isValid()) {
      return session.idToken.jwtToken
    } else {
      await refreshSession()
      session = await getSession()

      if (session && session?.isValid()) {
        return session.idToken.jwtToken
      }
    }
  } catch (catchError: any) {
    alert('Please logout and log back in to continue.')
  }
}
