import { IonContent, IonPage } from '@ionic/react'
import { useConfig } from 'app/context/config/ConfigContext'
import { useEffect, useState } from 'react'
import ProductDetailDisplay from './components/ProductDetailDisplay'

const ProductDetail: React.FC = ({ mockNumber }: { mockNumber?: string }) => {
  const { data: brandConfig } = useConfig()
  const [hideItemNumber, setHideItemNumber] = useState<boolean>(false)

  const handleFocus = () =>
    document.querySelector('html')?.classList.add('pwa-focused')

  const handleBlur = () =>
    document.querySelector('html')?.classList.remove('pwa-focused')

  useEffect(() => {
    if (brandConfig) {
      if (brandConfig.brandDynamic.config.flags.hideProductDataInUI) {
        setHideItemNumber(
          brandConfig.brandDynamic.config.flags.hideProductDataInUI
            .product_number
        )
      }
    }
  }, [brandConfig])

  return (
    <IonPage className="page-with-menu product-page">
      <IonContent onFocus={handleFocus} onBlur={handleBlur}>
        <ProductDetailDisplay
          hideItemNumber={hideItemNumber}
          mockNumber={mockNumber}
        />
      </IonContent>
    </IonPage>
  )
}

export default ProductDetail
