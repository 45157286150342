import { Storage } from '@ionic/storage'

const store = new Storage()

export async function getStoredKey(key: string) {
  await store.create()
  const type = await store.get(key)

  if (type) {
    return type
  }

  return false
}

export async function setStoredKey(key: string, value: any) {
  await store.create()
  await store.set(key, value)
}

export async function clearStorage() {
  await store.clear()
}

export async function removeStoredKey(key: string) {
  await store.create()
  await store.remove(key)
}

export function shouldUseCachedValue(
  value: any,
  online: boolean,
  alwaysFresh = false
): boolean {
  return (
    (value && !online) ||
    (value && process.env.REACT_APP_CACHE_STRATEGY === 'always') ||
    (value &&
      process.env.REACT_APP_CACHE_STRATEGY === 'sometimes' &&
      !alwaysFresh)
  )
}

export async function getBase64image(url: string) {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result as string
      resolve(base64data)
    }
  })
}
