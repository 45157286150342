const translations: any = {
  shop: {
    en: 'Shop',
    es: '',
    de: ''
  },
  shopNow: {
    en: 'Shop Now',
    es: '',
    de: ''
  },
  viewProducts: {
    en: 'View Products',
    es: '',
    de: ''
  },
  dashboard: {
    en: 'Dashboard',
    es: '',
    de: ''
  },
  orderHistory: {
    en: 'Order History',
    es: '',
    de: ''
  },
  viewAllBrands: {
    en: 'View All Brands',
    es: '',
    de: ''
  },
  viewAllProducts: {
    en: 'View All Products',
    es: '',
    de: ''
  },
  viewCatalog: {
    en: 'View Catalog',
    es: '',
    de: ''
  },
  learnMore: {
    en: 'Learn more',
    es: '',
    de: ''
  },
  customized: {
    en: 'Customized',
    es: '',
    de: ''
  },
  totalQty: {
    en: 'Total Qty.',
    es: '',
    de: ''
  },
  totalPrice: {
    en: 'Total Price',
    es: '',
    de: ''
  },
  remove: {
    en: 'Remove',
    es: '',
    de: ''
  },
  edit: {
    en: 'Edit',
    es: '',
    de: ''
  },
  color: {
    en: 'Colors',
    es: '',
    de: ''
  },
  dropShipAddressTitle: {
    en: 'Drop Ship Address',
    es: '',
    de: ''
  },
  dropShipAddressError: {
    en: 'We could not verify the address. Please check it for accuracy. Verify the address entered is correct, or press ‘Continue‘ to use the address as entered.',
    es: '',
    de: ''
  },
  email: {
    en: 'Email Address',
    es: '',
    de: ''
  },
  dropShipRegExError: {
    en: 'This field must match RegExp',
    es: '',
    de: ''
  },
  attentionTo: {
    en: 'Name / Attention To',
    es: '',
    de: ''
  },
  address1: {
    en: 'Address 1',
    es: '',
    de: ''
  },
  address2: {
    en: 'Address 2',
    es: '',
    de: ''
  },
  city: {
    en: 'City',
    es: '',
    de: ''
  },
  state: {
    en: 'State',
    es: '',
    de: ''
  },
  zip: {
    en: 'Zip',
    es: '',
    de: ''
  },
  country: {
    en: 'Country',
    es: '',
    de: ''
  },
  cancel: {
    en: 'Cancel',
    es: '',
    de: ''
  },
  continue: {
    en: 'Continue',
    es: '',
    de: ''
  },
  continueShopping: {
    en: 'Continue Shopping',
    es: '',
    de: ''
  },
  phone: {
    en: 'Phone',
    es: '',
    de: ''
  },
  removeItem: {
    en: 'Remove Item from Cart?',
    es: '',
    de: ''
  },
  no: {
    en: 'No',
    es: '',
    de: ''
  },
  yes: {
    en: 'Yes',
    es: '',
    de: ''
  },
  shipTo: {
    en: 'Ship To',
    es: '',
    de: ''
  },
  dropShip: {
    en: 'Drop Ship',
    es: '',
    de: ''
  },
  shippingAddress: {
    en: 'Shipping Address',
    es: '',
    de: ''
  },
  shoppingCart: {
    en: 'Shopping Cart',
    es: '',
    de: ''
  },
  firstName: {
    en: 'First Name',
    es: '',
    de: ''
  },
  lastName: {
    en: 'Last Name',
    es: '',
    de: ''
  },
  companyName: {
    en: 'Company Name',
    es: '',
    de: ''
  },
  yourCompany: {
    en: 'Your Company',
    es: '',
    de: ''
  },
  street1: {
    en: 'Street 1',
    es: '',
    de: ''
  },
  street2: {
    en: 'Street 2',
    es: '',
    de: ''
  },
  stateProvince: {
    en: 'State/Province',
    es: '',
    de: ''
  },
  aboutYou: {
    en: 'About You',
    es: '',
    de: ''
  },
  close: {
    en: 'Close',
    es: '',
    de: ''
  },
  contactFormIntro: {
    en: 'Contact <brand-name> to learn more about their products, retailer programs and more.',
    es: '',
    de: ''
  },
  fieldRequired: {
    en: 'This field is required.',
    es: '',
    de: ''
  },
  productInterest: {
    en: 'Products You’re Interested In ',
    es: '',
    de: ''
  },
  shipmentAddress: {
    en: 'Shipment Address',
    es: '',
    de: ''
  },
  description: {
    en: 'Description',
    es: '',
    de: ''
  },
  cartSummary: {
    en: 'Cart Summary',
    es: '',
    de: ''
  },
  items: {
    en: 'items',
    es: '',
    de: ''
  },
  saveForLater: {
    en: 'Save Order for Later',
    es: '',
    de: ''
  },
  itemsUpper: {
    en: 'Items',
    es: '',
    de: ''
  },
  priceDetails: {
    en: 'Price Details',
    es: '',
    de: ''
  },
  price: {
    en: 'Price',
    es: '',
    de: ''
  },
  discount: {
    en: 'Discount',
    es: '',
    de: ''
  },
  totalAmountDue: {
    en: 'Total Amount Due',
    es: '',
    de: ''
  },
  totalAmount: {
    en: 'Total Amount',
    es: '',
    de: ''
  },
  placeOrder: {
    en: 'Place Order',
    es: '',
    de: ''
  },
  pickUp: {
    en: 'Pick Up @',
    es: '',
    de: ''
  },
  latestAnnouncements: {
    en: 'Latest Announcements',
    es: '',
    de: ''
  },
  of: {
    en: 'of',
    es: '',
    de: ''
  },
  remainingCredits: {
    en: 'Your Remaining Credits',
    es: '',
    de: ''
  },
  forgotPassword: {
    en: 'Forgot Password',
    es: '',
    de: ''
  },
  checkEmail: {
    en: 'Check Your Email',
    es: '',
    de: ''
  },
  enterNewPassword: {
    en: 'Enter New Password',
    es: '',
    de: ''
  },
  passwordReset: {
    en: 'Password Successfully Reset',
    es: '',
    de: ''
  },
  required: {
    en: 'Required',
    es: '',
    de: ''
  },
  enterVaildEmail: {
    en: 'Please enter a valid email address and try again',
    es: '',
    de: ''
  },
  nextStep: {
    en: 'Next Step',
    es: '',
    de: ''
  },
  sentEmailTo: {
    en: 'We’ve sent an email to',
    es: '',
    de: ''
  },
  send: {
    en: 'Send',
    es: '',
    de: ''
  },
  withEmailInstructions: {
    en: 'with instructions on how to reset your password.',
    es: '',
    de: ''
  },
  backToLogin: {
    en: 'Back to Login',
    es: '',
    de: ''
  },
  newPassword: {
    en: 'New Password',
    es: '',
    de: ''
  },
  repeatPassword: {
    en: 'Repeat Password',
    es: '',
    de: ''
  },
  password: {
    en: 'Password',
    es: '',
    de: ''
  },
  passwordReqs: {
    en: 'Password must be 8 or more characters long.',
    es: '',
    de: ''
  },
  savePassword: {
    en: 'Save Password',
    es: '',
    de: ''
  },
  tryLoggingIn: {
    en: 'Please try logging in with your new password.',
    es: '',
    de: ''
  },
  login: {
    en: 'Login',
    es: '',
    de: ''
  },
  needHelp: {
    en: 'Need Help?',
    es: '',
    de: ''
  },
  getSupport: {
    en: 'Get Support',
    es: '',
    de: ''
  },
  username: {
    en: 'Username',
    es: '',
    de: ''
  },
  rememberMe: {
    en: 'Remember Me',
    es: '',
    de: ''
  },
  forgotUsernamePassowrd: {
    en: 'Forgot password?',
    es: '',
    de: ''
  },
  lasSaved: {
    en: 'Last Saved',
    es: '',
    de: ''
  },
  order: {
    en: 'Order',
    es: '',
    de: ''
  },
  orders: {
    en: 'Orders',
    es: '',
    de: ''
  },
  warning: {
    en: 'Warning',
    es: '',
    de: ''
  },
  draftOrderWarning: {
    en: 'There are items in your cart. Opening a draft order will override your current cart.',
    es: '',
    de: ''
  },
  areYouSure: {
    en: 'Are you sure you want to do this?',
    es: '',
    de: ''
  },
  customizeYourClub: {
    en: 'Customize Your Club',
    es: '',
    de: ''
  },
  clubCustomizedInCart: {
    en: 'Club Customized & Added to Cart!',
    es: '',
    de: ''
  },
  clubCustomizeInterested: {
    en: 'Interested? Use our Callaway Customize Club Tool to build the perfect club for you!',
    es: '',
    de: ''
  },
  editCustomization: {
    en: 'Edit Customization',
    es: '',
    de: ''
  },
  customizeClub: {
    en: 'Cutomize Club',
    es: '',
    de: ''
  },
  qty: {
    en: 'Qty.',
    es: '',
    de: ''
  },
  enterQuantities: {
    en: 'Enter Quantities',
    es: '',
    de: ''
  },
  size: {
    en: 'Size',
    es: '',
    de: ''
  },
  chooseA: {
    en: 'Choose a',
    es: '',
    de: ''
  },
  chooseOption: {
    en: 'Choose an option above in order to continue entering in quantities',
    es: '',
    de: ''
  },
  aboveInOrder: {
    en: 'above in order to continue to entering in quantities.',
    es: '',
    de: ''
  },
  productDetails: {
    en: 'Product Details',
    es: '',
    de: ''
  },
  productTechnology: {
    en: 'Technology',
    es: '',
    de: ''
  },
  productFeatures: {
    en: 'Features',
    es: '',
    de: ''
  },
  backToTop: {
    en: 'Back to Top',
    es: '',
    de: ''
  },
  total: {
    en: 'Total',
    es: '',
    de: ''
  },
  updateCart: {
    en: 'Update Cart',
    es: '',
    de: ''
  },
  addToCart: {
    en: 'Add To Cart',
    es: '',
    de: ''
  },
  filterBy: {
    en: 'Filter By',
    es: '',
    de: ''
  },
  searchProducts: {
    en: 'Search Products',
    es: '',
    de: ''
  },
  searchBrands: {
    en: 'Search Brands',
    es: '',
    de: ''
  },
  searchUser: {
    en: 'Search User',
    es: '',
    de: ''
  },
  clearFilters: {
    en: 'Clear Filters',
    es: '',
    de: ''
  },
  clearAllFilters: {
    en: 'Clear All Filters',
    es: '',
    de: ''
  },
  applyFilters: {
    en: 'Apply Filters',
    es: '',
    de: ''
  },
  sortBy: {
    en: 'Sort By',
    es: '',
    de: ''
  },
  viewFilters: {
    en: 'View Filters',
    es: '',
    de: ''
  },
  activeFilters: {
    en: 'Active Filters',
    es: '',
    de: ''
  },
  search: {
    en: 'Search',
    es: '',
    de: ''
  },
  logout: {
    en: 'Logout',
    es: '',
    de: ''
  },
  latestInstall: {
    en: 'Latest version of the app has been installed!',
    es: '',
    de: ''
  },
  installed: {
    en: 'Installed!',
    es: '',
    de: ''
  },
  ok: {
    en: 'OK',
    es: '',
    de: ''
  },
  updateAvailible: {
    en: 'App update availbile!',
    es: '',
    de: ''
  },
  updateAvailibleText: {
    en: 'This app has an updated version, in order to have the most up to date functionality please tap install below. The app will refresh automatically.',
    es: '',
    de: ''
  },
  installing: {
    en: 'Installing...',
    es: '',
    de: ''
  },
  cannotAccess: {
    en: 'You cannot access this site on mobile.',
    es: '',
    de: ''
  },
  loginDifferentDevice: {
    en: 'Please login with a different device compatible with our desktop experience.',
    es: '',
    de: ''
  },
  orderDetails: {
    en: 'Order Details',
    es: '',
    de: ''
  },
  shipmentStatus: {
    en: 'Shipment Status',
    es: '',
    de: ''
  },
  orderSummary: {
    en: 'Order Summary',
    es: '',
    de: ''
  },
  thanksForOrder: {
    en: 'Thank you for your order!',
    es: '',
    de: ''
  },
  yourOrder: {
    en: 'Your Order',
    es: '',
    de: ''
  },
  confirmationEmailSentTo: {
    en: 'and its confirmation email will be sent to',
    es: '',
    de: ''
  },
  confirmOrder: {
    en: 'Confirm Order',
    es: '',
    de: ''
  },
  submitOrder: {
    en: 'Submit Order',
    es: '',
    de: ''
  },
  confirmOrderBody: {
    en: 'All event orders are final and not eligible to be modified or returned',
    es: '',
    de: ''
  },
  shortly: {
    en: 'shortly',
    es: '',
    de: ''
  },
  backToExploring: {
    en: 'Get Back To Exploring',
    es: '',
    de: ''
  },
  cartItemsModified: {
    en: 'Your cart has been adjusted based on current availability.',
    es: '',
    de: ''
  },
  brands: {
    en: 'Our Brands',
    es: '',
    de: ''
  },
  brandResultsFound: {
    en: 'Brand Results',
    es: '',
    de: ''
  },
  brandNotFound: {
    en: 'We could not retrieve the details for this Brand',
    es: '',
    de: ''
  },
  resultsFound: {
    en: 'Results found',
    es: '',
    de: ''
  },
  error: {
    en: 'Error',
    es: '',
    de: ''
  },
  explore: {
    en: 'Explore',
    es: '',
    de: ''
  },
  catalogs: {
    en: 'Catalogs',
    es: '',
    de: ''
  },
  collections: {
    en: 'Collections',
    es: '',
    de: ''
  },
  ourProducts: {
    en: 'Our Products',
    es: '',
    de: ''
  },
  productResultsFound: {
    en: 'Product Results',
    es: '',
    de: ''
  },
  team: {
    en: 'Team',
    es: '',
    de: ''
  },
  contact: {
    en: 'Contact',
    es: '',
    de: ''
  },
  website: {
    en: 'Website',
    es: '',
    de: ''
  },
  social: {
    en: 'Social',
    es: '',
    de: ''
  },
  address: {
    en: 'Address',
    es: '',
    de: ''
  },
  cartOrderAmountTooSmall: {
    en: 'The minimum total order amount for this catalog is {amount}.',
    es: '',
    de: ''
  },
  cartOrderAmountTooLarge: {
    en: 'Allocated credit of ${amount} has been exceeded. Please remove ${excess} from your shopping cart.',
    es: '',
    de: ''
  },
  msrp: {
    en: 'MSRP',
    es: '',
    de: ''
  },
  'components.userManager.enable': {
    en: 'Enabled',
    es: '',
    de: ''
  },
  'components.userManager.username': {
    en: 'Username',
    es: '',
    de: ''
  },
  'components.userManager.email': {
    en: 'Email',
    es: '',
    de: ''
  },
  'components.userManager.actions': {
    en: 'Actions',
    es: '',
    de: ''
  },
  'components.userManager.manager': {
    en: 'Manager',
    es: '',
    de: ''
  },
  'components.userManager.yes': {
    en: 'Yes',
    es: '',
    de: ''
  },
  'components.userManager.no': {
    en: 'No',
    es: '',
    de: ''
  },
  cannotPlaceOrder: {
    en: 'Cannot Place Order',
    es: '',
    de: ''
  },
  cannotPlaceOrderDescription: {
    en: 'An order has already been placed. Additional orders are restricted.',
    es: '',
    de: ''
  }
}

export function getTranslation(string: string, language: string) {
  try {
    if (language == 'en') {
      return translations[string]['en']
    }

    if (language === 'es') {
      if (translations[string]['es'] !== '') {
        return translations[string]['es']
      } else {
        return translations[string]['en']
      }
    }

    if (language === 'de') {
      if (translations[string]['de'] !== '') {
        return translations[string]['de']
      } else {
        return translations[string]['en']
      }
    }
  } catch {
    return ''
  }

  return ''
}

export default translations
