import { IonBadge, IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import { useCart } from 'app/context/cart/useCart'
import { useSession } from 'app/context/session/useSession'
import { chevronForwardOutline } from 'ionicons/icons'
import { ReadOnlyOrder } from 'models'
import moment from 'moment'
import { getBadgeText, getBadgeType } from 'services/order'
import { getTranslation } from 'translations'
import './OrderItem.scss'

interface Props {
  order: ReadOnlyOrder
  openOrder: Function
}

const price = (appPriceType: string, order: ReadOnlyOrder) => {
  const priceToShow =
    appPriceType == 'elastic_retail'
      ? parseFloat(order.total_prices.elastic_retail).toFixed(2).toString()
      : parseFloat(order.total_prices.elastic_wholesale).toFixed(2).toString()
  if (appPriceType == 'both') {
    return `${priceToShow} / $${parseFloat(order.total_prices.elastic_retail)
      .toFixed(2)
      .toString()}`
  }

  return priceToShow
}

const Item: React.FC<{ order: ReadOnlyOrder }> = ({ order }) => {
  const { userLanguageCode } = useSession()
  const { appPriceType } = useCart()
  return (
    <>
      <IonLabel>
        <h3>{order.name}</h3>
        <p>
          {getTranslation('order', userLanguageCode)} #{order.number}
        </p>
      </IonLabel>
      <IonText slot="end">
        <p>
          {order.total_quantity} {getTranslation('items', userLanguageCode)}
        </p>
        <p className="price">${price(appPriceType, order)}</p>
      </IonText>
      <IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
    </>
  )
}

const OrderItem: React.FC<Props> = ({ order, openOrder }) => {
  const { userLanguageCode } = useSession()

  return (
    <div className="order-item">
      {order.state == 'final_submission' ? (
        <IonItem
          lines="none"
          routerLink={`/manage/orders/order-detail/${order._id}`}
          className="order"
        >
          <Item order={order} />
        </IonItem>
      ) : (
        <IonItem
          lines="none"
          className="order"
          onClick={() => openOrder(order)}
        >
          <Item order={order} />
        </IonItem>
      )}
      <IonText>
        <IonBadge color={getBadgeType(order.state)}>
          {getBadgeText(order.state)}
        </IonBadge>{' '}
        <span>
          {getTranslation('lasSaved', userLanguageCode)}{' '}
          {moment(order.updated_at).format('MM/DD/YYYY')}
        </span>
      </IonText>
    </div>
  )
}

export default OrderItem
