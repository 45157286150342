import { IonContent, IonPage } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import EmptyList from 'app/components/empty-list/EmptyList'
import { useSession } from 'app/context/session/useSession'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { getTranslation } from 'translations'

const Submitted: React.FC = () => {
  const [orderNum, setOrderNum] = useState<string>('')

  const { getUserInfo, userLanguageCode } = useSession()
  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo()
  })

  useEffect(() => {
    const pathnames = window.location.pathname.split('/')
    const id = pathnames[pathnames.length - 1]
    setOrderNum(id)
  }, [])

  const firstLineSubext = `${getTranslation(
    'yourOrder',
    userLanguageCode
  )} <strong>#${orderNum}</strong> ${getTranslation(
    'confirmationEmailSentTo',
    userLanguageCode
  )} <strong>${data?.email}</strong> ${getTranslation(
    'shortly',
    userLanguageCode
  )}.`

  return (
    <IonPage className="page-with-menu">
      <IonContent>
        <EmptyList
          icon={checkmarkCircleOutline}
          title={getTranslation('thanksForOrder', userLanguageCode)}
          firstLineSubext={firstLineSubext}
          buttonText={getTranslation('backToExploring', userLanguageCode)}
          buttonLink="/explore"
        />
      </IonContent>
    </IonPage>
  )
}

export default Submitted
