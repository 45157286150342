import { IonButton, IonInput, IonSelect, IonSelectOption } from '@ionic/react'

import PWAModal from 'app/components/modal-layout/PWAModal'
import { useSession } from 'app/context/session/useSession'
import React from 'react'
import { StateObject, useStates } from 'react-us-states'
import { getTranslation } from 'translations'

import InputWrapper from 'app/components/InputWrapper/InputWrapper'
import { useForm } from 'react-hook-form'
import './DropShipModal.scss'

interface DropShipModalProps {
  setDropShipOpen: Function
  dropShipOpen: boolean
  dropShipErrorRef: any
  dropShipAddressError: boolean
  dropShipFieldErrors: any
  dropShipFieldRegExps: any
  dropShipEmailField: boolean
  dropShipPhoneField: boolean
  dropShipFieldMaxLengths: any
  dropshipAllowed: boolean
  newDropShipAddress: any
  doChangeDropShipAddress: (newDropShipAddress: any, event: any) => void
}

const DropShipModal: React.FC<DropShipModalProps> = ({
  dropShipOpen,
  setDropShipOpen,
  dropShipAddressError,
  doChangeDropShipAddress,
  dropShipEmailField,
  dropShipFieldMaxLengths,
  newDropShipAddress,
  dropShipPhoneField,
  dropShipFieldRegExps
}) => {
  const usStates = useStates()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ reValidateMode: 'onBlur' })
  const onSubmit = (data: any) => {
    doChangeDropShipAddress(data, null)
  }
  const { userLanguageCode } = useSession()
  const onError = (theErrors: any, e: any) => console.log(theErrors, e)
  const toggleModal = () => setDropShipOpen(!dropShipOpen)

  window.addEventListener('popstate', function () {
    setDropShipOpen(false)
  })

  return (
    <PWAModal
      header={
        <div className="drop-ship__header">
          <h2>{getTranslation('shippingAddress', userLanguageCode)}</h2>
        </div>
      }
      footer={
        <>
          <IonButton shape="round" color={'secondary'} onClick={toggleModal}>
            {getTranslation('cancel', userLanguageCode)}
          </IonButton>
          <IonButton shape="round" onClick={handleSubmit(onSubmit, onError)}>
            {getTranslation('continue', userLanguageCode)}
          </IonButton>
        </>
      }
      isOpen={dropShipOpen}
      toggle={() => {
        setDropShipOpen(!dropShipOpen)
      }}
    >
      <div className="drop-ship">
        <form
          role="form"
          className="address-form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          {dropShipAddressError && (
            <div className="drop-ship__alert-area">
              <InputWrapper.ErrorMessage
                message={getTranslation(
                  'dropShipAddressError',
                  userLanguageCode
                )}
              />
            </div>
          )}
          {dropShipEmailField && (
            <InputWrapper
              errorMessage={errors.email?.message as string}
              hasError={!!errors?.email}
              labelMessage={getTranslation('email', userLanguageCode)}
            >
              <IonInput
                {...register('email', {
                  required: 'email is required'
                })}
                maxlength={dropShipFieldMaxLengths.emailMaxLength}
              ></IonInput>
            </InputWrapper>
          )}
          {dropShipPhoneField && (
            <InputWrapper
              errorMessage={errors?.phone?.message as string}
              hasError={!!errors.phone}
              labelMessage={getTranslation('phone', userLanguageCode)}
            >
              <IonInput
                {...register('phone', {
                  required: 'phone is required'
                })}
                maxlength={dropShipFieldMaxLengths.phoneMaxLength}
                value={newDropShipAddress.phone}
              ></IonInput>
            </InputWrapper>
          )}

          <InputWrapper
            labelMessage={getTranslation('attentionTo', userLanguageCode)}
            isRequired
            errorMessageHide
            errorMessage={errors?.name?.message as string}
            hasError={!!errors.name}
          >
            <IonInput
              {...register('name', {
                required: 'name is required',
                pattern: {
                  value: dropShipFieldRegExps.nameRegExp,
                  message: 'not match RegExp'
                }
              })}
              maxlength={dropShipFieldMaxLengths.nameMaxLength}
            ></IonInput>
          </InputWrapper>

          <InputWrapper
            labelMessage={getTranslation('address1', userLanguageCode)}
            errorMessage={errors?.address1?.message as string}
            hasError={!!errors?.address1 || dropShipAddressError}
            isRequired
            errorMessageHide
          >
            <IonInput
              {...register('address1', {
                required: 'address 1 is required',
                pattern: {
                  value: dropShipFieldRegExps.address1RegExp,
                  message: 'not match RegExp'
                }
              })}
              maxlength={dropShipFieldMaxLengths.address1MaxLength}
            />
          </InputWrapper>

          <InputWrapper
            labelMessage={getTranslation('address2', userLanguageCode)}
            errorMessage={errors?.address2?.message as string}
            hasError={!!errors?.address2}
          >
            <IonInput
              {...register('address2', {
                pattern: {
                  value: dropShipFieldRegExps.address2RegExp,
                  message: 'not match RegExp'
                }
              })}
            />
          </InputWrapper>

          <InputWrapper
            labelMessage={getTranslation('city', userLanguageCode)}
            errorMessage={errors?.city?.message as string}
            hasError={!!errors?.city || dropShipAddressError}
            isRequired
            errorMessageHide
          >
            <IonInput
              {...register('city', {
                required: 'city is required',
                pattern: {
                  value: dropShipFieldRegExps.cityRegExp,
                  message: 'not match RegExp'
                }
              })}
            />
          </InputWrapper>

          <InputWrapper
            labelMessage={getTranslation('state', userLanguageCode)}
            errorMessage={errors?.state?.message as string}
            hasError={!!errors?.state || dropShipAddressError}
            isRequired
            errorMessageHide
          >
            <IonSelect
              {...register('state', {
                required: "state is required'"
              })}
              placeholder="Choose an Option"
            >
              {usStates.map((state: StateObject, index: number) => {
                return (
                  <IonSelectOption key={index} value={state.abbreviation}>
                    {state.name}
                  </IonSelectOption>
                )
              })}
            </IonSelect>
          </InputWrapper>

          <InputWrapper
            labelMessage={getTranslation('zip', userLanguageCode)}
            errorMessage={errors?.zip?.message as string}
            hasError={!!errors?.zip || dropShipAddressError}
            isRequired
            errorMessageHide
          >
            <IonInput
              {...register('zip', {
                required: 'zip is required',
                pattern: {
                  value: dropShipFieldRegExps.zipRegExp,
                  message: 'not match RegExp'
                }
              })}
              maxlength={dropShipFieldMaxLengths.zipMaxLength}
            ></IonInput>
          </InputWrapper>

          <InputWrapper
            labelMessage={getTranslation('country', userLanguageCode)}
            errorMessage={errors?.country?.message as string}
            hasError={!!errors?.country || dropShipAddressError}
            isRequired
          >
            <IonSelect
              {...register('country', {
                value: 'US',
                required: 'country is required'
              })}
            >
              <IonSelectOption value={newDropShipAddress.country}>
                United States
              </IonSelectOption>
            </IonSelect>
          </InputWrapper>
        </form>
      </div>
    </PWAModal>
  )
}

export default DropShipModal
