import { IonLabel } from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { getTranslation } from 'translations'
import './TextInput.scss'

interface Props {
  label: string
  children: React.ReactNode
}

// todo: deprecate in favor of app/components/input
const TextInput: React.FC<Props> = ({ label, children }: Props) => {
  const { userLanguageCode } = useSession()
  return (
    <div className="text-input">
      <div className="label-container">
        <IonLabel class="input-label" position="floating">
          {label}
        </IonLabel>
        <IonLabel class="input-label required-label" position="floating">
          {getTranslation('required', userLanguageCode)}
        </IonLabel>
      </div>
      {children}
    </div>
  )
}

export default TextInput
