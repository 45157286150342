import { useConfig } from 'app/context/config/ConfigContext'
import React from 'react'

interface Props {
  src?: string
  className?: string
  onClick?: () => void
  thumbnail?: boolean
}

const blank = 'assets/blank.png'
const thumbnailSrc = 'assets/missing-image-icon.svg'

const Image: React.FC<Props> = ({ src, className, onClick, thumbnail }) => {
  const { data: brandConfig } = useConfig()
  const fallbackImg = thumbnail
    ? thumbnailSrc
    : brandConfig?.brandDynamic?.config?.flags?.missingImage || blank

  return (
    <img className={className} src={src || fallbackImg} onClick={onClick} />
  )
}

export default Image
