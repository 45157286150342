import { IonContent, IonIcon, IonInput, IonItem, IonPage } from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { searchOutline } from 'ionicons/icons'
import { useRef } from 'react'
import { useHistory } from 'react-router'
import { getTranslation } from 'translations'

import './search.scss'

const Search: React.FC = () => {
  const history = useHistory()
  const searchText = useRef('')

  const { userCatalogId, userLanguageCode } = useSession()

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      redirectUrl(searchText.current)
    }

    return false
  }

  const redirectUrl = (searchTerm: any) => {
    history.replace(
      '/explore/catalogs?catalogId=' + userCatalogId + '&query=' + searchTerm
    )
  }
  return (
    <IonPage className="page-with-menu search-page">
      <IonContent>
        <div className="search-field" role="group">
          <IonItem>
            <IonInput
              placeholder={getTranslation('search', userLanguageCode)}
              onKeyUp={(event) => handleKeyPress(event)}
              onIonChange={(e) => (searchText.current = e.detail.value || '')}
            ></IonInput>
          </IonItem>
          <IonIcon
            size="large"
            onClick={() => redirectUrl(searchText.current)}
            icon={searchOutline}
          />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Search
