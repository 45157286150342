import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonRouterLink,
  IonToolbar
} from '@ionic/react'
import { useSession } from 'app/context/session/useSession'
import { checkmarkOutline, closeOutline } from 'ionicons/icons'
import { MouseEventHandler, RefObject } from 'react'
import { getTranslation } from 'translations'
import type { SortOption } from '../ProductListing'
interface Props {
  modalRef: RefObject<HTMLIonModalElement>
  handleSortClose: MouseEventHandler
  sortOptions: SortOption[]
  selectSort: (option: SortOption) => void
  selectedSortOption: SortOption
  isOpen?: boolean // used for testing
}

const SortFilters: React.FC<Props> = ({
  modalRef,
  handleSortClose,
  sortOptions,
  selectSort,
  selectedSortOption,
  isOpen = false
}) => {
  const { userLanguageCode } = useSession()

  // this sets the hiehgt of the modal properly....
  const multiplier = 47.2 / window.innerHeight
  let breakpoint = multiplier * (sortOptions.length + 1.5)

  if (breakpoint > 1) {
    breakpoint = 1
  }

  return (
    <IonModal
      className="pwa-modal sort"
      ref={modalRef}
      initialBreakpoint={breakpoint}
      mode="ios"
      isOpen={isOpen}
    >
      <IonHeader className="no-border-toolbar">
        <IonToolbar mode="ios">
          <h2>{getTranslation('sortBy', userLanguageCode)}</h2>
          <IonButtons slot="end">
            <IonButton onClick={handleSortClose}>
              <IonIcon
                className="icon"
                size="large"
                icon={closeOutline}
                color="primary"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="choices">
          {sortOptions.map((option: SortOption) => (
            <IonItem
              lines="none"
              key={option.id}
              onClick={() => selectSort(option)}
            >
              <IonRouterLink
                slot="start"
                className={option.id == selectedSortOption.id ? 'selected' : ''}
              >
                {option.name}
              </IonRouterLink>
              {option.id == selectedSortOption.id && (
                <IonIcon
                  className="icon"
                  size="large"
                  icon={checkmarkOutline}
                  color="primary"
                  slot="end"
                />
              )}
            </IonItem>
          ))}
        </div>
      </IonContent>
    </IonModal>
  )
}

export default SortFilters
