export function sortByTitle(a: { title: string }, b: { title: string }) {
  var titleA = a.title.toLowerCase(),
    titleB = b.title.toLowerCase()

  if (titleA < titleB) return -1
  if (titleA > titleB) return 1

  return 0
}

export function generateFacetQueryString(facets: any[]) {
  let queryString = ''
  facets.forEach((facet: any) => {
    const splitString = facet.split('|')
    const facetName = splitString[0]
    const term = splitString[1]
    let facetNameFixed = facetName.replace(/ {2}/g, ' ').replace(/ /g, '+')
    facetNameFixed = facetNameFixed.replace(/\/\+/g, '/')
    queryString +=
      '&tags' +
      '[' +
      facetNameFixed +
      '][]=' +
      term.replace(/ {2}/g, ' ').replace(/ /g, '+')
  })

  return queryString.substring(1)
}

function convertToTitleCase(text: string) {
  const result = text.replace(/([A-Z])/g, '$1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function generateFacets(facets: any) {
  const usableFacets = []

  for (const key in facets) {
    if (key === 'Region') {
      // scramble4 gets the filters from the user's  first catalog, which has tags, those tags are what are used
      // a user can only belong to one region
      // the catalog's tags respects that so it doesn't have a region
      // so we need to remove the region tag in this place, because it just has one value anyway, it's All, and All has the total number of products you're querying from anyway
      continue
    }
    if (facets[key] && facets[key].terms && facets[key].terms.length > 0) {
      const tempFacets: any = {
        title: convertToTitleCase(key).trim().replace(/\/ /g, '/'),
        options: []
      }

      facets[key].terms.forEach((term: any) => {
        tempFacets.options.push({
          title: term[0].replace(/\/ /g, '/'),
          amount: term[1]
        })
      })

      usableFacets.push(tempFacets)
    }
  }

  usableFacets.sort(sortByTitle)

  usableFacets.forEach((facet: any) => {
    facet.options.sort(sortByTitle)
  })

  return usableFacets
}

export function generateFacetPWAQueryString(facets: any[]) {
  let queryString = ''
  facets.forEach((facet: any) => {
    facet = facet.replace('|', ':')
    queryString += '|' + facet.replace(/ {2}/g, ' ')
  })

  return queryString.substring(1)
}

export function totalFacetDisplayCount({
  tags,
  query = ''
}: {
  tags: string[]
  query?: string
}): string {
  const total = tags.length + (query.length > 0 ? 1 : 0)
  return total > 0 ? `(${total})` : ''
}
