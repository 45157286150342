import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonPage,
  IonRouterLink,
  IonToolbar,
  useIonLoading
} from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import Breadcrumbs from 'app/components/header/breadcrumbs/Breadcrumbs'
import { useCart } from 'app/context/cart/useCart'
import { useConnection } from 'app/context/connection/useConnection'
import { useSession } from 'app/context/session/useSession'
import { closeOutline } from 'ionicons/icons'
import { ReadOnlyOrder } from 'models'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { getOrders } from 'services/order'
import { getTranslation } from 'translations'
import './OrderListing.scss'
import OrderItem from './components/order-item/OrderItem'

const getOrderList = async (page: string, connected: boolean) => {
  const orders = await getOrders(page, connected)
  return orders.ordersDynamic.sort((a: ReadOnlyOrder) =>
    a.state === 'draft' ? -1 : 1
  )
}

const OrderListing: React.FC = ({
  mockedLocation
}: {
  mockedLocation?: string
}) => {
  const { connected } = useConnection()
  const { data, isLoading } = useQuery({
    queryKey: ['orders', '0-100'],
    queryFn: () => getOrderList('0-100', connected)
  })
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [orderClicked, setOrderClicked] = useState<string>('')
  const [present, dismiss] = useIonLoading()
  const history = useHistory()
  const { userLanguageCode } = useSession()

  const { cartItems, loadExistingOrder, currentOrder } = useCart()

  const warningModal = useRef<HTMLIonModalElement>(null)

  useEffect(() => {
    if (isLoading) {
      present({ message: 'Loading orders...' })
    } else {
      dismiss()
    }
  }, [isLoading, present, dismiss])

  async function loadOrder(orderId: string) {
    await present({ message: 'Loading order...' })
    const loaded = await loadExistingOrder(orderId)
    if (loaded) {
      history.replace('/cart')
    } else {
      alert('Please try again.')
    }
    await dismiss()
  }

  async function openOrder(order: ReadOnlyOrder) {
    setOrderClicked(order._id)
    if (order.state == 'final_submission') {
      history.replace(`/manage/orders/order-detail/${order._id}`)
    } else {
      if (currentOrder && order._id == currentOrder._id) {
        history.replace('/cart')
      } else {
        if (cartItems.length > 0) {
          setModalOpen(true)
        } else {
          await loadOrder(order._id)
        }
      }
    }
  }

  return (
    <IonPage className="page-with-menu">
      <IonContent>
        <div className="order-list">
          <Breadcrumbs mockedLocation={mockedLocation} />
          <h1>{getTranslation('orders', userLanguageCode)} </h1>
          <IonList>
            {(data || []).map((order: ReadOnlyOrder) => {
              return (
                <div key={order._id}>
                  <OrderItem order={order} openOrder={openOrder} />
                </div>
              )
            })}
          </IonList>
          <IonModal
            className="pwa-modal warning"
            onIonModalDidDismiss={() => {
              setModalOpen(false)
            }}
            isOpen={modalOpen}
            ref={warningModal}
            initialBreakpoint={0.35}
          >
            <IonHeader className="no-border-toolbar">
              <IonToolbar>
                <h2>{getTranslation('warning', userLanguageCode)}</h2>
                <IonButtons slot="end">
                  <IonButton
                    onClick={() => {
                      warningModal.current?.dismiss()
                    }}
                  >
                    <IonIcon
                      className="icon"
                      size="large"
                      icon={closeOutline}
                      color="primary"
                    />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="text">
                <p>{getTranslation('draftOrderWarning', userLanguageCode)}</p>
                <p>{getTranslation('areYouSure', userLanguageCode)}</p>
              </div>
              <div className="choices">
                <IonRouterLink
                  onClick={() => {
                    loadOrder(orderClicked)
                    warningModal.current?.dismiss()
                    return false
                  }}
                >
                  {getTranslation('yes', userLanguageCode)}{' '}
                </IonRouterLink>
                <IonRouterLink
                  onClick={() => {
                    warningModal.current?.dismiss()
                  }}
                >
                  {getTranslation('no', userLanguageCode)}{' '}
                </IonRouterLink>
              </div>
            </IonContent>
          </IonModal>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default OrderListing
