import { useContext } from 'react'
import { SessionContext } from './SessionContext'

export const useSession = () => {
  const {
    error,
    isAuthenticated,
    authValidated,
    getUserInfo,
    login,
    logout,
    userCatalogId,
    userInfo,
    userLanguageCode
  } = useContext(SessionContext)

  if (SessionContext === undefined)
    throw new Error('useSession must be used within a SessionProvider')

  return {
    error,
    isAuthenticated,
    authValidated,
    getUserInfo,
    login,
    logout,
    userInfo,
    userCatalogId,
    userLanguageCode
  }
}
